/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_MA_01.tsx
 * @desc 메인메뉴
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { downloadLink } from '../../utils/download';

export default function GngCwhSCR_H_CW_MO_MA_01() {
  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.swiper)) {
        window.swiper();
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
  }, []);
  return (
    <>
      <div className="section section01">
        <div className="intro-module">
          <div className="intro-full">
            <div className="intro-ani">
              <div className="title">
                <h2
                  className="txt-rotate"
                  data-period="6000"
                  data-rotate='[ " 언제나 새 차처럼, 세차클링"]'
                ></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section02 section_txt js-inview">
        <p className="inview-el">
          언제 어디서나 내 차 관리,
          <br />
          간편하게 세차클링 하세요.
        </p>
      </div>
      <div className="section section03">
        <div className="section03_bg section_bg">
          <div className="l" aria-hidden="true"></div>
          <div className="r" aria-hidden="true"></div>
        </div>
        <div className="section_txt">
          <strong>#간편하게</strong>
          <span>원하는 시간, 장소 어디서든 세차하세요. </span>
        </div>
      </div>
      <div className="section section04">
        <div className="section04_bg section_bg">
          <div className="l" aria-hidden="true"></div>
          <div className="r" aria-hidden="true"></div>
        </div>
        <div className="section_txt">
          <strong>#믿을수있는</strong>
          <span>
            월 7만 여건, G car의 차량관리 노하우와 품질이 검증된 맞춤형 서비스를
            받아보세요.{' '}
          </span>
        </div>
      </div>
      <div className="section section05">
        <div className="section05_bg section_bg">
          <div className="l" aria-hidden="true"></div>
          <div className="r" aria-hidden="true"></div>
        </div>
        <div className="section_txt">
          <strong>#합리적가격</strong>
          <span>
            꼭 필요한 구성으로 이뤄진 상품으로 가성비 효과를 누려보세요.{' '}
          </span>
        </div>
      </div>
      <div className="section section06">
        <div className="section06_bg section_bg">
          <div className="l" aria-hidden="true"></div>
          <div className="r" aria-hidden="true"></div>
        </div>
        <div className="section_txt">
          <strong>#전기차도</strong>
          <span>
            자동 세차장이 없는 전기차량 고객님도 걱정말고 세차하세요.{' '}
          </span>
        </div>
      </div>

      <div className="section section07">
        <span className="empty"></span>
        <p className="section_txt">
          합리적인 구성의
          <br />
          ‘언제나 새 차처럼’ 상품
          <br />
          언제, 어디서나, 스마트한
          <br />
          세차 서비스를 누려보세요
        </p>
        <div className="swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <p>1회 세차</p>
              <div>
                <strong>중요한 하루!</strong>
                <p>
                  특별한 날 새차처럼
                  <br />
                  보이고 싶은 고객의 선택!
                </p>
              </div>
            </div>
            <div className="swiper-slide">
              <p>2회 세차</p>
              <div>
                <strong>가성비</strong>
                <p>
                  기계식 세차를 이용하지 못하는
                  <br />
                  전기차 사용자들을 위한 선택
                </p>
              </div>
            </div>
            <div className="swiper-slide">
              <p>4회 세차</p>
              <div>
                <strong>주말은 내가! 평일은 매일 매일!</strong>
                <p>
                  꾸준한 관리가 중점인
                  <br />
                  깔끔한 고객의 선택!
                </p>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
      <div className="section section08">
        <div className="tit">올바르게, 친환경적으로</div>
        <div className="etc-list">
          <ul>
            <li>
              <p>최소 500ml의 물 사용으로 친환경 워터리스 방식 세차</p>
            </li>
            <li>
              <p>차량손상이 적은 세차클링만의 윤활제와 계면활성제 혼합비율</p>
            </li>
            <li>
              <p>주기적인 세차 타월교체와 장비 관리를 통한 품질유지</p>
            </li>
            <li>
              <p>주변시설 소음과 오염을 최소화한 세차 기본 에티켓 준수</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section section09">
        <div className="tit">
          세차클링만의 다양한 혜택으로
          <br />
          즐거움을 더해보세요.
        </div>
        <div className="sub">세차클링 혜택</div>
        <div className="benefit-list">
          <ul>
            <li>
              <p>
                회원을 위한 정기 세차
                <br />
                클링패스
              </p>
            </li>
            <li>
              <p>
                다양한 프로모션과
                <br />
                이벤트 혜택
              </p>
            </li>
            <li>
              <p>
                세차클링으로
                <br />새 차처럼 적립금 팡팡!
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-download">
        <a href="#" onClick={downloadLink}>
          <span className="hide-txt">세차클링 앱 다운로드</span>
        </a>
      </div>
    </>
  );
}
