/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_CS_05.tsx
 * @desc 자주묻는질문 게시물 없음
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_PC_CS_05() {
  return <div>자주묻는질문 게시물 없음</div>;
}
