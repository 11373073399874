/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_PS_02.tsx
 * @desc 제휴문의 (작성)
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useRef, useState, useEffect } from 'react';
import {
  coopCodeDefaultProps,
  selectCoopCodeApi,
  pathDefaultProps,
  selectPathCodeApi,
  saveCoopApi,
  uploadFilesApi
} from './../../api/ci/partner';
import _, { size, values } from 'lodash';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { setAlert } from '../../utils/web_common_ui';

export default function GngCwhSCR_H_CW_PC_PS_02() {
  let alertPopup: HTMLElement = document.getElementsByClassName(
    'alertPopup'
  )[0] as HTMLElement;

  const [type, setType] = useState('00001');
  const [tit, setTit] = useState('');
  const [ctnt, setCtnt] = useState('');
  const [coop, setCoop] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fileName, setFileName] = useState('');
  const [inPath, setInPath] = useState('00001');
  const [agreeYn, setAgreeYn] = useState('');

  const [file1, setFile1] = useState<any>();
  const [fileName0, setFileName0] = useState('');

  const fileGroup = [
    {
      value: file1,
      setter: setFile1,
      fileRef: useRef<any>(),
      imgRef: useRef<any>()
    }
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const defaultListProps: coopCodeDefaultProps = {
    codeList: []
  };
  const [greenCodeListData, setGreenCodeListData] = useState(defaultListProps);

  const defaultPathProps: pathDefaultProps = {
    codeList: []
  };
  const [pathCodeData, setPathCodeData] = useState(defaultPathProps);

  useEffect(() => {
    selectCoopCodeApi().then((res: any) => {
      setGreenCodeListData((prevState: any) => ({
        ...prevState,
        codeList: [...res.data]
      }));
    });

    selectPathCodeApi().then((res: any) => {
      console.log(res);
      setPathCodeData((prevState: any) => ({
        ...prevState,
        codeList: [...res.data]
      }));
    });
  }, []);

  const clickFile = () => {
    let element: HTMLElement = document.getElementsByClassName(
      'ip-file'
    )[0] as HTMLElement;

    element.click();
  };

  const changeType = (e: any) => {
    const value = e.target.value;
    setType(value);
  };

  const changeTit = (e: any) => {
    const value = e.target.value;
    setTit(value);
  };
  const changeCtnt = (e: any) => {
    const value = e.target.value;
    setCtnt(value);
  };

  const changeFile = (e: any) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    } else {
      setFileName('');
    }
  };

  // const uploadFiles = (e: any) => {
  //   let formData = new FormData();

  //   formData.append('dtlCd', '00011');
  //   formData.append('useGrpYn', 'N');
  //   if (!_.isNil(file1) && !_.isNil(file1[0])) {
  //     formData.append('file', file1[0]);
  //   }

  //   uploadFilesApi(formData).then((res: any) => {
  //     let file1Seq = '';
  //     if (!_.isNil(file1) && !_.isNil(file1[0])) {
  //       file1Seq = res.data[0].atchflSeq;
  //     }
  //     saveCoop(file1Seq);
  //   });
  // };

  const changeCoop = (e: any) => {
    const value = e.target.value;
    setCoop(value);
  };

  const changePhone = (e: any) => {
    const value = e.target.value;
    setPhone(value);
  };

  const changeEmail = (e: any) => {
    const value = e.target.value;
    setEmail(value);
  };

  const changePath = (e: any) => {
    const value = e.target.value;
    setInPath(value);
  };

  const agreeCheck = (e: any) => {
    const value = e.target.checked;
    setAgreeYn(value);
  };

  const saveCoop = (file1Seq: any) => {
    if (!tit) {
      setAlert('제목을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!ctnt) {
      setAlert('내용을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!coop) {
      setAlert('회사명을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!phone) {
      setAlert('담당자 연락처을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!email) {
      setAlert('담당자 이메일을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!agreeYn) {
      setAlert('개인정보 수집 및 이용에 동의해주세요.');
      alertPopup.click();
      return;
    }

    let formData = new FormData();

    formData.append('dtlCd', '00011');
    formData.append('useGrpYn', 'N');
    if (!_.isNil(file1) && !_.isNil(file1[0])) {
      formData.append('file', file1[0]);
    }

    // uploadFilesApi(formData).then((res: any) => {
    //   let file1Seq = '';
    //   if (!_.isNil(file1) && !_.isNil(file1[0])) {
    //     file1Seq = res.data[0].atchflSeq;
    //   }
    //   saveCoop(file1Seq);
    // });

    // const params = {
    //   inqryCcd: type,
    //   propseCmpnyNm: coop,
    //   chargerTelno: phone,
    //   chargerEmail: email,
    //   nttTit: tit,
    //   nttCtnt: ctnt,
    //   flpth: file1Seq,
    //   ldinPathCcd: inPath
    // };
    formData.append('inqryCcd', type);
    formData.append('propseCmpnyNm', coop);
    formData.append('chargerTelno', phone);
    formData.append('chargerEmail', email);
    formData.append('nttTit', tit);
    formData.append('nttCtnt', ctnt);
    formData.append('flpth', '');
    formData.append('ldinPathCcd', inPath);

    saveCoopApi(formData).then((res: any) => {
      setAlert('등록이 완료되었습니다.');
      alertPopup.click();
      navigate('/alliance');
    });
  };

  const tempImageRender = (files: any, ref: any, setter: any) => {
    const limitSize = 2 * 1024 * 1024;
    if (!_.isNil(files) && !_.isNil(files[0])) {
      if (files[0].size > limitSize) {
        alert('파일 사이즈가 10MB를 넘습니다.');
        setter(null);
        ref.current.src = '';
      }
    } else if (!_.isNil(ref.current)) {
      ref.current.src = '';
    }
  };

  return (
    <div className="content-layout donation write">
      <div className="tb-wrap write">
        <table>
          <caption>
            제휴문의 : 문의구분, 제목, 신청내용, 첨부파일, 제안 회사명, 담당자
            연락처, 담당자 이메일에 대한 정보
          </caption>
          <colgroup>
            <col
              style={{
                width: '217px'
              }}
            />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">문의 구분</th>
              <td>
                <span className="ui-select">
                  <select title="문의구분 선택" onChange={changeType}>
                    {greenCodeListData.codeList.map((type) => {
                      const list = (
                        <option key={type.dtlCd} value={type.dtlCd}>
                          {type.dtlCdNm}
                        </option>
                      );
                      return list;
                    })}
                  </select>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">제목</th>
              <td>
                <span className="ui-input">
                  <input
                    type="text"
                    className="ip-txt tit"
                    title="제목 입력"
                    placeholder="제목을 입력해주세요"
                    style={{
                      width: '100%'
                    }}
                    onChange={changeTit}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td className="dee[">
                <div className="ui-txtarea">
                  <textarea
                    className="txtarea ctnt"
                    placeholder="내용을 입력해주세요"
                    onChange={changeCtnt}
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">첨부파일</th>
              <td className="deep">
                {_.map(fileGroup, (file: any, key: number) => {
                  return (
                    <>
                      <span className="ui-input">
                        <input
                          type="file"
                          className="ip-file"
                          onChange={(e: any) => {
                            if (key == 0) {
                              setFileName0(e.target.files[0].name);
                            }

                            file.setter(e.target.files);
                            tempImageRender(
                              file.value,
                              file.imgRef,
                              file.setter
                            );
                          }}
                          ref={file.fileRef}
                          accept="image/*"
                        />
                        {key == 0 ? (
                          <input
                            type="text"
                            className="ip-txt fileName"
                            title="첨부파일"
                            placeholder="파일명"
                            value={fileName0}
                            readOnly
                          />
                        ) : null}
                      </span>
                      <button
                        type="button"
                        className="btns btn-file"
                        onClick={() => {
                          file.fileRef.current.click();
                        }}
                      >
                        파일찾기
                      </button>
                    </>
                  );
                })}

                <ul className="bullet-list">
                  <li>
                    - 제휴와 관련된 문서 또는 이미지 파일을 첨부해주시면 검토에
                    도움을 줄 수 있습니다.
                    <br />
                    (확장자 : pdf 외 jpg, jpeg, gif, png, bmp)
                  </li>
                  <li>- 파일 크기가 10MB 미만인 경우만 업로드 가능합니다.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">회사명</th>
              <td>
                <span className="ui-input">
                  <input
                    type="text"
                    className="ip-txt coop"
                    title="제안 회사명 입력"
                    placeholder="회사명을 입력해주세요"
                    onChange={changeCoop}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">담당자 연락처</th>
              <td>
                <span className="ui-input">
                  <input
                    type="text"
                    className="ip-txt phone"
                    title="담당자 연락처"
                    placeholder="연락가능한 담당자 연락처를 입력해주세요."
                    onChange={changePhone}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">담당자 이메일</th>
              <td>
                <span className="ui-input">
                  <input
                    type="text"
                    className="ip-txt email"
                    title="담당자 이메일"
                    placeholder="담당자 이메일을 입력해주세요."
                    onChange={changeEmail}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">인입 경로</th>
              <td>
                <span className="ui-select">
                  <select title="인입경로 선택" onChange={changePath}>
                    {pathCodeData.codeList.map((type) => {
                      const list = (
                        <option key={type.dtlCd} value={type.dtlCd}>
                          {type.dtlCdNm}
                        </option>
                      );
                      return list;
                    })}
                  </select>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="agree-module">
        <div className="agree-txt">
          <p className="dt">개인정보 수집 및 이용 동의 안내</p>
          <p className="dd">1. 항목 : 회사명, 연락처, 이메일</p>
          <p className="dd">2. 목적 : 제휴 제안에 따른 연락처 정보 확인</p>
          <p className="dd">
            3. 보유기간 : 신청 후 1개월간 보관 후 파기
            <br />위 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부
            시에는 제휴 제안 접수가 제한될 수 있습니다.
          </p>
        </div>

        <span className="ip-checkbox">
          <label htmlFor="check01" className="check-module">
            <input type="checkbox" id="check01" onChange={agreeCheck} />
            <span className="checkbox"></span>
            <span className="label">개인정보 수집 및 이용에 동의합니다</span>
          </label>
        </span>
      </div>

      <div className="btn-primary">
        <button type="button" className="btns lg" onClick={saveCoop}>
          등록 완료
        </button>
      </div>
    </div>
  );
}
