import _ from 'lodash';
import { menuList, MenuGroup } from './getPage';
//햄버거 메뉴

export function registUI(el: any, fn: any) {
  document.querySelectorAll(el).forEach(function (obj, idx) {
    // eslint-disable-next-line new-cap
    const _inst = new fn();
    _inst.init(el, obj);
  });
}

const initUi = () => {
  if (_.isFunction(window.uiMenu)) {
    registUI('.ui-parallax', window.uiMainparallax);
    //    registUI('.ui-tab', window.uiTab);
    registUI('.ui-accodion', window.uiAccodion);
    registUI('.ui-section-slider', window.uiSectionSlider);
    registUI('.ui-top', window.uiTop);
    window.uiPopup.init('.ui-popup');
  }
};

export function webHamburgerMenuInitUi() {
  initUi();
}

//Content Page Title 영역 event
// intersection observer
const changeNav = function changeNav(entries: any, observer: any) {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting && entry.intersectionRatio >= 0.1) {
      if (entry.target.classList.contains('js-ani')) {
        entry.target.classList.add('js-ani-end');
      } else {
        entry.target.classList.add('is-inviewed');
      }
    }
  });
};

const options = {
  threshold: 0.55
};
const observer = new IntersectionObserver(changeNav, options);

export function webContentTitleInitUi() {
  const sections = document.querySelectorAll('.js-inview');
  const sections2 = document.querySelectorAll('.js-ani');
  sections.forEach(function (obj) {
    observer.observe(obj);
  });
  sections2.forEach(function (obj) {
    observer.observe(obj);
  });
}

export function unClickMenu() {
  const menuEl: any = document.querySelector(
    '.head-module .menu.is-active .btn-menu'
  );
  if (menuEl) {
    menuEl.click();
  }
}

export function getCurrentMenuGroup() {
  const currentMenu: MenuGroup | any = _.find(menuList, (menuGroup) => {
    return (
      _.findIndex(menuGroup.children, (menu) => {
        // Ensure that the URL starts with a /
        const url = menu.url.startsWith('/') ? menu.url : `/${menu.url}`;
        return url === location.pathname;
      }) >= 0
    );
  });
  return currentMenu;
}

export function setAlert(content: any) {
  document.getElementsByClassName('alertContent')[0].innerHTML = content;
}
