// 경로 상수 정의
export const PATHS = {
  CLAUSE: '/clause',
  CLAUSE_TW: '/clausetw',
  CLAUSE_TH: '/clauseth',
  CLAUSE_M: '/m/clause',
  CLAUSE_TW_M: '/m/clausetw',
  CLAUSE_TH_M: '/m/clauseth'
};

// 경로와 이름을 매핑하는 객체
export const PATH_TO_NAME_MAP: Record<string, string> = {
  [PATHS.CLAUSE_TW]: '개인정보처리방침',
  [PATHS.CLAUSE_TH]: '위치기반 서비스 이용약관',
  [PATHS.CLAUSE]: '세차클링 회원 약관',
  [PATHS.CLAUSE_TW_M]: '개인정보처리방침',
  [PATHS.CLAUSE_TH_M]: '위치기반 서비스 이용약관',
  [PATHS.CLAUSE_M]: '세차클링 회원 약관'
};

// 현재 경로가 '/m'으로 시작하는지 확인하는 함수
export const startsWithM = (path: string): boolean => path.startsWith('/m');

// 경로에 따른 이름을 반환하는 함수
export const getNameByPath = (path: string): string => {
  return PATH_TO_NAME_MAP[path] || '';
};

// 이름에 따라 경로를 반환하는 함수
export const getPathByName = (name: string, currentPath: string): string => {
  const isMobile = startsWithM(currentPath);

  for (const path in PATH_TO_NAME_MAP) {
    if (PATH_TO_NAME_MAP[path] === name) {
      if (isMobile === path.startsWith('/m')) {
        return path;
      }
    }
  }

  return '';
};
