/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_SV_01.tsx
 * @desc 클링비즈
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_PC_SV_01() {
  return (
    <>
      <div className="js-inview mx-guide flex-desc">
        <p className="desc xxl inview-el nth-child-7">
          합리적이고 믿을 수 있는
          <br />
          클링비즈
        </p>
      </div>

      <div className="content-layout">
        <div className="grid-moudle">
          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="../static/web/images/contents/biz_img_01.png" alt="" />
            </div>
            <div className="flex cont inview-el">
              <p className="tit">편리한 서비스 이용</p>
              <p className="desc">
                쉽고 간편하게 원하는 시간에 어디서나
                <br />
                서비스 이용
              </p>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">가격 경쟁력 보유</p>
              <p className="desc">
                국내 최저 가격으로
                <br />
                정기세차 서비스 이용
              </p>
            </div>
            <div className="flex inview-el">
              <img src="../static/web/images/contents/biz_img_02.png" alt="" />
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="../static/web/images/contents/biz_img_03.png" alt="" />
            </div>
            <div className="flex cont inview-el">
              <p className="tit">전문성 높은 서비스</p>
              <p className="desc">
                전문가가 케어 해주는 서비스로 연 70,000번
                <br />
                이상의 세차 진행과 종합 소독 방역 서비스 제공
              </p>
              <p className="point">
                <i className="ico point sm"></i>전문 세차원 운영
              </p>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">전문 케어 서비스</p>
              <p className="desc">
                현재 요구 사항에 대한 진단을 해드리고
                <br />
                상품에 맞는 솔루션 제공
              </p>
            </div>
            <div className="flex inview-el">
              <img src="../static/web/images/contents/biz_img_04.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="greencar__footer_info js-inview">
        <div className="mx-guide">
          <div className="tit  inview-el ">
            <h2>
              클링비즈 <em>파트너스</em>
            </h2>
          </div>

          <div className="biz-box">
            <div className="group  inview-el  nth-child-2">
              <h3>롯데 그룹사</h3>
              <div className="cont">
                <ul>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_01.png"
                      alt="롯데렌탈"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_02.png"
                      alt="롯데케미칼"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_03.png"
                      alt="롯데마트"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_04.png"
                      alt="롯데중앙연구소"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_05.png"
                      alt="롯데건설"
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_06.png"
                      alt="롯데하이마트"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_07.png"
                      alt="lotte hotels"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="group  inview-el  nth-child-4">
              <h3>생활 주거 영역</h3>
              <div className="cont">
                <ul>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_08.png"
                      alt="롯데렌터카"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_09.png"
                      alt="GS 엠비즈"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_10.png"
                      alt="lotte castle"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_11.png"
                      alt="urbanil"
                    />
                  </li>
                  <li>
                    <img
                      src="/static/web/images/contents/biz_logo_12.png"
                      alt="롯데렌터카 G car"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
