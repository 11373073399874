/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_CS_06.tsx
 * @desc 공지사항 리스트
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  noticeListDefaultProps,
  selectNoticeListApi,
  selectNoticeAllListApi
} from './../../api/ci/csCenter';
import _, { size } from 'lodash';
import { formatDateToString } from './../../utils/math';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

export default function GngCwhSCR_H_CW_PC_CS_06() {
  const navigate = useNavigate();
  const location = useLocation();

  const [text, setText] = useState('');
  const [searchFlag, setSearchFlag] = useState('');

  const [pageIndex, setPageIndex] = useState('1');
  const [pageGroup, setPageGroup] = useState('1');
  const [totalCount, setTotalCount] = useState('');
  const [pageCount, setPageCount] = useState('1');

  const defaultListProps: noticeListDefaultProps = {
    noticeList: []
  };
  const [noticeListData, setNoticeListData] = useState(defaultListProps);

  useEffect(() => {
    selectNoticeListApi(pageIndex, '').then((res: any) => {
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
    });
    setPagination(pageIndex, '');
  }, []);

  // 검색어 입력, 변경
  const onChange = (e: any) => {
    setSearchFlag('');
    if (e.target.value === '') {
      clearSearchValBtn();
    }
    setText(e.target.value);
  };

  // 검색어 초기화
  const clearSearchValBtn = () => {
    setPageIndex('1');
    setText('');
    setSearchFlag('');
    selectNoticeListApi(pageIndex, '').then((res: any) => {
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
      setPagination('', '');
    });
  };

  // 공지사항 검색
  const noticeSearch = () => {
    selectNoticeListApi(pageIndex, text).then((res: any) => {
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
      setSearchFlag('search');

      setPagination(pageIndex, text);
    });
  };

  let pageCountArray: any = [];
  const pageCountData = {
    pageList: [
      {
        num: '1'
      }
    ]
  };
  const [pageData, setPageData] = useState(pageCountData);
  const setPagination = (page: any, value: any) => {
    selectNoticeAllListApi(value).then((res: any) => {
      setTotalCount(res.data.noticeList.length);
      let pc = '';
      if (Math.floor(parseInt(res.data.noticeList.length) % 10) == 0) {
        pc = Math.floor(parseInt(res.data.noticeList.length) / 10) + '';
      } else {
        pc = Math.floor(parseInt(res.data.noticeList.length) / 10 + 1) + '';
      }
      setPageCount(pc);

      let pagenumber = page;
      let lastnumber = page;
      lastnumber = parseInt(pagenumber) + 5 + '';
      for (let i = parseInt(pagenumber); i < parseInt(lastnumber); i++) {
        if (parseInt(pc) >= i) {
          const param = {
            num: i + ''
          };
          pageCountArray.push(param);
        }
      }

      setPageData((prevState: any) => ({
        ...prevState,
        pageList: pageCountArray
      }));
    });
  };
  const getList = (type: any, pageNum: any) => {
    selectNoticeListApi(pageNum, text).then((res: any) => {
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
    });
    if (pageNum != 1 && pageNum % 5 == 1) {
      if (type == 'next') {
        setPageGroup(parseInt(pageGroup) + 1 + '');
      }

      setPagination(pageNum, text);
    }
    if (pageNum % 5 == 0) {
      if (pageNum != 5) {
        setPageGroup(parseInt(pageNum) / 5 + '');
        setPagination(pageNum - 4, text);
      } else {
        setPageGroup('1');
        setPagination(1, text);
      }
    }
  };

  return (
    <section className="content-layout notice">
      <div className="tb-search">
        <div className="search-module">
          {searchFlag != '' ? (
            <div className="result">
              <p className="result-txt">
                <strong className="emp">‘{text}’ </strong>
                검색 결과
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="ip-group">
            {/* <button
              type="button"
              className="btns primary"
              onClick={clearSearchValBtn}
              style={{
                marginRight: '5px'
              }}
            >
              초기화
            </button> */}
            <input
              className="ip-txt"
              type="text"
              placeholder="검색어를 입력해주세요."
              onChange={onChange}
              value={text}
              // onFocus={() => {
              //   clearSearchValBtn();
              // }}
            />
            <div className="btn-module">
              {text != '' ? (
                <button
                  type="button"
                  className="btn-del"
                  onClick={clearSearchValBtn}
                >
                  <span className="hide-txt">내용 삭제</span>
                  <i className="ico delete lg"></i>
                </button>
              ) : (
                <></>
              )}
              <button
                type="button"
                className="btn-search"
                onClick={noticeSearch}
              >
                <span className="hide-txt">검색</span>
                <i className="ico search lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tb-wrap">
        <table>
          <caption>고객센터 고지사항에 대한 정보</caption>
          <colgroup>
            <col
              style={{
                width: '108px'
              }}
            />
            <col />
            <col
              style={{
                width: '188px'
              }}
            />
          </colgroup>
          <tbody>
            {noticeListData.noticeList.length === 0 ? (
              <>
                {text === null || text === '' ? (
                  <tr>
                    <td colSpan={3}>
                      <div className="no-data">
                        <p className="desc">등록된 공지사항이 없습니다.</p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <div className="no-data">
                        <p className="desc">
                          검색 결과가 없습니다.
                          <br />
                          다른 검색어를 이용해주세요.
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <>
                {noticeListData.noticeList.map((data) => {
                  let coloredCode = data.noticeTit.replaceAll(
                    text,
                    `<span style="color: #f00; font-weight: 600;">${text}</span>`
                  );
                  const list = (
                    <tr key={data.noticeSeq}>
                      <th scope="row" className="center">
                        {data.imprtncNtfYn === 'Y' ? (
                          <span className="badge">Notice</span>
                        ) : (
                          <>{data.num}</>
                        )}
                      </th>
                      <td
                        onClick={() => {
                          navigate('/notice/view/' + data.noticeSeq, {
                            state: {
                              pageIndex: pageIndex,
                              searchValue: text
                            }
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {searchFlag != '' ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{ __html: coloredCode }}
                            ></span>
                          </>
                        ) : (
                          <>{data.noticeTit}</>
                        )}
                      </td>
                      <td className="date center">{data.frstRdtt}</td>
                    </tr>
                  );
                  return list;
                })}
              </>
            )}
          </tbody>
        </table>
      </div>

      {noticeListData.noticeList.length === 0 ? (
        <></>
      ) : (
        <div className="pagination">
          <div className="pagi-module">
            {pageGroup === '1' ? (
              <button className="btn-pagi" disabled>
                <i className="ico btn-prev"></i>
                PREV
              </button>
            ) : (
              <button
                className="btn-pagi"
                onClick={() => {
                  setPageIndex((parseInt(pageGroup) - 1) * 5 + '');
                  // console.log((parseInt(pageGroup) - 1) * 5 + '');
                  getList('prev', (parseInt(pageGroup) - 1) * 5);
                }}
              >
                <i className="ico btn-prev"></i>
                PREV
              </button>
            )}

            <div className="number pageNumber">
              {pageData.pageList.map((data) => {
                const list = (
                  <div key={data.num}>
                    <button
                      type="button"
                      className={data.num === pageIndex ? 'active' : ''}
                      onClick={() => {
                        setPageIndex(data.num);
                        getList('', data.num);
                      }}
                    >
                      {data.num}
                    </button>
                  </div>
                );
                return list;
              })}
            </div>

            {Math.floor(parseInt(pageCount) / 5) + 1 ===
            Math.floor(parseInt(pageIndex) / 5) + 1 ? (
              <>
                {Math.floor(parseInt(pageIndex) % 5) === 0 ? (
                  <button
                    className="btn-pagi"
                    onClick={() => {
                      setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                      getList('next', parseInt(pageGroup) * 5 + 1);
                    }}
                  >
                    NEXT
                    <i className="ico btn-next"></i>
                  </button>
                ) : (
                  <button className="btn-pagi" disabled>
                    NEXT
                    <i className="ico btn-next"></i>
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn-pagi"
                onClick={() => {
                  setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                  getList('next', parseInt(pageGroup) * 5 + 1);
                }}
              >
                NEXT
                <i className="ico btn-next"></i>
              </button>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
