/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CW_01.tsx
 * @desc 세차클링
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
import { downloadLink } from '../../utils/download';

export default function GngCwhSCR_H_CW_MO_CW_01() {
  return (
    <>
      <div className="contents p0 washcling bs bg-parallax">
        <div className="washcling__top_intro">
          <div className="mx-guide">
            <div className="washcling__top_intro_txt js-ani">
              <h2>
                스마트한 세차 라이프, <br /> 세차클링
              </h2>
            </div>
          </div>
        </div>

        <div className="content-layout mt60 ">
          <div className="grid-moudle">
            <div className="grid js-ani">
              <div className="flex">
                <img
                  src="/static/mob/images/contents/img_washcling_01.png"
                  alt=""
                />
              </div>
              <div className="flex cont">
                <div className="txt_group">
                  <p className="tit">간편한 세차 예약</p>
                  <p className="desc">
                    원하는 시간과 장소에서 어디서든 간편하고 편리하게
                    예약하세요.
                  </p>
                </div>
              </div>
            </div>

            <div className="grid js-ani">
              <div className="flex">
                <img
                  src="/static/mob/images/contents/img_washcling_02.png"
                  alt=""
                />
              </div>
              <div className="flex cont">
                <div className="txt_group">
                  <p className="tit">검증된 세차 서비스</p>
                  <p className="desc">
                    카셰어링 플랫폼 G car가 만든 비대면 세차 서비스, 월 7만 여
                    건의 G car 세차 노하우로 안전하고 편리한 서비스를 경험하세요
                  </p>
                </div>
              </div>
            </div>

            <div className="grid js-ani">
              <div className="flex">
                <img
                  src="/static/mob/images/contents/img_washcling_03.png"
                  alt=""
                />
              </div>
              <div className="flex cont">
                <div className="txt_group">
                  <p className="tit">합리적 가격, 뛰어난 수준</p>
                  <p className="desc">
                    정기세차 서비스를 이용하면 다양한 혜택이 제공됩니다.
                    합리적인 가격으로 이용하세요.
                  </p>
                </div>
              </div>
            </div>

            <div className="grid js-ani">
              <div className="flex">
                <img
                  src="/static/mob/images/contents/img_washcling_04.png"
                  alt=""
                />
              </div>
              <div className="flex cont">
                <div className="txt_group">
                  <p className="tit">전기차 세차서비스</p>
                  <p className="desc">
                    전기차도 간편하게, 더 퍼펙트하게 관리하세요.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="washcling__footer_info">
          <div className="tit js-ani">
            <h2>
              세차클링만의
              <br />
              <span className="col-sblue">세차 시스템</span>
            </h2>
          </div>
          <div className="cling-system">
            <div className="box-item js-ani">
              <div className="box-item-group">
                <div className="tit">친환경 워터리스</div>
                <p>
                  친환경 워터리스 세차, 물을 단 500ml 밖에
                  <br /> 사용하지 않습니다.
                </p>
              </div>
            </div>
            <div className="box-item js-ani">
              <div className="box-item-group">
                <div className="tit">검증된 제품 사용</div>
                <p>
                  윤활제와 계면활성제 비율이 검증된 제품을
                  <br />
                  사용하고 있습니다.
                </p>
              </div>
            </div>
            <div className="box-item js-ani">
              <div className="box-item-group">
                <div className="tit">주기적인 관리</div>
                <p>
                  세차클링은 주기적으로 타월과 세차 장비를
                  <br /> 관리하고 있습니다.
                </p>
              </div>
            </div>
            <div className="box-item js-ani">
              <div className="box-item-group">
                <div className="tit">깔끔하고 안전한 서비스</div>
                <p>
                  주변시설 오염, 소음발생 없이 다양한
                  <br /> 환경에서도 깔끔하고 안전한 서비스를 제공합니다.
                </p>
              </div>
            </div>
          </div>

          <div className="btn-primary">
            <button type="button" className="btn" onClick={downloadLink}>
              세차클링 앱 다운로드
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
