import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const [clz, setClz] = useState('');

  const onClickFamilySite = () => {
    setClz(clz == 'active' ? '' : 'active');
  };

  return (
    <>
      <div className="ui-top">
        <a href="#" className="btn-top">
          <span className="hide-txt">페이지 상단으로 이동</span>
        </a>
      </div>
      <footer id="footer">
        <div className="link-section">
          <div className="module">
            <div className="link-list">
              <a
                href="https://www.lotterentacar.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                롯데렌터카
              </a>
              <a
                href="https://www.lotteautocare.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                롯데오토케어
              </a>
              <a
                href="https://www.lotte-autolease.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                롯데오토리스
              </a>
              <a
                href="https://www.lotteautoauction.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                롯데오토옥션
              </a>
              <a
                href="https://www.lpoint.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                L.POINT
              </a>
              <a
                href="https://www.greencar.co.kr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                G car
              </a>
            </div>

            <div className={`family-site ${clz}`}>
              <button className="btn-fm" onClick={onClickFamilySite}>
                FAMILY SITE <i className="ico bot-arrow sm"></i>
              </button>
              <div className="layer">
                <div className="item">
                  <a
                    href="https://www.lotterental.com/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    롯데렌탈
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.lotterentacar.net/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    롯데렌터카
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.lotteautocare.com/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    롯데오토케어
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.lotte-autolease.com/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    롯데오토리스
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.lotteautoauction.net/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    롯데오토옥션
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.myomee.com/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    묘미
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.greencar.co.kr/"
                    target="_blank"
                    title="새창 열기"
                    rel="noopener noreferrer"
                  >
                    G car
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="foot-cont">
          <div className="corp-module">
            <div className="ci">
              <img
                src="/static/web/images/common/logo_clingwash.png"
                alt="세차클링"
              />
            </div>
            <div className="corp-list">
              <a
                href="https://www.greencar.co.kr/service/company"
                className="in"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                회사소개
              </a>
              <a
                href="#"
                className="pop_open"
                data-id="modal_1"
                onClick={() => {
                  new window.UiPopup('.modal').bindEvent();
                }}
              >
                인재채용
              </a>
              <Link to="clause">회원이용약관</Link>
              <Link to="clausetw" style={{ color: '#42c0c0' }}>
                개인정보 처리방침
              </Link>

              <Link to="clauseth">위치기반서비스 이용약관</Link>
            </div>
            <p className="corp-info">
              (주) 그린카/서울특별시 강남구 테헤란로 70길 16(대치동)/TEL
              02-528-7741
              <br />
              사업자등록번호 220-87-91595/통신판매업신고번호
              제2011-서울강남-01456호
            </p>

            {/* <div className="affiliate">
              <div className="sns-module">
                <p className="txt">그린카 공식SNS채널</p>
                <a
                  href="https://www.instagram.com/greencar_kr/"
                  className="in"
                  target="_blank"
                  title="새창 열기"
                  rel="noopener noreferrer"
                >
                  <span className="hide-txt">인스타그램</span>
                </a>
                <a
                  href="http://blog.naver.com/greencar_co"
                  className="nb"
                  target="_blank"
                  title="새창 열기"
                  rel="noopener noreferrer"
                >
                  <span className="hide-txt">네이버블로그</span>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCG0WGiX5ZC9JxjSZIf5GZDQ"
                  className="yt"
                  target="_blank"
                  title="새창 열기"
                  rel="noopener noreferrer"
                >
                  <span className="hide-txt">유투브</span>
                </a>
                <a
                  href="https://www.facebook.com/greencarkorea"
                  className="fb"
                  target="_blank"
                  title="새창 열기"
                  rel="noopener noreferrer"
                >
                  <span className="hide-txt">페이스북</span>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
