/**
 * <pre>
 * @title pages.ts
 * @desc 페이지 정보
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.09.28 17:37:13
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.28.  김완수  최초작성
 *  2022.09.29.  김완수  구조 변경
 */
import { Combine } from '@gngs/client-commons';
import {
  createScreen,
  GngGhpScreen,
  GngGhpScreenType,
  SCREEN_ID_PREFIX,
  toBizCode
} from './screen';

// export type Page = { id: string; title: string; url?: string };
export type Page = Combine<
  GngGhpScreen,
  {
    readonly url: string;
    title?: string;
    contentClass?: string;
  }
>;

export function createPage(
  screenId: string,
  screenType: GngGhpScreenType,
  name: string,
  contentClass?: string,
  title?: string,
  url?: string
) {
  const [deviceType, bizCode, ...suffix] = screenId
    .replace(SCREEN_ID_PREFIX, '')
    .split('_');
  const screen = createScreen(
    deviceType,
    toBizCode(bizCode),
    suffix.join('_'),
    screenType,
    name
  );
  const page: Page = {
    ...screen,
    name: name,
    contentClass: contentClass,
    // title: title,
    title: title || screen.name,
    url: url || `${deviceType == 'MO' ? '/m' : ''}/${screen.id}`
  };
  return page;
}

const crtPg = createPage;

// prettier-ignore
const webMain: Page = { id: 'webMain', bizCode: '#', type: 'Empty', url: '/*' };
const mobileMain: Page = {
  id: 'mobileMain',
  bizCode: '#',
  type: 'Empty',
  url: '/m/*'
};
const pageList: Page = {
  id: 'pageList',
  bizCode: '#',
  type: 'Empty',
  url: '/pageList'
};
// prettier-ignore
const notFound: Page = { id: 'notFound', bizCode: '#', type: 'Empty', url: '/*' };

export const pages: Page[] = [
  crtPg('SCR_H_CW_PC_MA_01', 'Main', '메인메뉴 ', '', '', 'index'),
  crtPg('SCR_H_CW_MO_MA_01', 'M_Main', '메인메뉴 ', '', '', '/m/index'),
  crtPg('SCR_H_CW_PC_MA_02', 'Contents', '메인메뉴 (=햄버거 메뉴) '),
  crtPg('SCR_H_CW_MO_MA_02', 'M_Contents', '메인메뉴 (=햄버거 메뉴) '),
  crtPg(
    'SCR_H_CW_PC_CW_01',
    'Contents',
    '세차클링 ',
    'greencar clean bg-parallax',
    '',
    'about'
  ),
  crtPg('SCR_H_CW_MO_CW_01', 'M_Contents', '세차클링 ', '', '', '/m/about'),
  crtPg(
    'SCR_H_CW_PC_CW_02',
    'Contents',
    '상품소개 ',
    'greencar product pad-b',
    '',
    'service'
  ),
  crtPg('SCR_H_CW_MO_CW_02', 'M_Contents', '상품소개 ', '', '', '/m/service'),
  crtPg(
    'SCR_H_CW_PC_CW_03',
    'Contents',
    '이용방법 ',
    'greencar bs',
    '',
    'about/guide'
  ),
  crtPg(
    'SCR_H_CW_MO_CW_03',
    'M_Contents',
    '이용방법 ',
    '',
    '',
    '/m/about/guide'
  ),
  crtPg(
    'SCR_H_CW_PC_SV_01',
    'Contents',
    '클링비즈 ',
    'service biz',
    '',
    'workguide/benefit'
  ),
  crtPg(
    'SCR_H_CW_MO_SV_01',
    'M_Contents',
    '클링비즈 ',
    '',
    '',
    '/m/workguide/benefit'
  ),
  crtPg(
    'SCR_H_CW_PC_SV_02',
    'Contents',
    '클링패스 ',
    'service',
    '',
    'clingpass'
  ),
  crtPg('SCR_H_CW_MO_SV_02', 'M_Contents', '클링패스 ', '', '', '/m/clingpass'),
  crtPg(
    'SCR_H_CW_PC_PS_01',
    'Contents',
    '제휴안내 ',
    'ps pad-b',
    '',
    'alliance'
  ),
  crtPg('SCR_H_CW_MO_PS_01', 'M_Contents', '제휴안내 ', '', '', '/m/alliance'),
  crtPg(
    'SCR_H_CW_PC_PS_02',
    'Contents',
    '제휴문의 (작성) ',
    'ps pad-b',
    '',
    'alliance/input'
  ),
  crtPg(
    'SCR_H_CW_MO_PS_02',
    'M_Contents',
    '제휴문의 (작성) ',
    '',
    '',
    '/m/alliance/input'
  ),
  crtPg(
    'SCR_H_CW_PC_CS_01',
    'Contents',
    '고객센터 ',
    'customer',
    '',
    'customer'
  ),
  crtPg('SCR_H_CW_MO_CS_01', 'M_Contents', '고객센터 ', '', '', '/m/customer'),
  crtPg(
    'SCR_H_CW_PC_CS_02',
    'Contents',
    '자주묻는질문 리스트',
    'customer',
    '',
    'faq/list'
  ),
  crtPg(
    'SCR_H_CW_MO_CS_02',
    'M_Contents',
    '자주묻는질문 리스트',
    '',
    '',
    '/m/faq/list'
  ),
  crtPg('SCR_H_CW_PC_CS_03', 'Contents', '자주묻는질문 검색결과'),
  crtPg('SCR_H_CW_MO_CS_03', 'M_Contents', '자주묻는질문 검색결과'),
  crtPg('SCR_H_CW_PC_CS_04', 'Contents', '자주묻는질문 검색결과 없음'),
  crtPg('SCR_H_CW_MO_CS_04', 'M_Contents', '자주묻는질문 검색결과 없음'),
  crtPg('SCR_H_CW_PC_CS_05', 'Contents', '자주묻는질문 게시물 없음'),
  crtPg('SCR_H_CW_MO_CS_05', 'M_Contents', '자주묻는질문 게시물 없음'),
  crtPg(
    'SCR_H_CW_PC_CS_06',
    'Contents',
    '공지사항 리스트',
    'customer',
    '',
    'notice/list'
  ),
  crtPg(
    'SCR_H_CW_MO_CS_06',
    'M_Contents',
    '공지사항 리스트',
    '',
    '',
    '/m/notice/list'
  ),
  crtPg(
    'SCR_H_CW_PC_CS_07/:id',
    'Contents',
    '공지사항 상세',
    'customer',
    '',
    'notice/view/:id'
  ),
  crtPg(
    'SCR_H_CW_MO_CS_07/:id',
    'M_Contents',
    '공지사항 상세',
    '',
    '',
    '/m/notice/view/:id'
  ),
  crtPg('SCR_H_CW_PC_CS_08', 'Contents', '공지사항 검색결과'),
  crtPg('SCR_H_CW_MO_CS_08', 'M_Contents', '공지사항 검색결과'),
  crtPg('SCR_H_CW_PC_CS_09', 'Contents', '공지사항 검색결과 없음'),
  crtPg('SCR_H_CW_MO_CS_09', 'M_Contents', '공지사항 검색결과 없음'),
  crtPg('SCR_H_CW_PC_CS_10', 'Contents', '공지사항 게시물 없음'),
  crtPg('SCR_H_CW_MO_CS_10', 'M_Contents', '공지사항 게시물 없음'),
  crtPg(
    'SCR_H_CW_PC_TC_01',
    'Contents',
    '회원이용약관',
    'terms',
    '',
    '/clause'
  ),
  crtPg(
    'SCR_H_CW_MO_TC_01',
    'M_ContentsEmpty',
    '회원이용약관 ',
    '',
    '',
    '/m/clause'
  ),
  crtPg(
    'SCR_H_CW_PC_TC_01',
    'Contents',
    '개인정보 처리방침 ',
    'terms',
    '',
    '/clausetw'
  ),
  crtPg(
    'SCR_H_CW_MO_TC_01',
    'M_ContentsEmpty',
    '개인정보 처리방침 ',
    '',
    '',
    '/m/clausetw'
  ),
  crtPg(
    'SCR_H_CW_PC_TC_01',
    'Contents',
    '개인정보 처리방침 ',
    'terms',
    '',
    '/clauseth'
  ),
  crtPg(
    'SCR_H_CW_MO_TC_01',
    'M_ContentsEmpty',
    '개인정보 처리방침 ',
    '',
    '',
    '/m/clauseth'
  ),
  crtPg('SCR_H_CW_PC_PO_ER_01', 'Contents', ' Full Type 에러 1'),
  crtPg('SCR_H_CW_MO_PO_ER_01', 'M_Contents', ' Full Type 에러 1'),
  crtPg('SCR_H_CW_PC_PO_ER_02', 'Contents', ' Full Type 에러 2'),
  crtPg('SCR_H_CW_MO_PO_ER_02', 'M_Contents', ' Full Type 에러 2'),
  crtPg(
    'SCR_H_CW_PC_PO_APP_01',
    'Contents',
    ' 사이즈 및 버튼 Type 1 (텍스트형)'
  ),
  crtPg(
    'SCR_H_CW_MO_PO_APP_01',
    'M_Contents',
    ' 사이즈 및 버튼 Type 1 (텍스트형)'
  ),
  crtPg('SCR_H_CW_PC_PO_APP_02', 'Contents', ' 사이즈 및 버튼 Type 2'),
  crtPg('SCR_H_CW_MO_PO_APP_02', 'M_Contents', ' 사이즈 및 버튼 Type 2'),
  crtPg('SCR_H_CW_PC_PO_APP_03', 'Contents', ' 사이즈 및 버튼 Type 3'),
  crtPg('SCR_H_CW_MO_PO_APP_03', 'M_Contents', ' 사이즈 및 버튼 Type 3'),
  crtPg('SCR_H_CW_PC_PO_IS_01', 'Contents', ' 사이즈 Type 4'),
  crtPg('SCR_H_CW_MO_PO_IS_01', 'M_Contents', ' 사이즈 Type 4'),

  // 사용자 정의
  pageList,
  notFound
];
