/**
 * <pre>
 * @title getEnv.ts
 * @desc 빌드 환경 변수 parsing 헬퍼 함수
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.19
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.19.  김완수  최초작성
 */
export type EnvValueTransform<T> = (val?: string) => T;

const echo: EnvValueTransform<string> = (val?: string) => val || '';

export function getEnvTo<T>(suffix: string, transform: EnvValueTransform<T>) {
  const val = process.env[`REACT_APP_${suffix}`];
  return transform(val);
}

export function getEnv(suffix: string) {
  return getEnvTo(suffix, echo);
}

export const toBooleanFromEnv = (val?: string) => {
  val = val?.toLowerCase();
  if (val === 't' || val === 'true' || val === '1') {
    return true;
  }
  return false;
};
