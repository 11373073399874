/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_SV_01.tsx
 * @desc 클링비즈
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_MO_SV_01() {
  return (
    <>
      <div className="contents service">
        <div className="js-inview mb40">
          <p className="desc-tit inview-el nth-child-7">
            합리적이고 믿을 수 있는
            <br /> 클링비즈
          </p>
        </div>

        <section className="section-wrap bg-parallax">
          <div className="img-item-wrap">
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/clean_biz01.png"
                className=""
              />
              <div className="desc">
                <div className="img">
                  <img src="/static/mob/images/icon/icon_biz01.svg" />
                </div>
                <div className="text">
                  <strong>편리한 서비스 이용</strong>
                  <p>
                    쉽고 간편하게 원하는 시간에
                    <br />
                    어디서나 서비스 이용
                  </p>
                </div>
              </div>
            </div>
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/clean_biz02.png"
                className="l"
              />
              <div className="desc">
                <div className="img">
                  <img src="/static/mob/images/icon/icon_biz02.svg" />
                </div>
                <div className="text">
                  <strong>가격 경쟁력 보유</strong>
                  <p>
                    국내 최저 가격으로
                    <br />
                    정기세차 서비스 이용
                  </p>
                </div>
              </div>
            </div>
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/clean_biz03.png"
                className=""
              />
              <div className="desc">
                <div className="img">
                  <img src="/static/mob/images/icon/icon_biz03.svg" />
                </div>
                <div className="text">
                  <strong>전문성 높은 서비스</strong>
                  <p>
                    전문가가 케어 해주는 서비스로
                    <br />
                    연 70,000번 이상의 세차 진행과
                    <br />
                    종합 소독 방역 서비스 제공
                  </p>
                  <p className="bold cicle-noti">전문 세차원 운영</p>
                </div>
              </div>
            </div>
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/clean_biz04.png"
                className=""
              />
              <div className="desc">
                <div className="img">
                  <img src="/static/mob/images/icon/icon_biz04.svg" />
                </div>
                <div className="text">
                  <strong>전문 케어 서비스</strong>
                  <p>
                    현재 요구 사항에 대한 진단을
                    <br />
                    해드리고 상품에 맞는 솔루션 제공
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="clean_service">
          <h1 className="desc-tit pt60 js-ani">
            클링비즈 <span className="col-sblue">파트너스</span>
          </h1>
          <div className="clean-se-bg mt32 js-ani">
            <p className="sec-tit">롯데 그룹사</p>
            <ul className="logo-area">
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group01.png"
                  alt="롯데케미칼"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group02.png"
                  alt="롯데렌탈"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group03.png"
                  alt="롯데마트"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group04.png"
                  alt="롯데중앙연구소"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group05.png"
                  alt="롯데건설"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group06.png"
                  alt="롯데하이마트"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_group07.png"
                  alt="롯데호텔"
                />
              </li>
            </ul>
          </div>
          <div className="clean-se-bg mt12 js-ani">
            <p className="sec-tit">생활 주거 영역</p>
            <ul className="logo-area">
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_life01.png"
                  alt="롯데렌터카"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_life02.png"
                  alt="gs엠비즈"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_life03.png"
                  alt="롯데캐슬"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_life04.png"
                  alt="어바니엘"
                />
              </li>
              <li className="bz-img-wrap">
                <img
                  src="/static/mob/images/contents/img_life05.png"
                  alt="롯데렌터카 G car"
                />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
