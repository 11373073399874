import React, { useEffect } from 'react';
import { menuList } from '../../../../utils/getPage';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { registUI } from '../../../../utils/web_common_ui';

export default function MainHeader(props: {
  headerType?: string;
  pageTitle?: string;
}) {
  const navigate = useNavigate();

  const { headerType = '', pageTitle = '' } = props;

  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.uiMenu) && _.isFunction(window.headerPosi)) {
        registUI('#header', window.uiMenu);
        if (headerType == 'sub') {
          registUI('#header', window.headerPosi);
        }

        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
  }, []);

  return (
    <header id="header">
      <div className={`head-cont ${headerType}`}>
        <div className="head-module">
          <div className="logo">
            <Link
              to="/m/index"
              className="main-logo"
              onClick={() => {
                document.body.classList.remove('menu-open');
              }}
            >
              <span className="hide-txt">Greencar</span>
            </Link>
            <button
              type="button"
              className="btn-head"
              onClick={() => {
                history.back();
              }}
            >
              <i className="ico back lg"></i>
            </button>
          </div>
          {pageTitle != '' ? (
            <h2 className="title">{pageTitle}</h2>
          ) : (
            <h2 className="title"></h2>
          )}
          <div className="menu">
            <button className="btn-menu" title="menu" aria-label="Sidebar menu">
              <div className="module">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <nav className="gnb-wrap">
        <ul className="gnb">
          {menuList
            .filter((item) => item.device == 'MOBILE' && item.order !== 5)
            .map((menuGroup: any, index: number) => {
              return (
                <li className="menu-01" key={index}>
                  <h4>
                    <Link to={'#'} onClick={(e) => e.preventDefault()}>
                      {menuGroup.title}
                    </Link>
                    <i className="ico plus sm"></i>
                  </h4>
                  <ul className="depth">
                    {_.map(menuGroup.children, (menu: any, sIndex: number) => {
                      return (
                        <li className="menu-02" key={sIndex}>
                          {menu.link === 'Y' ? (
                            <a
                              href={menu.url}
                              target="_blank"
                              title="새창 열기"
                              rel="noopener noreferrer"
                            >
                              <span className="hidden">
                                <span className="ani blank">
                                  {menu.subTitle}
                                </span>
                              </span>
                            </a>
                          ) : (
                            <Link
                              to={menu.url}
                              onClick={() => {
                                document.body.classList.remove('menu-open');
                              }}
                            >
                              {menu.subTitle}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
        </ul>

        <div className="gnb-footer">
          <div className="link-biz">
            <div className="btn line full">
              <a
                className="line"
                href="https://cwc-api.clingwash.co.kr/main/index.do"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                클링비즈
                <i className="ico arrow-right-up-navy xs ml4"></i>
              </a>
            </div>
          </div>
          <div className="link-wrap">
            <div className="link">
              <a
                href="https://www.greencar.co.kr/m/service/company"
                className="in"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                회사소개
              </a>
            </div>
            <hr className="bar" />
            <div className="link">
              <a
                href="#"
                className="pop_open"
                data-id="modal_1"
                onClick={() => {
                  new window.UiPopup('.modal').bindEvent();
                }}
              >
                인재채용
                <i className="ico arrow-right-up xs ml4"></i>
              </a>
            </div>
          </div>
          <ul className="sns-wrap">
            <li>
              <a
                href="https://www.instagram.com/greencar_kr"
                className="in"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <img
                  src="/static/mob/images/icon/icon_Instagram.svg"
                  alt="인스타그램"
                />
              </a>
            </li>
            <li>
              <a
                href="http://blog.naver.com/greencar_co"
                className="nb"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <img
                  src="/static/mob/images/icon/icon_naverblog.svg"
                  alt="네이버블로그"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/greencarkorea"
                className="fb"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <img
                  src="/static/mob/images/icon/icon_facebook.svg"
                  alt="페이스북"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCG0WGiX5ZC9JxjSZIf5GZDQ"
                className="yt"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <img
                  src="/static/mob/images/icon/icon_youtube.svg"
                  alt="유튜브"
                />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
