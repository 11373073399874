/**
 * <pre>
 * @title config.ts
 * @desc config 모듈
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.19
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.19.  김완수  최초작성
 *  2022.09.27.  김완수  네이티브 연동 관련 필드 추가(플랫폼, 앱 URL 스키마 설정)
 *  2022.09.28.  김완수  페이지 관련 설정 추가
 */
import { BuildEnv, toBuildEnv } from './helper/buildType';
import { getEnv, getEnvTo, toBooleanFromEnv } from './helper/getEnv';
import { Page, pages } from './pages/pages';

export type Config = {
  readonly buildEnv: BuildEnv; // 빌드 환경
  readonly domain: {
    readonly webHost?: string; // 그린카 웹 도메인 hostname
    readonly webUrl?: string; // 그린카 웹 도메인 url
  };
  readonly api: {
    readonly greencarApi?: string; // cwa-api url(임시)
    readonly greencarDevApi?: string; // cwa-api dev 환경 url
    readonly greencarStgApi?: string; // cwa-api stg 환경 url
    readonly greencarPrdApi?: string; // cwa-api prd 환경 url
    readonly nativeSchemeName?: string; // (URL scheme 방식) 네이티브 인터페이스 스키마 명
    readonly iosUseScheme?: boolean; // ios URL scheme 사용 여부
    readonly aosUseScheme?: boolean; // aos URL scheme 사용 여부
  };
  readonly http: {
    readonly useCsrf: boolean; // CSRF 토큰 사용 여부
    readonly csrfHeaderName?: string; // CSRF HTTP 헤더 속성명
    readonly csrfCookieName?: string; // CSRF 쿠키명
    readonly greencarAccessTokenExpires?: number; // 그린카 api 엑세스 토큰 만료시간
    readonly greencarRefreshTokenExpires?: number; // 그린카 api 리프레쉬 토큰 만료시간
    readonly greencarApiCurSysCd?: string; // 그린카 api 시스템 구분코드(http 헤더에 포함)
  };
  readonly external: {
    readonly kakao: {
      readonly apiKey?: string; // 카카오 api 키
    };
  };
  readonly key: Partial<Record<string, string>>;

  // 경로 정보
  readonly paths: {
    readonly localesPath: string; //  번역 파일 경로 (root 경로: ./public)
    readonly trFilePrefix: string; // 번역 파일명 prefix (예: translation.*.json)
  };
  readonly pages?: Page[]; // 페이지 정보
};

/**
 * @todo config 로드 시점, 정상 로드 검토(React context 대체 여부)
 */
export const config: Config = {
  buildEnv: getEnvTo('BUILD_ENV', toBuildEnv),
  domain: {
    webHost: getEnv('GREENCAR_WEB_HOST'),
    webUrl: getEnv('GREENCAR_WEB_URL')
  },
  api: {
    greencarApi: getEnv('GREENCAR_API'),
    greencarDevApi: getEnv('GREENCAR_DEV_API'),
    greencarStgApi: getEnv('GREENCAR_STG_API'),
    greencarPrdApi: getEnv('GREENCAR_PRD_API'),
    nativeSchemeName: getEnv('GREENCAR_NATIVE_SCHEME_NAME'),
    iosUseScheme: getEnvTo('GREENCAR_IOS_USE_SCHEME', toBooleanFromEnv),
    aosUseScheme: getEnvTo('GREENCAR_AOS_USE_SCHEME', toBooleanFromEnv)
  },
  http: {
    useCsrf: getEnvTo('GREENCAR_CSRF_USE', toBooleanFromEnv),
    csrfHeaderName: getEnv('GREENCAR_CSRF_HEADER_NAME'),
    csrfCookieName: getEnv('GREENCAR_CSRF_COOKIE_NAME'),
    greencarAccessTokenExpires: parseInt(
      getEnv('GREENCAR_ACCESS_TOKEN_EXPIRES_TIME')
    ),
    greencarRefreshTokenExpires: parseInt(
      getEnv('GREENCAR_REFRESH_TOKEN_EXPIRES_TIME')
    ),
    greencarApiCurSysCd: getEnv('GREENCAR_CUR_SYS_CD')
  },
  external: {
    kakao: {
      apiKey: getEnv('KAKAO_API_KEY')
    }
  },
  key: {
    greencarApiAccessToken: getEnv('GREENCAR_API_ACCESS_TOKEN'),
    greencarApiRefreshToken: getEnv('GREENCAR_API_REFRESH_TOKEN')
  },
  paths: {
    localesPath: '/locales',
    trFilePrefix: 'translation'
  },
  pages: pages
};
