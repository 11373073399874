/* eslint-disable camelcase */
import NotFound from '../pages/notfound/NotFound';
import PageList from '../pages/pageList/PageList';
import MainLayout from '../components/layouts/web/MainLayout';
import ContentLayout from '../components/layouts/web/ContentLayout';
import ContentEmptyLayout from '../components/layouts/web/ContentEmptyLayout';
import MobileMainLayout from '../components/layouts/mob/MainLayout';
import MobileContentLayout from '../components/layouts/mob/ContentLayout';
import MobileContentEmptyLayout from '../components/layouts/mob/ContentEmptyLayout';
import EmptyLayout from '../components/layouts/web/EmptyLayout';
import GngCwhSCR_H_CW_PC_MA_01 from '../pages/SCR_H_CW_PC_MA_01/GngCwhSCR_H_CW_PC_MA_01';
import GngCwhSCR_H_CW_PC_MA_02 from '../pages/SCR_H_CW_PC_MA_02/GngCwhSCR_H_CW_PC_MA_02';
import GngCwhSCR_H_CW_PC_CW_01 from '../pages/SCR_H_CW_PC_CW_01/GngCwhSCR_H_CW_PC_CW_01';
import GngCwhSCR_H_CW_PC_CW_02 from '../pages/SCR_H_CW_PC_CW_02/GngCwhSCR_H_CW_PC_CW_02';
import GngCwhSCR_H_CW_PC_CW_03 from '../pages/SCR_H_CW_PC_CW_03/GngCwhSCR_H_CW_PC_CW_03';
import GngCwhSCR_H_CW_PC_SV_01 from '../pages/SCR_H_CW_PC_SV_01/GngCwhSCR_H_CW_PC_SV_01';
import GngCwhSCR_H_CW_PC_SV_02 from '../pages/SCR_H_CW_PC_SV_02/GngCwhSCR_H_CW_PC_SV_02';
import GngCwhSCR_H_CW_PC_PS_01 from '../pages/SCR_H_CW_PC_PS_01/GngCwhSCR_H_CW_PC_PS_01';
import GngCwhSCR_H_CW_PC_PS_02 from '../pages/SCR_H_CW_PC_PS_02/GngCwhSCR_H_CW_PC_PS_02';
import GngCwhSCR_H_CW_PC_CS_01 from '../pages/SCR_H_CW_PC_CS_01/GngCwhSCR_H_CW_PC_CS_01';
import GngCwhSCR_H_CW_PC_CS_02 from '../pages/SCR_H_CW_PC_CS_02/GngCwhSCR_H_CW_PC_CS_02';
import GngCwhSCR_H_CW_PC_CS_03 from '../pages/SCR_H_CW_PC_CS_03/GngCwhSCR_H_CW_PC_CS_03';
import GngCwhSCR_H_CW_PC_CS_04 from '../pages/SCR_H_CW_PC_CS_04/GngCwhSCR_H_CW_PC_CS_04';
import GngCwhSCR_H_CW_PC_CS_05 from '../pages/SCR_H_CW_PC_CS_05/GngCwhSCR_H_CW_PC_CS_05';
import GngCwhSCR_H_CW_PC_CS_06 from '../pages/SCR_H_CW_PC_CS_06/GngCwhSCR_H_CW_PC_CS_06';
import GngCwhSCR_H_CW_PC_CS_07 from '../pages/SCR_H_CW_PC_CS_07/GngCwhSCR_H_CW_PC_CS_07';
import GngCwhSCR_H_CW_PC_CS_08 from '../pages/SCR_H_CW_PC_CS_08/GngCwhSCR_H_CW_PC_CS_08';
import GngCwhSCR_H_CW_PC_CS_09 from '../pages/SCR_H_CW_PC_CS_09/GngCwhSCR_H_CW_PC_CS_09';
import GngCwhSCR_H_CW_PC_CS_10 from '../pages/SCR_H_CW_PC_CS_10/GngCwhSCR_H_CW_PC_CS_10';
import GngCwhSCR_H_CW_PC_TC_01 from '../pages/SCR_H_CW_PC_TC_01/GngCwhSCR_H_CW_PC_TC_01';
import GngCwhSCR_H_CW_PC_TC_02 from '../pages/SCR_H_CW_PC_TC_02/GngCwhSCR_H_CW_PC_TC_02';
import GngCwhSCR_H_CW_PC_TC_03 from '../pages/SCR_H_CW_PC_TC_03/GngCwhSCR_H_CW_PC_TC_03';
import GngCwhSCR_H_CW_PC_PO_ER_01 from '../pages/SCR_H_CW_PC_PO_ER_01/GngCwhSCR_H_CW_PC_PO_ER_01';
import GngCwhSCR_H_CW_PC_PO_ER_02 from '../pages/SCR_H_CW_PC_PO_ER_02/GngCwhSCR_H_CW_PC_PO_ER_02';
import GngCwhSCR_H_CW_PC_PO_APP_01 from '../pages/SCR_H_CW_PC_PO_APP_01/GngCwhSCR_H_CW_PC_PO_APP_01';
import GngCwhSCR_H_CW_PC_PO_APP_02 from '../pages/SCR_H_CW_PC_PO_APP_02/GngCwhSCR_H_CW_PC_PO_APP_02';
import GngCwhSCR_H_CW_PC_PO_APP_03 from '../pages/SCR_H_CW_PC_PO_APP_03/GngCwhSCR_H_CW_PC_PO_APP_03';
import GngCwhSCR_H_CW_PC_PO_IS_01 from '../pages/SCR_H_CW_PC_PO_IS_01/GngCwhSCR_H_CW_PC_PO_IS_01';
import GngCwhSCR_H_CW_MO_MA_01 from '../pages/SCR_H_CW_MO_MA_01/GngCwhSCR_H_CW_MO_MA_01';
import GngCwhSCR_H_CW_MO_MA_02 from '../pages/SCR_H_CW_MO_MA_02/GngCwhSCR_H_CW_MO_MA_02';
import GngCwhSCR_H_CW_MO_CW_01 from '../pages/SCR_H_CW_MO_CW_01/GngCwhSCR_H_CW_MO_CW_01';
import GngCwhSCR_H_CW_MO_CW_02 from '../pages/SCR_H_CW_MO_CW_02/GngCwhSCR_H_CW_MO_CW_02';
import GngCwhSCR_H_CW_MO_CW_03 from '../pages/SCR_H_CW_MO_CW_03/GngCwhSCR_H_CW_MO_CW_03';
import GngCwhSCR_H_CW_MO_SV_01 from '../pages/SCR_H_CW_MO_SV_01/GngCwhSCR_H_CW_MO_SV_01';
import GngCwhSCR_H_CW_MO_SV_02 from '../pages/SCR_H_CW_MO_SV_02/GngCwhSCR_H_CW_MO_SV_02';
import GngCwhSCR_H_CW_MO_PS_01 from '../pages/SCR_H_CW_MO_PS_01/GngCwhSCR_H_CW_MO_PS_01';
import GngCwhSCR_H_CW_MO_PS_02 from '../pages/SCR_H_CW_MO_PS_02/GngCwhSCR_H_CW_MO_PS_02';
import GngCwhSCR_H_CW_MO_CS_01 from '../pages/SCR_H_CW_MO_CS_01/GngCwhSCR_H_CW_MO_CS_01';
import GngCwhSCR_H_CW_MO_CS_02 from '../pages/SCR_H_CW_MO_CS_02/GngCwhSCR_H_CW_MO_CS_02';
import GngCwhSCR_H_CW_MO_CS_03 from '../pages/SCR_H_CW_MO_CS_03/GngCwhSCR_H_CW_MO_CS_03';
import GngCwhSCR_H_CW_MO_CS_04 from '../pages/SCR_H_CW_MO_CS_04/GngCwhSCR_H_CW_MO_CS_04';
import GngCwhSCR_H_CW_MO_CS_05 from '../pages/SCR_H_CW_MO_CS_05/GngCwhSCR_H_CW_MO_CS_05';
import GngCwhSCR_H_CW_MO_CS_06 from '../pages/SCR_H_CW_MO_CS_06/GngCwhSCR_H_CW_MO_CS_06';
import GngCwhSCR_H_CW_MO_CS_07 from '../pages/SCR_H_CW_MO_CS_07/GngCwhSCR_H_CW_MO_CS_07';
import GngCwhSCR_H_CW_MO_CS_08 from '../pages/SCR_H_CW_MO_CS_08/GngCwhSCR_H_CW_MO_CS_08';
import GngCwhSCR_H_CW_MO_CS_09 from '../pages/SCR_H_CW_MO_CS_09/GngCwhSCR_H_CW_MO_CS_09';
import GngCwhSCR_H_CW_MO_CS_10 from '../pages/SCR_H_CW_MO_CS_10/GngCwhSCR_H_CW_MO_CS_10';
import GngCwhSCR_H_CW_MO_TC_01 from '../pages/SCR_H_CW_MO_TC_01/GngCwhSCR_H_CW_MO_TC_01';
import GngCwhSCR_H_CW_MO_TC_02 from '../pages/SCR_H_CW_MO_TC_02/GngCwhSCR_H_CW_MO_TC_02';
import GngCwhSCR_H_CW_MO_TC_03 from '../pages/SCR_H_CW_MO_TC_03/GngCwhSCR_H_CW_MO_TC_03';
import GngCwhSCR_H_CW_MO_PO_ER_01 from '../pages/SCR_H_CW_MO_PO_ER_01/GngCwhSCR_H_CW_MO_PO_ER_01';
import GngCwhSCR_H_CW_MO_PO_ER_02 from '../pages/SCR_H_CW_MO_PO_ER_02/GngCwhSCR_H_CW_MO_PO_ER_02';
import GngCwhSCR_H_CW_MO_PO_APP_01 from '../pages/SCR_H_CW_MO_PO_APP_01/GngCwhSCR_H_CW_MO_PO_APP_01';
import GngCwhSCR_H_CW_MO_PO_APP_02 from '../pages/SCR_H_CW_MO_PO_APP_02/GngCwhSCR_H_CW_MO_PO_APP_02';
import GngCwhSCR_H_CW_MO_PO_APP_03 from '../pages/SCR_H_CW_MO_PO_APP_03/GngCwhSCR_H_CW_MO_PO_APP_03';
import GngCwhSCR_H_CW_MO_PO_IS_01 from '../pages/SCR_H_CW_MO_PO_IS_01/GngCwhSCR_H_CW_MO_PO_IS_01';

import { agentCheck } from './download';

export function getLayout(layout: string) {
  switch (layout) {
    case 'Main':
      return MainLayout;
    case 'Contents':
      return ContentLayout;
    case 'ContentEmpty':
      return ContentEmptyLayout;
    case 'M_Main':
      return MobileMainLayout;
    case 'M_Contents':
      return MobileContentLayout;
    case 'M_ContentsEmpty':
      return MobileContentEmptyLayout;
    default:
      return EmptyLayout;
    // if (agentCheck() === 'mobile') {
    //   // 모바일
    //   if (location.pathname == '/') {
    //     location.pathname = '/m/index';
    //     layout = 'M_Main';
    //     return MobileMainLayout;
    //   } else {
    //     layout = 'M_Contents';
    //     return EmptyMobLayout;
    //   }
    // } else if (agentCheck() === 'window') {
    //   // PC
    //   if (location.pathname == '/') {
    //     location.pathname = '/index';
    //     layout = 'Main';
    //     return MainLayout;
    //   } else {
    //     layout = 'Contents';
    //     return EmptyLayout;
    //   }
    // } else {
    //   return EmptyLayout;
    // }
    // if (location.pathname.indexOf('PC') == 10) {
    //   layout = 'Contents';
    //   return EmptyLayout;
    // } else if (location.pathname.indexOf('MO') == 12) {
    //   layout = 'M_Contents';
    //   return EmptyMobLayout;
    // } else {
    //   return EmptyLayout;
    // }
  }
}

export function getPage(pageId: string) {
  switch (pageId) {
    case 'SCR_H_CW_PC_MA_01':
      return GngCwhSCR_H_CW_PC_MA_01;
    case 'SCR_H_CW_PC_MA_02':
      return GngCwhSCR_H_CW_PC_MA_02;
    case 'SCR_H_CW_PC_CW_01':
      return GngCwhSCR_H_CW_PC_CW_01;
    case 'SCR_H_CW_PC_CW_02':
      return GngCwhSCR_H_CW_PC_CW_02;
    case 'SCR_H_CW_PC_CW_03':
      return GngCwhSCR_H_CW_PC_CW_03;
    case 'SCR_H_CW_PC_SV_01':
      return GngCwhSCR_H_CW_PC_SV_01;
    case 'SCR_H_CW_PC_SV_02':
      return GngCwhSCR_H_CW_PC_SV_02;
    case 'SCR_H_CW_PC_PS_01':
      return GngCwhSCR_H_CW_PC_PS_01;
    case 'SCR_H_CW_PC_PS_02':
      return GngCwhSCR_H_CW_PC_PS_02;
    case 'SCR_H_CW_PC_CS_01':
      return GngCwhSCR_H_CW_PC_CS_01;
    case 'SCR_H_CW_PC_CS_02':
      return GngCwhSCR_H_CW_PC_CS_02;
    case 'SCR_H_CW_PC_CS_03':
      return GngCwhSCR_H_CW_PC_CS_03;
    case 'SCR_H_CW_PC_CS_04':
      return GngCwhSCR_H_CW_PC_CS_04;
    case 'SCR_H_CW_PC_CS_05':
      return GngCwhSCR_H_CW_PC_CS_05;
    case 'SCR_H_CW_PC_CS_06':
      return GngCwhSCR_H_CW_PC_CS_06;
    case 'SCR_H_CW_PC_CS_07/:id':
      return GngCwhSCR_H_CW_PC_CS_07;
    case 'SCR_H_CW_PC_CS_08':
      return GngCwhSCR_H_CW_PC_CS_08;
    case 'SCR_H_CW_PC_CS_09':
      return GngCwhSCR_H_CW_PC_CS_09;
    case 'SCR_H_CW_PC_CS_10':
      return GngCwhSCR_H_CW_PC_CS_10;
    case 'SCR_H_CW_PC_TC_01':
      return GngCwhSCR_H_CW_PC_TC_01;
    case 'SCR_H_CW_PC_TC_02':
      return GngCwhSCR_H_CW_PC_TC_02;
    case 'SCR_H_CW_PC_TC_03':
      return GngCwhSCR_H_CW_PC_TC_03;
    case 'SCR_H_CW_PC_PO_ER_01':
      return GngCwhSCR_H_CW_PC_PO_ER_01;
    case 'SCR_H_CW_PC_PO_ER_02':
      return GngCwhSCR_H_CW_PC_PO_ER_02;
    case 'SCR_H_CW_PC_PO_APP_01':
      return GngCwhSCR_H_CW_PC_PO_APP_01;
    case 'SCR_H_CW_PC_PO_APP_02':
      return GngCwhSCR_H_CW_PC_PO_APP_02;
    case 'SCR_H_CW_PC_PO_APP_03':
      return GngCwhSCR_H_CW_PC_PO_APP_03;
    case 'SCR_H_CW_PC_PO_IS_01':
      return GngCwhSCR_H_CW_PC_PO_IS_01;
    case 'SCR_H_CW_MO_MA_01':
      return GngCwhSCR_H_CW_MO_MA_01;
    case 'SCR_H_CW_MO_MA_02':
      return GngCwhSCR_H_CW_MO_MA_02;
    case 'SCR_H_CW_MO_CW_01':
      return GngCwhSCR_H_CW_MO_CW_01;
    case 'SCR_H_CW_MO_CW_02':
      return GngCwhSCR_H_CW_MO_CW_02;
    case 'SCR_H_CW_MO_CW_03':
      return GngCwhSCR_H_CW_MO_CW_03;
    case 'SCR_H_CW_MO_SV_01':
      return GngCwhSCR_H_CW_MO_SV_01;
    case 'SCR_H_CW_MO_SV_02':
      return GngCwhSCR_H_CW_MO_SV_02;
    case 'SCR_H_CW_MO_PS_01':
      return GngCwhSCR_H_CW_MO_PS_01;
    case 'SCR_H_CW_MO_PS_02':
      return GngCwhSCR_H_CW_MO_PS_02;
    case 'SCR_H_CW_MO_CS_01':
      return GngCwhSCR_H_CW_MO_CS_01;
    case 'SCR_H_CW_MO_CS_02':
      return GngCwhSCR_H_CW_MO_CS_02;
    case 'SCR_H_CW_MO_CS_03':
      return GngCwhSCR_H_CW_MO_CS_03;
    case 'SCR_H_CW_MO_CS_04':
      return GngCwhSCR_H_CW_MO_CS_04;
    case 'SCR_H_CW_MO_CS_05':
      return GngCwhSCR_H_CW_MO_CS_05;
    case 'SCR_H_CW_MO_CS_06':
      return GngCwhSCR_H_CW_MO_CS_06;
    case 'SCR_H_CW_MO_CS_07/:id':
      return GngCwhSCR_H_CW_MO_CS_07;
    case 'SCR_H_CW_MO_CS_08':
      return GngCwhSCR_H_CW_MO_CS_08;
    case 'SCR_H_CW_MO_CS_09':
      return GngCwhSCR_H_CW_MO_CS_09;
    case 'SCR_H_CW_MO_CS_10':
      return GngCwhSCR_H_CW_MO_CS_10;
    case 'SCR_H_CW_MO_TC_01':
      return GngCwhSCR_H_CW_MO_TC_01;
    case 'SCR_H_CW_MO_TC_02':
      return GngCwhSCR_H_CW_MO_TC_02;
    case 'SCR_H_CW_MO_TC_03':
      return GngCwhSCR_H_CW_MO_TC_03;
    case 'SCR_H_CW_MO_PO_ER_01':
      return GngCwhSCR_H_CW_MO_PO_ER_01;
    case 'SCR_H_CW_MO_PO_ER_02':
      return GngCwhSCR_H_CW_MO_PO_ER_02;
    case 'SCR_H_CW_MO_PO_APP_01':
      return GngCwhSCR_H_CW_MO_PO_APP_01;
    case 'SCR_H_CW_MO_PO_APP_02':
      return GngCwhSCR_H_CW_MO_PO_APP_02;
    case 'SCR_H_CW_MO_PO_APP_03':
      return GngCwhSCR_H_CW_MO_PO_APP_03;
    case 'SCR_H_CW_MO_PO_IS_01':
      return GngCwhSCR_H_CW_MO_PO_IS_01;
    case 'pageList':
      return PageList;
    default:
      if (agentCheck() === 'mobile') {
        return GngCwhSCR_H_CW_MO_PO_ER_01;
      } else if (agentCheck() === 'window') {
        return GngCwhSCR_H_CW_PC_PO_ER_01;
      } else {
        return NotFound;
      }
    // if (location.pathname.indexOf('PC') == 10) {
    //   return GngCwhSCR_H_CW_PC_PO_ER_01;
    // } else if (location.pathname.indexOf('MO') == 12) {
    //   return GngCwhSCR_H_CW_MO_PO_ER_01;
    // } else {
    //   return NotFound;
    // }
    //return NotFound;
  }
}

export type Menu = {
  title?: string;
  subTitle?: string;
  readonly url: string;
  readonly link: string;
};

export type MenuGroup = {
  title?: string;
  order?: number;
  device: 'WEB' | 'MOBILE';
  children: Array<Menu>;
};

export const menuList: Array<MenuGroup> = [
  {
    title: '세차클링',
    device: 'WEB',
    order: 1,
    children: [
      {
        title: '세차클링',
        subTitle: '세차클링',
        url: 'about',
        link: 'N'
      },
      {
        title: '이용방법',
        subTitle: '이용방법',
        url: 'about/guide',
        link: 'N'
      }
    ]
  },
  {
    title: '서비스',
    order: 2,
    device: 'WEB',
    children: [
      {
        title: '상품소개',
        subTitle: '상품소개',
        url: 'service',
        link: 'N'
      },
      {
        title: '클링비즈',
        subTitle: '클링비즈',
        url: 'workguide/benefit',
        link: 'N'
      },
      {
        title: '클링패스',
        subTitle: '클링패스',
        url: 'clingpass',
        link: 'N'
      },
      {
        title: 'G car',
        subTitle: 'G car',
        url: 'https://www.greencar.co.kr/',
        link: 'Y'
      }
    ]
  },
  {
    title: '파트너십',
    order: 3,
    device: 'WEB',
    children: [
      {
        title: '파트너십',
        subTitle: '제휴문의',
        url: 'alliance',
        link: 'N'
      }
    ]
  },
  {
    title: '고객센터',
    order: 4,
    device: 'WEB',
    children: [
      {
        title: '고객센터',
        subTitle: '고객센터',
        url: 'customer',
        link: 'N'
      },
      {
        title: '고객센터',
        subTitle: '자주묻는 질문',
        url: 'faq/list',
        link: 'N'
      },
      {
        title: '고객센터',
        subTitle: '공지사항',
        url: 'notice/list',
        link: 'N'
      }
    ]
  },
  {
    title: '이용약관',
    order: 5,
    device: 'WEB',
    children: [
      {
        title: '세차클링 회원 약관',
        subTitle: '세차클링 회원 약관',
        url: 'clause',
        link: 'N'
      },
      {
        title: '개인정보처리방침',
        subTitle: '개인정보처리방침',
        url: 'clausetw',
        link: 'N'
      },
      {
        title: '위치기반 서비스 이용약관',
        subTitle: '위치기반 서비스 이용약관',
        url: 'clauseth',
        link: 'N'
      }
    ]
  },
  {
    title: '세차클링',
    device: 'MOBILE',
    order: 1,
    children: [
      {
        title: '세차클링',
        subTitle: '세차클링',
        url: 'm/about',
        link: 'N'
      },
      {
        title: '이용방법',
        subTitle: '이용방법',
        url: 'm/about/guide',
        link: 'N'
      }
    ]
  },
  {
    title: '서비스',
    order: 2,
    device: 'MOBILE',
    children: [
      {
        title: '상품소개',
        subTitle: '상품소개',
        url: 'm/service',
        link: 'N'
      },
      {
        title: '클링비즈',
        subTitle: '클링비즈',
        url: 'm/workguide/benefit',
        link: 'N'
      },
      {
        title: '클링패스',
        subTitle: '클링패스',
        url: 'm/clingpass',
        link: 'N'
      },
      {
        title: 'G car',
        subTitle: 'G car',
        url: 'https://www.greencar.co.kr/',
        link: 'Y'
      }
    ]
  },
  {
    title: '파트너십',
    order: 3,
    device: 'MOBILE',
    children: [
      {
        title: '파트너십',
        subTitle: '제휴문의',
        url: 'm/alliance',
        link: 'N'
      }
    ]
  },
  {
    title: '고객센터',
    order: 4,
    device: 'MOBILE',
    children: [
      {
        title: '고객센터',
        subTitle: '고객센터',
        url: 'm/customer',
        link: 'N'
      },
      {
        title: '고객센터',
        subTitle: '자주묻는 질문',
        url: 'm/faq/list',
        link: 'N'
      },
      {
        title: '고객센터',
        subTitle: '공지사항',
        url: 'm/notice/list',
        link: 'N'
      }
    ]
  },
  {
    title: '이용약관',
    order: 5,
    device: 'MOBILE',
    children: [
      {
        title: '이용약관',
        subTitle: '이용약관',
        url: 'm/clause',
        link: 'N'
      },
      {
        title: '개인정보처리방침',
        subTitle: '개인정보처리방침',
        url: 'm/clausetw',
        link: 'N'
      },
      {
        title: '위치기반 서비스 이용약관',
        subTitle: '위치기반 서비스 이용약관',
        url: 'm/clauseth',
        link: 'N'
      }
    ]
  }
];
