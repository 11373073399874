/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_CS_01.tsx
 * @desc 고객센터
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  faqCtgyDefaultProps,
  selectFaqCtgyApi,
  noticeListDefaultProps,
  selectNoticeListApi
} from './../../api/ci/csCenter';
import _, { size } from 'lodash';
import { Link } from 'react-router-dom';
import { downloadLink } from '../../utils/download';

export default function GngCwhSCR_H_CW_PC_CS_01() {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [code, setSearchCode] = useState('');

  const defaultProps: faqCtgyDefaultProps = {
    faqCtgyList: []
  };
  const [faqCtgyData, setFaqCtgyData] = useState(defaultProps);

  const defaultListProps: noticeListDefaultProps = {
    noticeList: []
  };
  const [noticeListData, setNoticeListData] = useState(defaultListProps);

  useEffect(() => {
    selectFaqCtgyApi().then((res: any) => {
      setFaqCtgyData((prevState: any) => ({
        ...prevState,
        faqCtgyList: [...res.data.commCodeList]
      }));
    });

    selectNoticeListApi('1', '').then((res: any) => {
      let array: any[] = [];
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');

        if (res.data.noticeList[i].imprtncNtfYn == 'Y') {
          array.push(res.data.noticeList[i]);
        }
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...array]
      }));
    });
  }, []);

  // 검색어 입력, 변경
  const onChange = (e: any) => {
    if (e.target.value === '') {
      clearSearchValBtn();
    }
    setText(e.target.value);
  };

  // 검색어 초기화
  const clearSearchValBtn = () => {
    setText('');
  };

  return (
    <section className="content-layout">
      <div className="js-inview">
        <p className="desc xxl inview-el nth-child-7">
          안녕하세요, 세차클링 고객센터 입니다
          <br />
          무엇을 도와드릴까요?
        </p>
      </div>

      <div className="search">
        <div className="search-module">
          <div className="search-group">
            <i className="ico search lg"></i>
            <input
              className="ip-txt"
              type="text"
              placeholder="궁금한 내용을 검색하세요"
              title="검색어 입력"
              onChange={onChange}
              value={text}
              // onFocus={() => {
              //   clearSearchValBtn();
              // }}
            />
            <button
              type="button"
              className="btns primary"
              onClick={() => {
                if (text) {
                  navigate('/faq/list', {
                    state: {
                      searchCode: code,
                      searchValue: text
                    }
                  });
                }
              }}
            >
              검색
            </button>
            <button
              type="button"
              className="btns primary"
              onClick={clearSearchValBtn}
              style={{
                marginLeft: '5px'
              }}
            >
              초기화
            </button>
          </div>
        </div>
        <div className="link-module">
          {faqCtgyData.faqCtgyList.map((info) => {
            const list = (
              <a
                key={info.dtlCd}
                onClick={() => {
                  //etSearchCode(info.dtlCd);

                  navigate('/faq/list', {
                    state: {
                      searchCode: info.dtlCd,
                      searchValue: text
                    }
                  });
                }}
                style={{
                  cursor: 'pointer'
                }}
              >
                {info.dtlCdNm}
              </a>
            );
            return list;
          })}
        </div>
      </div>

      <div className="flex-box utility js-inview">
        <div className="flex ico01 inview-el nth-child-1">
          <div className="box">
            <div className="text-module">
              <p className="emp">02-528-7741</p>
              <p className="desc">월-금 09:00 – 17:00</p>
            </div>
          </div>
        </div>
        <div className="flex ico02 inview-el nth-child-4">
          <Link to="/faq/list" className="box">
            <div className="text-module">
              <p className="emp">자주묻는질문</p>
            </div>
          </Link>
        </div>
        <div className="flex ico03 inview-el nth-child-7">
          <a href="#" className="box" onClick={downloadLink}>
            <div className="txt-module">
              <p className="emp">1:1문의</p>
              <p className="desc">앱에서 작성 가능</p>
            </div>
          </a>
        </div>
      </div>

      <div className="notice">
        <h2 className="title">공지사항</h2>

        <div className="tb-wrap">
          <table>
            <caption>고객센터 고지사항에 대한 정보</caption>
            <colgroup>
              <col
                style={{
                  width: '108px'
                }}
              />
              <col />
              <col
                style={{
                  width: '188px'
                }}
              />
            </colgroup>
            <tbody>
              {noticeListData.noticeList.map((data) => {
                const list = (
                  <tr key={data.noticeSeq}>
                    <th scope="row" className="center">
                      <span className="badge">Notice</span>
                    </th>
                    <td
                      onClick={() => {
                        navigate('/notice/view/' + data.noticeSeq, {
                          state: {
                            pageIndex: '1',
                            searchValue: ''
                          }
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {data.noticeTit}
                    </td>
                    <td className="date center">{data.frstRdtt}</td>
                  </tr>
                );
                return list;
              })}
            </tbody>
          </table>
        </div>

        <div className="btn-group">
          <button
            type="button"
            className="btns line"
            onClick={() => {
              navigate('/notice/list');
            }}
          >
            공지사항 전체보기
          </button>
        </div>
      </div>
    </section>
  );
}
