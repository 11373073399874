// prettier-ignore
export const bizCodes  = {
  MA: 'MA', //메인
  CW: 'CW', //세차클링
  SV: 'SV', //서비스
  PS: 'PS', //파트너쉽
  CS: 'CS', //고객센터
  TC: 'TC',
  PO: 'PO',
  '#': '#', //없음(사용자정의)
} as const;

export type GngGhpBizCode = keyof typeof bizCodes;
