/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_PO_ER_01.tsx
 * @desc  Full Type 에러 1
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { webContentTitleInitUi } from '../../utils/web_common_ui';
export default function GngCwhSCR_H_CW_PC_PO_ER_01() {
  const navigate = useNavigate();
  useEffect(() => {
    webContentTitleInitUi();
  }, []);
  return (
    <>
      <section className="title-area cs js-inview">
        <h1 className="title inview-el nth-child-1">
          죄송합니다. <br />
          해당 페이지를 찾을 수 없습니다.
        </h1>
        <p className="desc inview-el nth-child-2">
          스마트한 세차라이프, 세차클링
        </p>
      </section>

      <div className="content-layout">
        <div className="btn-primary">
          {/* <button type="button" className="btns line lg">
            이전화면
          </button> */}
          <button
            type="button"
            className="btns lg"
            onClick={() => {
              navigate('/index');
            }}
          >
            메인화면
          </button>
        </div>
      </div>
    </>
  );
}
