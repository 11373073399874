import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Menu } from '../../../utils/getPage';
import { webContentTitleInitUi } from '../../../utils/web_common_ui';

// 상태 인터페이스 정의
export interface ContentLayoutState {
  title: string;
  currentUrl: string;
  children: Menu[];
  contentClass: string;
}

// 초기 상태 정의
const initialState: ContentLayoutState = {
  title: '',
  currentUrl: '',
  children: [],
  contentClass: ''
};

// 슬라이스 생성
export const contentLayoutSlice = createSlice({
  name: 'contentLayout',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ContentLayoutState>) => {
      const payload = action.payload;

      // 페이로드가 유효한 경우 상태 업데이트
      if (payload) {
        state.title = payload.title;
        state.children = payload.children;

        // 현재 메뉴 항목의 인덱스 찾기
        const currentIndex = _.findIndex(payload.children, (menu: Menu) => {
          const url = menu.url.startsWith('/') ? menu.url : `/${menu.url}`;
          return url === location.pathname;
        });

        // 현재 URL 업데이트
        state.currentUrl = payload.children[currentIndex].url;

        // UI 초기화
        webContentTitleInitUi();
      }
    }
  }
});

// 액션 내보내기
export const { setData } = contentLayoutSlice.actions;

// 리듀서 내보내기
export default contentLayoutSlice.reducer;
