/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_PS_01.tsx
 * @desc 제휴안내
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router';
export default function GngCwhSCR_H_CW_PC_PS_01() {
  const navigate = useNavigate();
  return (
    <>
      <div className="js-inview mx-guide flex-desc">
        <p className="desc xxl inview-el nth-child-7">제휴 영역</p>
        <p className="desc-sub inview-el nth-child-8">
          세차클링의 새로운 사업 파트너로서 귀하의 열린 제안을 기다립니다
        </p>
      </div>

      <div className="content-layout aff-introduction">
        <div className="grid-moudle common-moudle">
          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="/static/web/images/contents/img_ps_att01.png" alt="" />
            </div>

            <div className="flex cont inview-el">
              <p className="tit">제휴</p>
              <p className="desc">세차클링 제휴 및 입점 문의</p>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">마케팅</p>
              <p className="desc">온·오프라인 채널의 다양한 마케팅 제휴</p>
            </div>

            <div className="flex inview-el">
              <img src="/static/web/images/contents/img_ps_att02.png" alt="" />
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="/static/web/images/contents/img_ps_att03.png" alt="" />
            </div>

            <div className="flex cont inview-el">
              <p className="tit">법인영업</p>
              <p className="desc">직원 복지를 위한 합리적 가격의 법인 제휴</p>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">G car</p>
              <p className="desc">
                국내 최초 카셰어링 서비스 G car 서비스 제휴
              </p>
            </div>

            <div className="flex inview-el">
              <img src="/static/web/images/contents/img_ps_att04.png" alt="" />
            </div>
          </div>
        </div>

        <div className="step-module js-inview">
          <h2 className="title sm inview-el">제휴 신청 방법</h2>

          <div className="step inview-el">
            <div className="item">
              <strong className="num">01</strong>
              <p className="tit">제휴신청</p>
              <p className="desc">
                세차클링과 제휴할 내용을 등록합니다. 담당자 연락처를 꼭 함께
                기재해주세요
              </p>
            </div>
            <div className="item">
              <strong className="num">02</strong>
              <p className="tit">검토</p>
              <p className="desc">
                담당 부서에서 등록하신 제휴내용을 검토합니다
              </p>
            </div>
            <div className="item">
              <strong className="num">03</strong>
              <p className="tit">검토결과 회신</p>
              <p className="desc">
                담당자가 검토 결과 제휴를 진행할지 여부를 회신 드립니다
              </p>
            </div>
            <div className="item">
              <strong className="num">04</strong>
              <p className="tit">제휴 진행</p>
              <p className="desc">
                세부 조건과 일정을 조율해 제휴를 진행합니다
              </p>
            </div>
          </div>
        </div>

        <div className="btn-primary">
          <button
            type="button"
            className="btns lg"
            onClick={() => {
              navigate('/alliance/input');
            }}
          >
            세차클링 제휴 신청
          </button>
        </div>
      </div>
    </>
  );
}
