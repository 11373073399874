/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_TC_01.tsx
 * @desc 회원이용약관
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  stplatListDefaultProps,
  selectStplatListApi,
  stplatDefaultProps,
  selectStplatHistApi,
  stplatHistDefaultProps
} from './../../api/ci/csCenter';
import _ from 'lodash';
import { Children } from 'react';

export default function GngCwhSCR_H_CW_MO_TC_02() {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultListProps: stplatListDefaultProps = {
    stplatList: []
  };
  const [stplatListData, setStplatListData] = useState(defaultListProps);

  const defaultStplatProps: stplatDefaultProps = {
    stplatItem: []
  };
  const [stplatItemData, setStplatItemData] = useState(defaultStplatProps);

  const defaultHistProps: stplatHistDefaultProps = {
    stplatHist: []
  };
  const [stplatHistData, setStplatHistData] = useState(defaultHistProps);

  useEffect(() => {
    document.getElementsByClassName('title')[0].innerHTML = '이용약관';
    selectStplatListApi().then((res: any) => {
      setStplatListData((prevState: any) => ({
        ...prevState,
        stplatList: [...res.data]
      }));

      let cnt = '';
      for (let i = 0; i < res.data[0].stplats.length; i++) {
        res.data[0].stplats[i].isActive = false;
        if (res.data[0].stplats[i].stplatNo == '211068') {
          cnt = i + '';
        }
      }
      res.data[0].stplats[cnt].isActive = true;
      document.getElementsByClassName('terms-text')[0].innerHTML =
        res.data[0].stplats[cnt].stplatCtnt;

      selectStplatHistApi('211068').then((res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].enfcDttm = res.data[i].enfcDttm.substring(0, 10);
        }
        setStplatHistData((prevState: any) => ({
          ...prevState,
          stplatHist: [...res.data]
        }));
      });

      let data: any = [];
      for (let i = 0; i < res.data[0].stplats.length; i++) {
        if (res.data[0].stplats[i].stplatNo == '211066') {
          data.push(res.data[0].stplats[i]);
        }
        if (res.data[0].stplats[i].stplatNo == '211068') {
          data.push(res.data[0].stplats[i]);
        }
        if (res.data[0].stplats[i].stplatNo == '211069') {
          data.push(res.data[0].stplats[i]);
        }
      }
      setStplatItemData((prevState: any) => ({
        ...prevState,
        stplatItem: [...data]
      }));
    });
  }, []);

  const itemToggle = (code: any) => {
    const itemIndex = _.findIndex(stplatItemData.stplatItem, (item) => {
      return item.stplatNo == code;
    });

    for (let i = 0; i < stplatItemData.stplatItem.length; i++) {
      stplatItemData.stplatItem[i].isActive = false;
    }
    stplatItemData.stplatItem[itemIndex].isActive = true;
    document.getElementsByClassName('terms-text')[0].innerHTML =
      stplatItemData.stplatItem[itemIndex].stplatCtnt;

    selectStplatHistApi(stplatItemData.stplatItem[itemIndex].stplatNo).then(
      (res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].enfcDttm = res.data[i].enfcDttm.substring(0, 10);
        }
        setStplatHistData((prevState: any) => ({
          ...prevState,
          stplatHist: [...res.data]
        }));
      }
    );

    setStplatItemData((prevState: any) => ({
      ...prevState,
      stplatItem: [...stplatItemData.stplatItem]
    }));
  };

  return (
    <>
      <div className="tab scroll mt20">
        <ul>
          {stplatItemData.stplatItem.map((item) => {
            const list = (
              <li
                key={item.stplatNo}
                className={item.isActive == true ? 'item active' : 'item'}
                onClick={() => {
                  itemToggle(item.stplatNo);
                }}
              >
                <a href="#">{item.stplatNm}</a>
              </li>
            );
            return list;
          })}
        </ul>
        <span className="tab-indicator"></span>
      </div>
      <div className="contents">
        <div className="cl clause">
          <div className="flex">
            <div className="form-label mr8">개정이력</div>
            <div className="form-select">
              <select
                id="termsSelect"
                title="개인정보 처리방침 개정이력 선택 및 이동"
                onChange={(e: any) => {
                  console.log(e.target.value);

                  for (let i = 0; i < stplatHistData.stplatHist.length; i++) {
                    if (
                      stplatHistData.stplatHist[i].stplatSeq == e.target.value
                    ) {
                      document.getElementsByClassName(
                        'terms-text'
                      )[0].innerHTML = stplatHistData.stplatHist[i].stplatCtnt;
                    }
                  }
                }}
              >
                {stplatHistData.stplatHist.map((item) => {
                  const list = (
                    <option key={item.stplatSeq} value={item.stplatSeq}>
                      {item.enfcDttm} 개정
                    </option>
                  );
                  return list;
                })}
              </select>
              <span className="open-arrow"></span>
            </div>
          </div>
          <div id="20221031" className="selected-cont">
            <div id="termsText" className="term-wrap terms-text"></div>
          </div>
          <div id="" className="selected-cont"></div>
          <div id="" className="selected-cont"></div>
        </div>
      </div>
    </>
  );
}
