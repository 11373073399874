/**
 * <pre>
 * @title httpErrorHandler.ts
 * @desc HTTP 통신 예외 처리
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.19 21:28:29
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.19.  김완수  최초작성(gma-api 예외 처리 추가)
 */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Console } from 'console';
// import i18n from '../../../locales';
import { GmaResponseType } from '../vo/greenApiDefaultVo';

// const t = i18n.t;

export function gmaHttpErrorHandler<T = any>(err: AxiosError<T>): never {
  console.log(err);
  if (axios.isCancel(err)) {
    const message = `Request canceled : ${err.message}`;
    throw new Error(message);
  } else if (err.response) {
    const res = err.response as any;
    const message =
      res.data.errMsg ||
      res.data.msg ||
      res.data.message ||
      res.data.errorMessage ||
      'Sorry.\nA network error has occurred.\nPlease contact your administrator.';
    throw new Error(message);
  }
  throw new Error('Communication with the server failed.');
}

export function gmaResponseErrorHandler<T>(
  res: AxiosResponse<GmaResponseType<T>>
): Promise<AxiosResponse<GmaResponseType<T>>> {
  return new Promise((resolve, reject) => {
    if (res.data.errCode !== 0) {
      return reject({
        response: res
      });
    }
    return resolve(res);
  });
}

export type ResponseErrorHandler<T> = (
  res: AxiosResponse<T>
) => Promise<AxiosResponse<T>>;
