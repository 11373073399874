/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_CW_02.tsx
 * @desc 상품소개
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_PC_CW_02() {
  return (
    <>
      <div className="top-bn">
        <div className="js-inview mx-guide">
          <p className="tit xxl inview-el nth-child-7">
            고객님들의 다양한 니즈에 맞춘 <br />
            세차클링 서비스!
          </p>
        </div>
      </div>
      <div className="content-layout aff-introduction">
        <div className="grid-moudle">
          <div className="grid js-inview">
            <div className="flex inview-el">
              <img
                src="static/web/images/contents/clean_product_01.png"
                alt=""
              />
            </div>
            <div className="flex cont inview-el">
              <p className="tit">1회 세차</p>
              <p className="desc">이런 분께 추천드려요!</p>
              <ol className="bullet a">
                <li>
                  차량 관리에 시간이 부족하여 주유 할 때만 기계식 세차를
                  이용하시는
                  <br />
                  차주분들!
                </li>
                <li>주로 주말에 여가용으로 차량을 이용하시는 차주분들</li>
              </ol>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">2회 세차</p>

              <p className="desc">이런 분께 추천드려요!</p>
              <ol className="bullet a">
                <li>
                  차량 관리에 시간이 부족하지만 주유 할 필요가 없어 기계식
                  세차를 이용 <br />
                  못하시는 전기차 차주 분들
                </li>
                <li>출퇴근용으로 차량을 이용하시는 차주분들</li>
              </ol>
            </div>
            <div className="flex inview-el">
              <img
                src="static/web/images/contents/clean_product_02.png"
                alt=""
              />
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex inview-el">
              <img
                src="static/web/images/contents/clean_product_03.png"
                alt=""
              />
            </div>
            <div className="flex cont inview-el bg2-trigger">
              <p className="tit">4회 세차</p>
              <p className="desc">이런 분께 추천드려요!</p>
              <ol className="bullet a">
                <li>차를 아끼시지만 시간이 부족한 차주분들!</li>
                <li>
                  출퇴근 할 때도, 주말 여가용으로도 차량을 이용하시는 차주분들!
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
