import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Link, Outlet } from 'react-router-dom';
import _ from 'lodash';

import { pages, Page } from '../../../config/pages/pages';

const ContentLayout = () => {
  //console.log('ContentLayout :::::: ', location.pathname, pages[0].url);
  const contentLayoutState = useSelector(
    (state: RootState) => state.contentLayout
  );

  useEffect(() => {
    //webHamburgerMenuInitUi();
  }, [contentLayoutState]);

  return (
    <div id="wrap">
      <>
        {location.pathname === '/pageList' ? (
          <></>
        ) : (
          <Header headerType="sub" />
        )}
      </>
      <main className="container">
        <div className="contents error">
          <Outlet />
        </div>
      </main>
      <>{location.pathname === '/pageList' ? <></> : <Footer />}</>

      <article className="modal a sm" id="modal_1">
        <div className="modal-dialog not-close">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="ptit"></h1>
            </div>
            <div className="modal-body center">
              <p>준비중입니다</p>
            </div>
            <div className="modal-footer">
              <div className="btn-set b2">
                {/* <button type="button" className="btn close-pop" data-id="modal_1">취소</button> */}
                <button
                  type="button"
                  className="btn close-pop dark b rd lg"
                  data-id="modal_1"
                >
                  확인
                </button>
              </div>
            </div>
            <button type="button" className="btn-pop-close">
              <i className="blind">닫기</i>
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default React.memo(ContentLayout);
