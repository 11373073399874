import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const [clz, setClz] = useState('');

  const onClickFamilySite = () => {
    setClz(clz == 'active' ? '' : 'active');
  };

  return (
    <>
      <div className="ui-top">
        <a href="#" className="btn-top">
          <span className="hide-txt">페이지 상단으로 이동</span>
        </a>
      </div>

      <footer id="footer">
        <div className="foot-cont">
          <div className="corp-module">
            <div className="ci">
              <img src="/static/mob/images/common/logo_ci.svg" alt="세차클링" />
            </div>

            <p className="corp-info">
              (주) 그린카/서울특별시 강남구 테헤란로 70길 16(대치동)/TEL
              02-528-7741
              <br />
              사업자등록번호 220-87-91595/통신판매업신고번호
              제2011-서울강남-01456호
            </p>
            <div className="corp-list">
              <a
                href="https://www.greencar.co.kr/m/service/company"
                className="in"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                회사소개
              </a>
              <a
                href="#"
                className="pop_open"
                data-id="modal_1"
                onClick={() => {
                  new window.UiPopup('.modal').bindEvent();
                }}
              >
                인재채용
              </a>
              <Link to="/m/clausetw" style={{ color: '#42c0c0' }}>
                개인정보 처리방침
              </Link>
              <Link to="/m/clause">회원이용약관</Link>
              <Link to="/m/clauseth">위치기반서비스 이용약관</Link>
            </div>
            {/* <div className="sns-module">
              <p className="txt">공식SNS채널</p>
              <a
                href="https://www.instagram.com/greencar_kr/"
                className="in"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <span className="hide-txt">인스타그램</span>
              </a>
              <a
                href="http://blog.naver.com/greencar_co"
                className="nb"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <span className="hide-txt">네이버블로그</span>
              </a>
              <a
                href="https://www.facebook.com/greencarkorea"
                className="fb"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <span className="hide-txt">페이스북</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCG0WGiX5ZC9JxjSZIf5GZDQ"
                className="yt"
                target="_blank"
                title="새창 열기"
                rel="noopener noreferrer"
              >
                <span className="hide-txt">유투브</span>
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
