/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CW_02.tsx
 * @desc 상품소개
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_MO_CW_02() {
  return (
    <>
      <div className="contents p0 service">
        <div className="gray-full-area js-inview prl20 pro-img">
          <div className="gray-full-area-txt inview-el">
            <h2 className="desc-tit pt0 col-white">
              고객님들의 <br />
              다양한 니즈에 맞춘 <br />
              세차클링 서비스!
            </h2>
          </div>
        </div>

        <section className="section-wrap bg-parallax se-product-sec mt40">
          <div className="img-item-wrap">
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/clean_product_01.png"
                className="inview-el"
              />
              <div className="desc inview-el pl0 desc-new01">
                <div className="img ">
                  <img src="/static/mob/images/icon/icon_pr_wash01.svg" />
                </div>
                <div className="text">
                  <strong>1회 세차</strong>
                  <p className="mt2">이런 분께 추천드려요!</p>
                </div>
              </div>
              <ul className="cicle-num-wrap">
                <li>
                  <span className="cicle-num">1</span>
                  <span className="cicle-con">
                    차량 관리에 시간이 부족하여 주유 할 때만 기계식 세차를
                    이용하시는 차주분들!
                  </span>
                </li>
                <li>
                  <span className="cicle-num">2</span>
                  <span className="cicle-con">
                    주로 주말에 여가용으로 차량을 이용하시는 차주분들
                  </span>
                </li>
              </ul>
            </div>

            <div className="item bg-gray js-ani">
              <img
                src="/static/mob/images/contents/clean_product_02.png"
                className="inview-el"
              />
              <div className="desc inview-el pl0 desc-new01">
                <div className="img">
                  <img src="/static/mob/images/icon/icon_pr_wash02.svg" />
                </div>
                <div className="text">
                  <strong>2회 세차</strong>
                  <p className="mt2">이런 분께 추천드려요!</p>
                </div>
              </div>
              <ul className="cicle-num-wrap">
                <li>
                  <span className="cicle-num">1</span>
                  <span className="cicle-con">
                    차량 관리에 시간이 부족하지만 주유 할 필요가 없어 기계식
                    세차를 이용 못하시는 전기차 차주 분들
                  </span>
                </li>
                <li>
                  <span className="cicle-num">2</span>
                  <span className="cicle-con">
                    출퇴근용으로 차량을 이용하시는 차주분들
                  </span>
                </li>
              </ul>
            </div>

            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/clean_product_03.png"
                className="inview-el"
              />
              <div className="desc inview-el pl0 desc-new01">
                <div className="img">
                  <img src="/static/mob/images/icon/icon_pr_wash03.svg" />
                </div>
                <div className="text">
                  <strong>4회 세차</strong>
                  <p className="mt2">이런 분께 추천드려요!</p>
                </div>
              </div>
              <ul className="cicle-num-wrap">
                <li>
                  <span className="cicle-num">1</span>
                  <span className="cicle-con">
                    차를 아끼시지만 시간이 부족한 차주분들!
                  </span>
                </li>
                <li>
                  <span className="cicle-num">2</span>
                  <span className="cicle-con">
                    출퇴근 할 때도, 주말 여가용으로도 차량을 이용하시는
                    차주분들!
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
