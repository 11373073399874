/**
 * <pre>
 * @title useScrollTop.ts
 * @desc router시 최상단 스크롤
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 윤예지
 * @since 2022.08.30 14:49:50
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.30.  윤예지  최초작성
 *  2022.09.29.  김완수  custom hook으로 변경
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
