import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Link, Outlet } from 'react-router-dom';
import _ from 'lodash';
import { pages, Page } from '../../../config/pages/pages';
import { registUI } from '../../../utils/web_common_ui';

const MobileContentLayout = () => {
  const pageInfo = _.find(pages, (page: Page) => {
    const pathname = location.pathname.startsWith('/')
      ? location.pathname
      : `/${location.pathname}`;
    const pageurl = page.url.startsWith('/') ? page.url : `/${page.url}`;

    return pathname.indexOf(pageurl) >= 0;
  });

  const contentLayoutState = useSelector(
    (state: RootState) => state.contentLayout
  );

  useEffect(() => {
    //webHamburgerMenuInitUi();
  }, [contentLayoutState]);

  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.uiTop)) {
        registUI('.ui-top', window.uiTop);
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);

    const element: HTMLElement = document.getElementsByClassName(
      'btn-top'
    )[0] as HTMLElement;

    element.click();
  }, []);
  return (
    <div id="wrap">
      <div
        className="pop_open alertPopup"
        data-id="modal_2"
        onClick={() => {
          new window.UiPopup('.modal').bindEvent();
        }}
        style={{ display: 'none' }}
      ></div>
      <>
        {_.map(contentLayoutState.children, (menu: any, index: number) => {
          if (menu.url === contentLayoutState.currentUrl) {
            const list = (
              <Header
                key={`${menu.subTitle}`}
                headerType="sub"
                pageTitle={`${menu.subTitle}`}
              ></Header>
            );

            return list;
          }
        })}
      </>
      <main className="container">
        <div className="tab scroll mt20">
          <ul>
            {_.map(contentLayoutState.children, (menu: any, index: number) => {
              return (
                <li
                  className={`item ${
                    `${menu.url}` === contentLayoutState.currentUrl
                      ? 'active'
                      : ''
                  }`}
                  key={index}
                >
                  {menu.link === 'Y' ? (
                    <a
                      href={menu.url}
                      target="_blank"
                      title="새창 열기"
                      rel="noopener noreferrer"
                    >
                      {menu.subTitle}
                      <i className="ico arrow-rt xs">새창</i>
                    </a>
                  ) : (
                    <Link to={menu.url}>{menu.subTitle}</Link>
                  )}
                </li>
              );
            })}
          </ul>
          <span className="tab-indicator"></span>
        </div>
        {/* <div className={`contents `}> */}

        <Outlet />
        {/* </div> */}
      </main>
      <Footer />

      <article className="modal c" id="modal_1">
        <div className="modal-dialog">
          <div className="">
            <h1 className="ptit"></h1>
          </div>
          <div className="modal-content">
            <div className="modal-body alert center">준비중입니다.</div>
          </div>
          <div className="modal-footer">
            <div className="btn-set">
              <button type="button" className="btn btn-pop-close dark">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>

      <article className="modal c" id="modal_2">
        <div className="modal-dialog">
          <div className="">
            <h1 className="ptit"></h1>
          </div>
          <div className="modal-content">
            <div className="modal-body alert center alertContent"></div>
          </div>
          <div className="modal-footer">
            <div className="btn-set">
              <button type="button" className="btn btn-pop-close dark">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default React.memo(MobileContentLayout);
