/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_SV_02.tsx
 * @desc 클링패스
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_PC_SV_02() {
  return (
    <>
      <div className="js-inview mx-guide flex-desc">
        <p className="desc xxl inview-el nth-child-7">
          구독으로 시작하는 새로운 세차,
          <br />
          클링패스
        </p>
      </div>
      <div className="greencar__top_bn js-inview">
        <div className="mx-guide">
          <div className="greencar__top_bn_txt inview-el nth-child-7">
            <h2>클링패스 서비스란?</h2>
            <p className="desc">
              클링패스 방문 세차는 월 구독형 세차 패키지로 정기적으로
              <br />
              테크니션이 원하는 곳에 방문하여 세차를 진행합니다.
            </p>
          </div>
        </div>
      </div>
      <div className="content-layout">
        <div className="grid-moudle common-moudle">
          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="static/web/images/contents/pass_img_01.png" alt="" />
            </div>
            <div className="flex cont inview-el">
              <p className="tit">
                차량 케어에 대한
                <br />
                바른 변화
              </p>
              <p className="desc">
                세차클링은 차량케어에 관한 생각을 바꾸고
                <br />
                삶을 새롭게 혁신하기 위해 존재합니다.
              </p>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">
                차량 케어를 위한
                <br />
                바른 행동
              </p>
              <p className="desc">
                세차클링은 차량의 컨디션에 맞추어
                <br />
                전문 테크니션을 운영합니다.
              </p>
            </div>
            <div className="flex inview-el">
              <img src="static/web/images/contents/pass_img_02.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="greencar__footer_info js-inview">
        <div className="mx-guide inview-el">
          <div className="tit">
            <h2>
              클링패스 <em>상품 구성</em>
            </h2>
          </div>

          <div className="vs-box inview-el">
            <ul>
              <li>
                <div>
                  <h3>이용권</h3>
                  <div className="cont">
                    <div className="coupon-module wash x2">
                      <i>세차쿠폰 2장</i>
                    </div>
                    {/* <span className="desc">세차 쿠폰 2장</span> */}
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <h3>할인권</h3>
                  <div className="cont">
                    <div className="coupon-module p50">
                      <i>50% 할인권</i>
                    </div>
                    {/* <span className="desc">50% 할인권</span> */}
                    <div className="coupon-module p30">
                      <i>30% 할인권</i>
                    </div>
                    {/* <span className="desc">30% 할인권</span> */}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
