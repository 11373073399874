/**
 * <pre>
 * @title screen.ts
 * @desc screen 클래스
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.09.29 10:53:32
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  김완수  최초작성
 */
import _ from 'lodash';
import { GngGhpBizCode, bizCodes } from './bizCode';

export const SCREEN_ID_PREFIX = 'SCR_H_CW_';

export type GngGhpScreenType =
  | 'Main'
  | 'Contents'
  | 'ContentEmpty'
  | 'Empty'
  | 'M_Main'
  | 'M_Contents'
  | 'M_ContentsEmpty'
  | 'M_Empty';

export type GngGhpScreen = {
  readonly id: string;
  readonly bizCode: GngGhpBizCode;
  readonly type: GngGhpScreenType;
  readonly name?: string;
};

export type GngGhpScreens = {
  // readonly [key: string]: Screen;
  [key: string]: GngGhpScreen;
};

export function makeScreenID(
  deviceType: string,
  bizCode: GngGhpBizCode,
  suffix: string
) {
  const u = (str: string) => str.toUpperCase();
  return `${u(SCREEN_ID_PREFIX)}${u(deviceType)}_${u(bizCode)}_${suffix}`;
}

export function isValidScreenId(screenId: string) {
  return !screenId.includes(SCREEN_ID_PREFIX);
}

export function getBizCode(screenId: string): GngGhpBizCode | undefined {
  if (!isValidScreenId(screenId)) {
    console.log('invalid screen id');
    return undefined;
  }
  const bizKey = screenId
    .replace(SCREEN_ID_PREFIX, '')
    .split('_')[0]
    .toUpperCase();

  if (_.includes(_.keys(bizCodes), bizKey)) {
    return bizCodes[bizKey as GngGhpBizCode];
  }
  return undefined;
}

export function toBizCode(code: string) {
  const bizKey = code.toUpperCase();
  if (_.has(bizCodes, bizKey)) {
    return bizCodes[bizKey as GngGhpBizCode];
  }
  return bizCodes['#'];
}

export function createScreen(
  deviceType: string,
  bizCode: GngGhpBizCode,
  suffix: string,
  type: GngGhpScreenType,
  name?: string
): GngGhpScreen {
  return {
    id: makeScreenID(deviceType, bizCode, suffix),
    type,
    bizCode,
    name
  };
}
