/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CS_02.tsx
 * @desc 자주묻는질문 리스트
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  faqCtgyDefaultProps,
  selectFaqCtgyApi,
  faqListDefaultProps,
  selectFaqListApi
} from './../../api/ci/csCenter';
import _, { size } from 'lodash';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';

export default function GngCwhSCR_H_CW_MO_CS_02() {
  const searchRef = useRef<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const [text, setText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchFlag, setSearchFlag] = useState('');

  const [pageIndex, setPageIndex] = useState('1');
  const [pageGroup, setPageGroup] = useState('1');
  const [totalCount, setTotalCount] = useState('');
  const [pageCount, setPageCount] = useState('1');

  const [colorCtnt, setColorCtnt] = useState('');

  const defaultProps: faqCtgyDefaultProps = {
    faqCtgyList: []
  };
  const [faqCtgyData, setFaqCtgyData] = useState(defaultProps);

  const defaultListProps: faqListDefaultProps = {
    faqList: []
  };
  const [faqListData, setFaqListData] = useState(defaultListProps);

  const state = location.state as {
    searchCode: string;
    searchValue: string;
  };
  let searchCode = '';
  let searchValue = '';
  if (state) {
    searchCode = state.searchCode;
    searchValue = state.searchValue;
  }

  // useEffect(() => {
  //   console.log(pageCount);
  // }, [pageCount]);

  useEffect(() => {
    setText(searchValue);
    if (searchValue != '') {
      let element: HTMLElement = document.getElementsByClassName(
        'searchBtn'
      )[0] as HTMLElement;

      element.click();
    }

    selectFaqCtgyApi().then((res: any) => {
      for (let i = 0; i < res.data.commCodeList.length; i++) {
        if (res.data.commCodeList[i].dtlCd == searchCode) {
          res.data.commCodeList[i].isActive = true;
        } else {
          document.getElementsByClassName('ctgyAll')[0].className =
            'ctgyAll item';
          res.data.commCodeList[i].isActive = false;
        }
      }
      if (searchCode == '') {
        document.getElementsByClassName('ctgyAll')[0].className =
          'ctgyAll item active';
      }
      setFaqCtgyData((prevState: any) => ({
        ...prevState,
        faqCtgyList: [...res.data.commCodeList]
      }));
    });

    selectFaqListApi(pageIndex, '10', searchCode, searchValue).then(
      (res: any) => {
        setFaqListData((prevState: any) => ({
          ...prevState,
          faqList: [...res.data.faqList]
        }));
      }
    );
    setPagination(pageIndex, searchCode, searchValue);

    (() => {
      window.addEventListener('beforeunload', (e: any) => {
        history.replaceState({}, location.pathname);
        e.preventDefault();
        e.returnValue = '';
      });
    })();
    /*
    let initHeaderInterval: any;
    const initAcco = () => {
      if (_.isFunction(window.accordion)) {
        window.accordion();
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initAcco, 100);
    */
  }, []);

  // 검색어 입력, 변경
  const onChange = (e: any) => {
    console.log('1');
    // setSearchFlag('');
    setSearchText(e.target.value);
    // if (e.target.value === '') {
    //   clearSearchValBtn();
    //   selectFaqListApi(pageIndex, '', '', '').then((res: any) => {
    //     setFaqListData((prevState: any) => ({
    //       ...prevState,
    //       faqList: [...res.data.faqList]
    //     }));

    //     setPagination(pageIndex, '', '');
    //   });
    // }
  };

  // 검색어 초기화
  const clearSearchValBtn = () => {
    searchRef.current.value = '';
    setText('');
    setSearchFlag('');
    setPageIndex('1');

    for (let i = 0; i < faqCtgyData.faqCtgyList.length; i++) {
      faqCtgyData.faqCtgyList[i].isActive = false;
    }

    selectFaqListApi('1', '10', '', '').then((res: any) => {
      setFaqListData((prevState: any) => ({
        ...prevState,
        faqList: [...res.data.faqList]
      }));

      document.getElementsByClassName('ctgyAll')[0].className =
        'ctgyAll item active';

      setPagination(pageIndex, '', '');
    });
  };

  // 리스트 카테고리 토글
  const faqCtgyToggle = (dtlCd: any) => {
    setPageIndex('1');
    const itemIndex = _.findIndex(faqCtgyData.faqCtgyList, (faq) => {
      return faq.dtlCd == dtlCd;
    });

    for (let i = 0; i < faqCtgyData.faqCtgyList.length; i++) {
      if (i === itemIndex) {
        document.getElementsByClassName('ctgyAll')[0].className =
          'ctgyAll item';
        faqCtgyData.faqCtgyList[itemIndex].isActive = true;
      } else {
        if (dtlCd === '') {
          document.getElementsByClassName('ctgyAll')[0].className =
            'ctgyAll item active';
        }
        faqCtgyData.faqCtgyList[i].isActive = false;
      }
    }
    setFaqListData((prevState: any) => ({
      ...prevState,
      faqCtgyList: [...faqCtgyData.faqCtgyList]
    }));

    selectFaqListApi(pageIndex, '10', dtlCd, text).then((res: any) => {
      setFaqListData((prevState: any) => ({
        ...prevState,
        faqList: [...res.data.faqList]
      }));

      setPagination(pageIndex, dtlCd, text);
    });
  };

  // 검색어 입력 리스트 검색
  const searchFaq = () => {
    setPageIndex('1');

    if (searchRef.current.value != '') {
      setText(searchRef.current.value);
    }

    selectFaqListApi(pageIndex, '10', '', searchRef.current.value).then(
      (res: any) => {
        setFaqListData((prevState: any) => ({
          ...prevState,
          faqList: [...res.data.faqList]
        }));

        if (searchRef.current.value != '') {
          setSearchFlag('search');
        } else {
          setSearchFlag('');
        }

        setPagination(pageIndex, '', searchRef.current.value);
      }
    );
  };

  let pageCountArray: any = [];
  let pageArray: any = [];
  const pageCountData = {
    pageList: [
      {
        num: '1'
      }
    ]
  };
  const [pageData, setPageData] = useState(pageCountData);
  const setPagination = (page: any, code: any, value: any) => {
    selectFaqListApi('0', '0', code, value).then((res: any) => {
      let pc = '';
      if (Math.floor(parseInt(res.data.faqList.length) % 10) == 0) {
        pc = Math.floor(parseInt(res.data.faqList.length) / 10) + '';
      } else {
        pc = Math.floor(parseInt(res.data.faqList.length) / 10 + 1) + '';
      }
      setPageCount(pc);
      setPageCount(pc);

      let pagenumber = page;
      let lastnumber = page;
      lastnumber = parseInt(pagenumber) + 5 + '';
      for (let i = parseInt(pagenumber); i < parseInt(lastnumber); i++) {
        if (parseInt(pc) >= i) {
          const param = {
            num: i + ''
          };
          pageCountArray.push(param);
        }
      }

      setPageData((prevState: any) => ({
        ...prevState,
        pageList: pageCountArray
      }));
    });
  };
  const getList = (type: any, pageNum: any) => {
    selectFaqListApi(pageNum, '10', searchCode, searchValue).then(
      (res: any) => {
        setFaqListData((prevState: any) => ({
          ...prevState,
          faqList: [...res.data.faqList]
        }));

        if (pageNum != 1 && pageNum % 5 == 1) {
          if (type == 'next') {
            setPageGroup(parseInt(pageGroup) + 1 + '');
          }

          setPagination(pageNum, searchCode, searchValue);
        }
        if (pageNum % 5 == 0) {
          if (pageNum != 5) {
            setPageGroup(parseInt(pageNum) / 5 + '');
            setPagination(pageNum - 4, searchCode, searchValue);
          } else {
            setPageGroup('1');
            setPagination(1, searchCode, searchValue);
          }
        }
      }
    );
  };

  const onClickAccordion = (e: any) => {
    if ($(e.currentTarget).hasClass('active')) {
      $(e.currentTarget).removeClass('active').find('.answer').slideUp();
    } else {
      $('.accordion-list > li.active .answer').slideUp();
      $('.accordion-list > li.active').removeClass('active');
      $(e.currentTarget).addClass('active').find('.answer').slideDown();
    }
  };
  return (
    <>
      <div className="contents">
        <div className="cs">
          <div className="search-box">
            <input
              type="text"
              placeholder="검색어를 입력해주세요"
              onChange={onChange}
              ref={searchRef}
              // onFocus={() => {
              //   clearSearchValBtn();
              // }}
            />
            <button className="btn icon searchBtn" onClick={searchFaq}>
              <i className="ico search sm"></i>
            </button>
            {searchText != '' ? (
              <button
                type="button"
                className="clean-btn"
                onClick={clearSearchValBtn}
              >
                <i className="ico input-delete sm"></i>
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="scroll-x">
            <ul>
              {faqCtgyData.faqCtgyList.map((info) => {
                const list = (
                  <li
                    className="item"
                    key={info.dtlCd}
                    onClick={() => {
                      faqCtgyToggle(info.dtlCd);
                    }}
                  >
                    <a href="#">{info.dtlCdNm}</a>
                  </li>
                );
                return list;
              })}
            </ul>
          </div>
          <div className="tab type-2 scroll mt20">
            <ul>
              <li
                className="ctgyAll active"
                onClick={() => {
                  faqCtgyToggle('');
                }}
              >
                <a href="#">전체</a>
              </li>
              {faqCtgyData.faqCtgyList.map((info) => {
                const code = info.dtlCd;
                const list = (
                  <li
                    className={info.isActive == true ? 'active ' : ''}
                    key={info.dtlCd}
                    onClick={() => {
                      faqCtgyToggle(info.dtlCd);
                    }}
                  >
                    <a href="#">{info.dtlCdNm}</a>
                  </li>
                );
                return list;
              })}
            </ul>
          </div>
          <div className="accordion">
            {searchFlag != '' ? (
              <div className="accordion-list-head">
                <p>
                  <span className="emp">‘{text}’</span>검색 결과
                </p>
              </div>
            ) : (
              <></>
            )}
            <div
              className="accordion-list-body mt22"
              // {
              //   searchFlag === ''
              //     ? 'accordion-list-body mt22'
              //     : 'accordion-list-body mt32'
              // }
            >
              {faqListData.faqList.length === 0 ? (
                <>
                  {searchFlag === '' ? (
                    <></>
                  ) : (
                    <>
                      {text === null || text === '' ? (
                        <div className="no-data">
                          <p>등록된 자주 묻는 질문이 없습니다.</p>
                        </div>
                      ) : (
                        <div className="no-data">
                          <p>
                            검색 결과가 없습니다.
                            <br />
                            다른 검색어를 이용해주세요.
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <ul className="accordion-list">
                  {faqListData.faqList.map((data) => {
                    let coloredCode = data.faqTit.replaceAll(
                      text,
                      `<span className="emp" style="color: #f00;">${text}</span>`
                    );

                    let coloredCtnt = colorCtnt.replaceAll(
                      text,
                      `<span className="emp" style="color: #f00;">${text}</span>`
                    );

                    const list = (
                      <li key={data.faqSeq} onClick={onClickAccordion}>
                        {searchFlag != '' ? (
                          <>
                            {/* <h3
                              dangerouslySetInnerHTML={{
                                __html: coloredCode
                              }}
                            ></h3>
                            <div className="answer" style={{ display: 'none' }}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: coloredCtnt
                                }}
                              ></p>
                            </div> */}
                            <h3
                            // dangerouslySetInnerHTML={{
                            //   __html: coloredCode
                            // }}
                            ></h3>
                            <div className="answer" style={{ display: 'none' }}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.faqCtnt
                                }}
                              ></p>
                            </div>
                          </>
                        ) : (
                          <>
                            <h3>{data.faqTit}</h3>
                            <div className="answer" style={{ display: 'none' }}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.faqCtnt
                                }}
                              ></p>
                            </div>
                          </>
                        )}
                      </li>
                    );
                    return list;
                  })}
                </ul>
              )}
            </div>
          </div>
          {faqListData.faqList.length === 0 ? (
            <></>
          ) : (
            <ul className="pagination">
              {pageGroup === '1' ? (
                <></>
              ) : (
                <li className="previous">
                  <a
                    onClick={() => {
                      setPageIndex((parseInt(pageGroup) - 1) * 5 + '');
                      // console.log((parseInt(pageGroup) - 1) * 5 + '');
                      getList('prev', (parseInt(pageGroup) - 1) * 5);
                    }}
                  >
                    <i className="ico chevron-left xs"></i>
                  </a>
                </li>
              )}

              {pageData.pageList.map((data) => {
                const list = (
                  <li
                    key={data.num}
                    className={data.num === pageIndex ? 'active' : ''}
                  >
                    <a
                      onClick={() => {
                        setPageIndex(data.num);
                        getList('', data.num);
                      }}
                    >
                      {data.num}
                    </a>
                  </li>
                );
                return list;
              })}

              {Math.floor(parseInt(pageCount) / 5) + 1 ===
              Math.floor(parseInt(pageIndex) / 5) + 1 ? (
                <>
                  {Math.floor(parseInt(pageIndex) % 5) === 0 ? (
                    <li className="next">
                      <a
                        onClick={() => {
                          setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                          getList('next', parseInt(pageGroup) * 5 + 1);
                        }}
                      >
                        <i className="ico chevron-right xs"></i>
                      </a>
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <li className="next">
                  <a
                    onClick={() => {
                      setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                      getList('next', parseInt(pageGroup) * 5 + 1);
                    }}
                  >
                    <i className="ico chevron-right xs"></i>
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
