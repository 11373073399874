/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_PS_01.tsx
 * @desc 제휴안내
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router';
export default function GngCwhSCR_H_CW_MO_PS_01() {
  const navigate = useNavigate();
  return (
    <>
      <div className="contents">
        <div className="ps">
          <div className="section-wrap affiliate bg-parallax">
            <section>
              <div className="js-ani">
                <div className="">
                  <h3 className="section-title">제휴 영역</h3>
                  <p className="section-desc">
                    세차클링의 새로운 사업 파트너로서 귀하의 열린 제안을
                    기다립니다
                  </p>
                </div>
              </div>
              <div className="affiliate-area">
                <div className="item js-ani">
                  <img
                    src="/static/mob/images/contents/img_affiliate_01.png"
                    className=""
                  />
                  <div className="desc">
                    <div className="img">
                      <img src="/static/mob/images/icon/icon_affiliate_01.svg" />
                    </div>
                    <div className="text">
                      <strong>제휴</strong>
                      <p>세차클링 제휴 및 입점 문의</p>
                    </div>
                  </div>
                </div>
                <div className="item js-ani">
                  <img
                    src="/static/mob/images/contents/img_affiliate_02.png"
                    className=""
                  />
                  <div className="desc">
                    <div className="img">
                      <img src="/static/mob/images/icon/icon_affiliate_02.svg" />
                    </div>
                    <div className="text">
                      <strong>마케팅</strong>
                      <p>
                        온·오프라인 채널의
                        <br /> 다양한 마케팅 제휴
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item js-ani">
                  <img
                    src="/static/mob/images/contents/img_affiliate_03.png"
                    className=""
                  />
                  <div className="desc">
                    <div className="img">
                      <img src="/static/mob/images/icon/icon_affiliate_03.svg" />
                    </div>
                    <div className="text">
                      <strong>법인영업</strong>
                      <p>
                        직원 복지를 위한
                        <br />
                        합리적 가격의 법인 제휴
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item js-ani">
                  <img
                    src="/static/mob/images/contents/img_affiliate_04.png"
                    className=""
                  />
                  <div className="desc">
                    <div className="img">
                      <img src="/static/mob/images/icon/icon_affiliate_04.svg" />
                    </div>
                    <div className="text">
                      <strong>G car</strong>
                      <p>
                        국내 최초 카셰어링 서비스
                        <br />G car 서비스 제휴
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="">
              <h3 className="section-title js-ani">제휴 신청 방법</h3>
              <div className="apply-step ">
                <ol>
                  <li className="item js-ani">
                    <div className="num">01</div>
                    <div className="desc">
                      <strong>제휴신청</strong>
                      <p>
                        세차클링과 제휴할 내용을
                        <br />
                        등록합니다.
                        <br />
                        담당자 연락처를 꼭 함께
                        <br />
                        기재해주세요
                      </p>
                    </div>
                  </li>
                  <li className="arrow"></li>
                  <li className="item js-ani">
                    <div className="num">02</div>
                    <div className="desc">
                      <strong>검토</strong>
                      <p>
                        담당 부서에서
                        <br />
                        등록하신 제휴내용을
                        <br />
                        검토합니다
                      </p>
                    </div>
                  </li>
                  <li className="arrow"></li>
                  <li className="item js-ani">
                    <div className="num">03</div>
                    <div className="desc">
                      <strong>검토결과 회신</strong>
                      <p>
                        담당자가 검토 결과 제휴를
                        <br />
                        진행할지 여부를
                        <br />
                        회신 드립니다.
                      </p>
                    </div>
                  </li>
                  <li className="arrow"></li>
                  <li className="item js-ani">
                    <div className="num">04</div>
                    <div className="desc">
                      <strong>제휴 진행</strong>
                      <p>
                        세부 조건과 일정을
                        <br />
                        조율해 제휴를 <br />
                        진행합니다.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>
            </section>
          </div>
          <div className="btn-group mt40">
            <button
              className="btn md"
              type="button"
              onClick={() => {
                navigate('/m/alliance/input');
              }}
            >
              세차클링 제휴 신청
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
