/**
 * <pre>
 * @title getGreencarApi.ts
 * @desc 그린카 도메인 url
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.19 10:36:44
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.19.  김완수  최초작성
 */
import { config } from '../config';
import { BuildEnv } from './buildType';

/**
 * @todo hostname 체크(as-is 기준) 검토
 * window.location.hostname
 */
export function getGreencarApi() {
  const buildEnv = config.buildEnv;
  let api;
  if (buildEnv === BuildEnv.PRD) {
    api = config.api.greencarPrdApi;
  } else if (buildEnv === BuildEnv.STG) {
    api = config.api.greencarStgApi;
  } else {
    api = config.api.greencarDevApi;
  }
  return api || config.api.greencarApi;
}
