/**
 * <pre>
 * @title useOnInit.ts
 * @desc 앱 초기 설정 hook
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.09.28
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.28.  김완수  최초작성
 */
// import { useEffect } from 'react';
// import { default as locales } from '../locales';
// import { getPageBaseUrl } from './useChangeNativeHeader';
import useScrollTop from './useScrollTop';
// import { useTranslation } from 'react-i18next';
// import { config } from '../config';
// import _ from 'lodash';

export function useOnInit() {
  // @todo use locale module
  // const { t } = useTranslation();

  // useEffect(() => {
  // initConfigTitle(t);
  // locales.changeLanguage('ko');
  // }, []);

  useScrollTop();
}

export function FakeOnInitComponent() {
  useOnInit();
  return null;
}

// function initConfigTitle(t: any) {
//   _.forEach(config.pages, (val) => {
//     if (val.id === 'notFound') {
//       return;
//     }
//     val.title = t(`${getPageBaseUrl(val.url)}.title`);
//   });
// }
