/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_CS_02.tsx
 * @desc 자주묻는질문 리스트
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  faqCtgyDefaultProps,
  selectFaqCtgyApi,
  faqListDefaultProps,
  selectFaqListApi
} from './../../api/ci/csCenter';
import _, { size } from 'lodash';
import { useLocation } from 'react-router-dom';
import { setAlert } from '../../utils/web_common_ui';
//import { getPagination } from './../../utils/pagination';

export default function GngCwhSCR_H_CW_PC_CS_02() {
  const searchRef = useRef<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const [text, setText] = useState('');
  const [searchFlag, setSearchFlag] = useState('');

  const [pageIndex, setPageIndex] = useState('1');
  const [pageGroup, setPageGroup] = useState('1');
  const [totalCount, setTotalCount] = useState('');
  const [pageCount, setPageCount] = useState('1');

  const [colorCtnt, setColorCtnt] = useState('');

  const defaultProps: faqCtgyDefaultProps = {
    faqCtgyList: []
  };
  const [faqCtgyData, setFaqCtgyData] = useState(defaultProps);

  const defaultListProps: faqListDefaultProps = {
    faqList: []
  };
  const [faqListData, setFaqListData] = useState(defaultListProps);

  const state = location.state as {
    searchCode: string;
    searchValue: string;
  };
  let searchCode = '';
  let searchValue = '';
  if (state) {
    searchCode = state.searchCode;
    searchValue = state.searchValue;
  }

  useEffect(() => {
    setText(searchValue);
    if (searchValue != '') {
      let element: HTMLElement = document.getElementsByClassName(
        'searchBtn'
      )[0] as HTMLElement;

      element.click();
    }

    selectFaqCtgyApi().then((res: any) => {
      for (let i = 0; i < res.data.commCodeList.length; i++) {
        if (res.data.commCodeList[i].dtlCd == searchCode) {
          res.data.commCodeList[i].isActive = true;
        } else {
          document.getElementsByClassName('ctgyAll')[0].className =
            'ctgyAll item';
          res.data.commCodeList[i].isActive = false;
        }
      }
      if (searchCode == '') {
        document.getElementsByClassName('ctgyAll')[0].className =
          'ctgyAll item active';
      }
      setFaqCtgyData((prevState: any) => ({
        ...prevState,
        faqCtgyList: [...res.data.commCodeList]
      }));
    });

    selectFaqListApi(pageIndex, '10', searchCode, searchValue).then(
      (res: any) => {
        setFaqListData((prevState: any) => ({
          ...prevState,
          faqList: [...res.data.faqList]
        }));
      }
    );
    setPagination(pageIndex, searchCode, searchValue);

    (() => {
      window.addEventListener('beforeunload', (e: any) => {
        history.replaceState({}, location.pathname);
        e.preventDefault();
        e.returnValue = '';
      });
    })();
  }, []);

  // 검색어 입력, 변경
  const onChange = (e: any) => {
    // setSearchFlag('');
    // setPageIndex('1');

    // setText(e.target.value);

    console.log('1');
    // console.log(e.target.value);
    // if (e.target.value === '') {
    //   clearSearchValBtn();
    //   selectFaqListApi(pageIndex, '', '', '').then((res: any) => {
    //     setFaqListData((prevState: any) => ({
    //       ...prevState,
    //       faqList: [...res.data.faqList]
    //     }));

    //     setPagination(pageIndex, '', '');
    //   });
    // }
  };

  // 검색어 초기화
  const clearSearchValBtn = () => {
    searchRef.current.value = '';
    setText('');
    setSearchFlag('');
    setPageIndex('1');

    for (let i = 0; i < faqCtgyData.faqCtgyList.length; i++) {
      faqCtgyData.faqCtgyList[i].isActive = false;
    }

    selectFaqListApi('1', '10', '', '').then((res: any) => {
      setFaqListData((prevState: any) => ({
        ...prevState,
        faqList: [...res.data.faqList]
      }));

      document.getElementsByClassName('ctgyAll')[0].className =
        'ctgyAll item active';
      setPagination(pageIndex, '', '');
    });
  };

  // 리스트 카테고리 토글
  const faqCtgyToggle = (dtlCd: any) => {
    setPageIndex('1');
    const itemIndex = _.findIndex(faqCtgyData.faqCtgyList, (faq) => {
      return faq.dtlCd == dtlCd;
    });

    for (let i = 0; i < faqCtgyData.faqCtgyList.length; i++) {
      if (i === itemIndex) {
        document.getElementsByClassName('ctgyAll')[0].className =
          'ctgyAll item';
        faqCtgyData.faqCtgyList[itemIndex].isActive = true;
      } else {
        if (dtlCd === '') {
          document.getElementsByClassName('ctgyAll')[0].className =
            'ctgyAll item active';
        }
        faqCtgyData.faqCtgyList[i].isActive = false;
      }
    }
    setFaqListData((prevState: any) => ({
      ...prevState,
      faqCtgyList: [...faqCtgyData.faqCtgyList]
    }));

    selectFaqListApi(pageIndex, '10', dtlCd, text).then((res: any) => {
      setFaqListData((prevState: any) => ({
        ...prevState,
        faqList: [...res.data.faqList]
      }));

      setPagination(pageIndex, dtlCd, text);
    });
  };

  // 리스트 목록 토글
  const faqToggle = (faqSeq: any) => {
    const itemIndex = _.findIndex(faqListData.faqList, (faq) => {
      return faq.faqSeq == faqSeq;
    });

    for (let i = 0; i < faqListData.faqList.length; i++) {
      if (i === itemIndex) {
        if (faqListData.faqList[itemIndex].isActive == true) {
          faqListData.faqList[itemIndex].isActive = false;
        } else {
          faqListData.faqList[itemIndex].isActive = true;
          if (text) {
            document.getElementsByClassName('nttCtntDiv')[itemIndex].innerHTML =
              faqListData.faqList[itemIndex].faqCtnt.replaceAll(
                text,
                `<span style="color: #f00; font-weight: 600;">${text}</span>`
              );
          } else {
            document.getElementsByClassName('nttCtntDiv')[itemIndex].innerHTML =
              faqListData.faqList[itemIndex].faqCtnt;
          }
        }
      } else {
        faqListData.faqList[i].isActive = false;
      }
    }

    setFaqListData((prevState: any) => ({
      ...prevState,
      faqList: [...faqListData.faqList]
    }));
  };

  let alertPopup: HTMLElement = document.getElementsByClassName(
    'alertPopup'
  )[0] as HTMLElement;

  // 검색어 입력 리스트 검색
  const searchFaq = () => {
    setPageIndex('1');

    if (searchRef.current.value != '') {
      setText(searchRef.current.value);
    }

    selectFaqListApi(pageIndex, '10', '', searchRef.current.value).then(
      (res: any) => {
        setFaqListData((prevState: any) => ({
          ...prevState,
          faqList: [...res.data.faqList]
        }));

        if (searchRef.current.value != '') {
          setSearchFlag('search');
        } else {
          setSearchFlag('');
        }

        setPagination(pageIndex, '', searchRef.current.value);
      }
    );
  };

  let pageCountArray: any = [];
  let pageArray: any = [];
  const pageCountData = {
    pageList: [
      {
        num: '1'
      }
    ]
  };
  const [pageData, setPageData] = useState(pageCountData);
  const setPagination = (page: any, code: any, value: any) => {
    selectFaqListApi('0', '0', code, value).then((res: any) => {
      setTotalCount(res.data.faqList.length);
      let pc = '';
      if (Math.floor(parseInt(res.data.faqList.length) % 10) == 0) {
        pc = Math.floor(parseInt(res.data.faqList.length) / 10) + '';
      } else {
        pc = Math.floor(parseInt(res.data.faqList.length) / 10 + 1) + '';
      }
      setPageCount(pc);
      setPageCount(pc);

      let pagenumber = page;
      let lastnumber = page;
      lastnumber = parseInt(pagenumber) + 5 + '';
      for (let i = parseInt(pagenumber); i < parseInt(lastnumber); i++) {
        if (parseInt(pc) >= i) {
          const param = {
            num: i + ''
          };
          pageCountArray.push(param);
        }
      }

      setPageData((prevState: any) => ({
        ...prevState,
        pageList: pageCountArray
      }));
    });
  };
  const getList = (type: any, pageNum: any) => {
    selectFaqListApi(pageNum, '10', searchCode, searchValue).then(
      (res: any) => {
        setFaqListData((prevState: any) => ({
          ...prevState,
          faqList: [...res.data.faqList]
        }));

        if (pageNum != 1 && pageNum % 5 == 1) {
          if (type == 'next') {
            setPageGroup(parseInt(pageGroup) + 1 + '');
          }

          setPagination(pageNum, searchCode, searchValue);
        }
        if (pageNum % 5 == 0) {
          if (pageNum != 5) {
            setPageGroup(parseInt(pageNum) / 5 + '');
            setPagination(pageNum - 4, searchCode, searchValue);
          } else {
            setPageGroup('1');
            setPagination(1, searchCode, searchValue);
          }
        }
      }
    );
    //setPagination(searchCode, searchValue);
  };

  return (
    <>
      <section className="content-layout faq">
        <div className="search">
          <div className="search-module">
            <div className="search-group">
              <i className="ico search lg"></i>
              <input
                className="ip-txt"
                type="text"
                placeholder="궁금한 내용을 검색하세요"
                title="검색어 입력"
                onChange={onChange}
                ref={searchRef}
                // onFocus={() => {
                //   clearSearchValBtn();
                // }}
              />
              <button
                type="button"
                className="btns primary searchBtn"
                onClick={searchFaq}
              >
                검색
              </button>
              <button
                type="button"
                className="btns primary clearBtn"
                onClick={clearSearchValBtn}
                style={{
                  marginLeft: '5px'
                }}
              >
                초기화
              </button>
            </div>
          </div>

          <div className="link-module">
            {faqCtgyData.faqCtgyList.map((info) => {
              const list = (
                <a
                  key={info.dtlCd}
                  onClick={() => {
                    faqCtgyToggle(info.dtlCd);
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {info.dtlCdNm}
                </a>
              );
              return list;
            })}
          </div>
        </div>
        {searchFlag != '' ? (
          <div
            style={{
              marginTop: '20px',
              marginBottom: '-36px',
              fontSize: '18px'
            }}
          >
            <strong>&apos;{text}&apos;</strong> 검색 결과
          </div>
        ) : (
          <></>
        )}
        <div className="ui-tab-type02">
          <div className="module">
            <div
              className="ctgyAll item active"
              onClick={() => {
                faqCtgyToggle('');
              }}
            >
              <button className="btn">전체</button>
            </div>
            {faqCtgyData.faqCtgyList.map((info) => {
              const code = info.dtlCd;
              const list = (
                <div
                  className={info.isActive == true ? 'item active ' : 'item '}
                  key={info.dtlCd}
                  onClick={() => {
                    faqCtgyToggle(info.dtlCd);
                  }}
                >
                  <button type="button" className="btn">
                    {info.dtlCdNm}
                  </button>
                </div>
              );
              return list;
            })}
          </div>
        </div>

        <div className="faq-accdion">
          <div className="ui-accodion">
            {faqListData.faqList.length === 0 ? (
              <>
                {searchFlag === '' ? (
                  <></>
                ) : (
                  <>
                    {text === null || text === '' ? (
                      <div className="no-data">
                        <p className="desc">
                          등록된 자주 묻는 질문이 없습니다.
                        </p>
                      </div>
                    ) : (
                      <div className="no-data">
                        <p className="desc">
                          검색 결과가 없습니다.
                          <br />
                          다른 검색어를 이용해주세요.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {faqListData.faqList.map((data) => {
                  let coloredCode = '';
                  let coloredCtnt = '';

                  if (text) {
                    coloredCode = data.faqTit.replaceAll(
                      text,
                      `<span style="color: #f00; font-weight: 600;">${text}</span>`
                    );

                    coloredCtnt = data.faqCtnt.replaceAll(
                      text,
                      `<span style="color: #f00; font-weight: 600;">${text}</span>`
                    );
                  }

                  const list = (
                    <div
                      className={data.isActive == true ? 'item active' : 'item'}
                      key={data.faqSeq}
                      onClick={() => {
                        faqToggle(data.faqSeq);
                      }}
                    >
                      <button type="button" className="btn">
                        <span className="mark">Q</span>
                        {searchFlag != '' ? (
                          <>
                            <span
                              className="desc"
                              dangerouslySetInnerHTML={{ __html: coloredCode }}
                            ></span>
                          </>
                        ) : (
                          <>{data.faqTit}</>
                        )}
                        <i className="ico arrow md"></i>
                      </button>
                      <div className="layer">
                        <div className="cont">
                          {searchFlag != '' ? (
                            <>
                              <p
                                className="desc nttCtntDiv"
                                dangerouslySetInnerHTML={{
                                  __html: coloredCode
                                }}
                              ></p>
                            </>
                          ) : (
                            <>
                              <p
                                className="desc nttCtntDiv"
                                dangerouslySetInnerHTML={{
                                  __html: data.faqCtnt
                                }}
                              ></p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  return list;
                })}
              </>
            )}
          </div>
        </div>
      </section>

      {faqListData.faqList.length === 0 ? (
        <></>
      ) : (
        <div className="pagination">
          <div className="pagi-module">
            {pageGroup === '1' ? (
              <button className="btn-pagi" disabled>
                <i className="ico btn-prev"></i>
                PREV
              </button>
            ) : (
              <button
                className="btn-pagi"
                onClick={() => {
                  setPageIndex((parseInt(pageGroup) - 1) * 5 + '');
                  // console.log((parseInt(pageGroup) - 1) * 5 + '');
                  getList('prev', (parseInt(pageGroup) - 1) * 5);
                }}
              >
                <i className="ico btn-prev"></i>
                PREV
              </button>
            )}

            <div className="number pageNumber">
              {pageData.pageList.map((data) => {
                const list = (
                  <div key={data.num}>
                    <button
                      type="button"
                      className={data.num === pageIndex ? 'active' : ''}
                      onClick={() => {
                        setPageIndex(data.num);
                        getList('', data.num);
                      }}
                    >
                      {data.num}
                    </button>
                  </div>
                );
                return list;
              })}
            </div>

            {Math.floor(parseInt(pageCount) / 5) + 1 ===
            Math.floor(parseInt(pageIndex) / 5) + 1 ? (
              <>
                {Math.floor(parseInt(pageIndex) % 5) === 0 ? (
                  <button
                    className="btn-pagi"
                    onClick={() => {
                      setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                      getList('next', parseInt(pageGroup) * 5 + 1);
                    }}
                  >
                    NEXT
                    <i className="ico btn-next"></i>
                  </button>
                ) : (
                  <button className="btn-pagi" disabled>
                    NEXT
                    <i className="ico btn-next"></i>
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn-pagi"
                onClick={() => {
                  setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                  getList('next', parseInt(pageGroup) * 5 + 1);
                }}
              >
                NEXT
                <i className="ico btn-next"></i>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
