/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CW_03.tsx
 * @desc 이용방법
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { registUI } from '../../utils/web_common_ui';
import { downloadLink } from './../../utils/download';
export default function GngCwhSCR_H_CW_MO_CW_03() {
  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.stepModule) && _.isFunction(window.stepModule)) {
        registUI('.module-link-step', window.stepModule);

        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 200);
  }, []);
  return (
    <>
      <div className="contents p0 washcling bs">
        <div className="green-pd-se js-inview mx-guide flex-desc">
          <p className="desc-tit inview-el nth-child-7">
            스마트한 세차 라이프,
            <br />
            세차클링
          </p>
        </div>

        <div className="module-link-step">
          <div className="mo-guide">
            <ol>
              <li className="">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <div>
                    <span className="num">01</span>
                    STEP
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <div>
                    <span className="num">02</span>
                    STEP
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <div>
                    <span className="num">03</span>
                    STEP
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <div>
                    <span className="num">04</span>
                    STEP
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <div>
                    <span className="num">05</span>
                    STEP
                  </div>
                </a>
              </li>
            </ol>
          </div>
        </div>

        <div className="content-layout mb74">
          <div className="step_detail js-ani">
            <div className="step_num">
              <span>STEP</span>01
              <div className="step-prgs">
                <span></span>
              </div>
            </div>
            <div className="step_con">
              <div className="step_txt">
                <p className="tit">앱을 설치해주세요</p>
                <p className="info">
                  앱스토어 또는 플레이스토에서 <em>‘세차클링’을 검색</em>해 앱을
                  설치해주세요.
                </p>
              </div>
              <div className="img-wrap">
                <img src="/static/mob/images/contents/img_step_01.png" alt="" />
              </div>
            </div>
          </div>
          <div className="step_detail js-ani">
            <div className="step_num">
              <span>STEP</span>02
              <div className="step-prgs">
                <span></span>
              </div>
            </div>
            <div className="step_con">
              <div className="step_txt">
                <p className="tit">회원가입을 해주세요</p>
                <p className="info">
                  L.POINT 아이디 혹은 SNS 계정으로 <em>간편하게 가입을 완료</em>
                  합니다.
                </p>
              </div>
              <div className="img-wrap">
                <img src="/static/mob/images/contents/img_step_02.png" alt="" />
              </div>
            </div>
          </div>

          <div className="step_detail js-ani">
            <div className="step_num">
              <span>STEP</span>03
              <div className="step-prgs">
                <span></span>
              </div>
            </div>
            <div className="step_con">
              <div className="step_txt">
                <p className="tit">간편하게 예약하세요</p>
                <p className="info">
                  <em>원하는 장소와 시간을 설정</em>해 간편하게 세차 예약을
                  완료합니다.
                </p>
                <p className="tip">
                  <span>TIP</span>다양한 쿠폰과 이벤트를 확인해 더욱 합리적인
                  가격에 이용해보세요!
                </p>
              </div>
              <div className="img-wrap">
                <img src="/static/mob/images/contents/img_step_03.png" alt="" />
              </div>
            </div>
          </div>
          <div className="step_detail js-ani">
            <div className="step_num">
              <span>STEP</span>04
              <div className="step-prgs">
                <span></span>
              </div>
            </div>
            <div className="step_con">
              <div className="step_txt">
                <p className="tit">테크니션 배정을 확인하세요</p>
                <p className="info">
                  세차 예약이 완료되면 <em>세차담당 테크니션이 배정</em>됩니다.
                </p>
              </div>
              <div className="img-wrap">
                <img src="/static/mob/images/contents/img_step_04.png" alt="" />
              </div>
            </div>
          </div>

          <div className="step_detail js-ani">
            <div className="step_num">
              <span>STEP</span>05
              <div className="step-prgs">
                <span></span>
              </div>
            </div>
            <div className="step_con">
              <div className="step_txt">
                <p className="tit">깨끗해진 차를 확인하세요</p>
                <p className="info">
                  세차 예약 당일, 비대면으로 세차가 진행됩니다. 세차 완료 알림을
                  받은 후 깨끗해진 내 차를 확인해보세요!
                </p>
              </div>
              <div className="img-wrap">
                <img src="/static/mob/images/contents/img_step_05.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="washcling__footer_info">
          <div className="tit js-inview">
            <h2>
              세차클링에는
              <br />
              이런 서비스가 있어요
            </h2>
          </div>
          <div className="cling-service">
            <div className="box-item js-inview">
              <dl className="">
                <dt className="inview-el nth-child-1">외부세차</dt>
                <dd className="inview-el nth-child-3">
                  오염세정 / 물왁스세정 / 휠세정
                </dd>
              </dl>
            </div>
            <div className="box-item js-inview mt40">
              <dl>
                <dt className="inview-el nth-child-1">실내세차</dt>
                <dd className="inview-el nth-child-3">
                  바닥세정 / 먼지세정 / 메트탈거
                </dd>
              </dl>
            </div>
          </div>
          <div className="btn-primary mt32">
            <button type="button" className="btn" onClick={downloadLink}>
              세차클링 앱 다운로드
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
