/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CS_07.tsx
 * @desc 공지사항 상세
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useState } from 'react';
import _, { size } from 'lodash';
import { formatDateToString } from './../../utils/math';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  noticeInfoDefaultProps,
  searchNoticeInfoApi,
  noticeListDefaultProps,
  selectNoticeAllListApi
} from './../../api/ci/csCenter';
import { composeInitialProps } from 'react-i18next';
import { useParams } from 'react-router';

export default function GngCwhSCR_H_CW_MO_CS_07() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const paramsId = params.id;

  const [prevYn, setPrevYn] = useState('');
  const [nextYn, setNextYn] = useState('');

  const [prevSeq, setPrevSeq] = useState('');
  const [nextSeq, setNextSeq] = useState('');

  const state = location.state as {
    pageIndex: string;
    searchValue: string;
  };
  let pageIndex = '';
  let searchValue = '';
  if (state) {
    pageIndex = state.pageIndex;
    searchValue = state.searchValue;
  }

  const defaultInfoProps: noticeInfoDefaultProps = {
    noticeInfo: {
      num: '',
      noticeSeq: '',
      noticeTypCd: '',
      noticeTit: '',
      noticeCtnt: '',
      ntceStrtDttm: '',
      ntceEndDttm: '',
      rdcnt: '',
      ntceYn: '',
      atchflId: '',
      useYn: '',
      frstRdtt: '',
      newCheck: ''
    }
  };
  const [noticeInfoData, setNoticeInfoData] = useState(defaultInfoProps);

  const defaultListProps: noticeListDefaultProps = {
    noticeList: []
  };
  const [noticeListData, setNoticeListData] = useState(defaultListProps);

  useEffect(() => {
    searchNoticeInfoApi(paramsId).then((res: any) => {
      const date = new Date(res.data.noticeInfo[0].frstRdtt);
      res.data.noticeInfo[0].frstRdtt =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

      setNoticeInfoData((prevState: any) => ({
        ...prevState,
        noticeInfo: res.data.noticeInfo[0]
      }));

      document.getElementsByClassName('nttCtntDiv')[0].innerHTML =
        res.data.noticeInfo[0].noticeCtnt;
    });

    selectNoticeAllListApi(searchValue).then((res: any) => {
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));

      for (let i = 0; i < res.data.noticeList.length; i++) {
        if (res.data.noticeList[i].noticeSeq + '' === paramsId) {
          if (i != 0) {
            setPrevYn('Y');
            setPrevSeq(res.data.noticeList[i - 1].noticeSeq);
            document.getElementsByClassName('prevTit')[0].innerHTML =
              res.data.noticeList[i - 1].noticeTit;
          } else {
            setPrevYn('N');
          }
          if (i != res.data.noticeList.length - 1) {
            setNextYn('Y');
            setNextSeq(res.data.noticeList[i + 1].noticeSeq);
            document.getElementsByClassName('nextTit')[0].innerHTML =
              res.data.noticeList[i + 1].noticeTit;
          } else {
            setNextYn('N');
          }
        }
      }
    });
  }, [paramsId]);

  const settingData = (type: any) => {
    let seqId = '';
    if (type === 'prev') {
      seqId = prevSeq;
    } else {
      seqId = nextSeq;
    }
    navigate('/m/notice/view/' + seqId, {
      state: {
        pageIndex: pageIndex,
        searchValue: searchValue
      }
    });
  };

  return (
    <div className="contents">
      <div className="ps">
        <div className="board-detail">
          <div className="board-header">
            <h3 className="title">{noticeInfoData.noticeInfo.noticeTit}</h3>
            <div className="date">{noticeInfoData.noticeInfo.frstRdtt}</div>
          </div>
          <div className="board-body nttCtntDiv ims-data"></div>
          <div className="board-footer">
            <div className="board-navi">
              {prevYn === 'Y' ? (
                <div className="item prev">
                  <button
                    className="btn-navi"
                    onClick={() => {
                      settingData('prev');
                    }}
                  >
                    <span className="text">PREV</span>
                    <i className="ico arrow-top xs"></i>
                  </button>
                  <p className="title prevTit"></p>
                  <div className="title"></div>
                </div>
              ) : (
                <div
                  className="item prev"
                  style={{
                    display: 'none'
                  }}
                >
                  <button className="btn-navi">
                    <span className="text">PREV</span>
                    <i className="ico arrow-top xs"></i>
                  </button>
                  <p className="title prevTit"></p>
                  <div className="title"></div>
                </div>
              )}

              {nextYn === 'Y' ? (
                <div className="item next">
                  <button
                    className="btn-navi"
                    onClick={() => {
                      settingData('next');
                    }}
                  >
                    <span className="text">NEXT</span>
                    <i className="ico arrow-bottom xs"></i>
                  </button>
                  <p className="title nextTit"></p>
                </div>
              ) : (
                <div
                  className="item next"
                  style={{
                    display: 'none'
                  }}
                >
                  <button className="btn-navi">
                    <span className="text">NEXT</span>
                    <i className="ico arrow-bottom xs"></i>
                  </button>
                  <p className="title nextTit"></p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btn-group mt32">
          <button
            className="btn md"
            onClick={() => {
              navigate('/m/notice/list');
            }}
          >
            목록
          </button>
        </div>
      </div>
    </div>
  );
}
