/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_PS_02.tsx
 * @desc 제휴문의 (작성)
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useRef, useState, useEffect } from 'react';
import {
  coopCodeDefaultProps,
  selectCoopCodeApi,
  pathDefaultProps,
  selectPathCodeApi,
  saveCoopApi,
  uploadFilesApi
} from './../../api/ci/partner';
import _, { size, values } from 'lodash';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { setAlert } from '../../utils/web_common_ui';

export default function GngCwhSCR_H_CW_MO_PS_02() {
  let alertPopup: HTMLElement = document.getElementsByClassName(
    'alertPopup'
  )[0] as HTMLElement;

  const [type, setType] = useState('');
  const [tit, setTit] = useState('');
  const [ctnt, setCtnt] = useState('');
  const [coop, setCoop] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fileName, setFileName] = useState('');
  const [inPath, setInPath] = useState('');
  const [agreeYn, setAgreeYn] = useState('');

  const [file1, setFile1] = useState<any>();
  const [fileName0, setFileName0] = useState('');

  const fileGroup = [
    {
      value: file1,
      setter: setFile1,
      fileRef: useRef<any>(),
      imgRef: useRef<any>()
    }
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const defaultListProps: coopCodeDefaultProps = {
    codeList: []
  };
  const [greenCodeListData, setGreenCodeListData] = useState(defaultListProps);

  const defaultPathProps: pathDefaultProps = {
    codeList: []
  };
  const [pathCodeData, setPathCodeData] = useState(defaultPathProps);

  useEffect(() => {
    selectCoopCodeApi().then((res: any) => {
      setGreenCodeListData((prevState: any) => ({
        ...prevState,
        codeList: [...res.data]
      }));
    });

    selectPathCodeApi().then((res: any) => {
      setPathCodeData((prevState: any) => ({
        ...prevState,
        codeList: [...res.data]
      }));
    });
  }, []);

  const clickFile = () => {
    let element: HTMLElement = document.getElementsByClassName(
      'ip-file'
    )[0] as HTMLElement;

    element.click();
  };

  const changeType = (e: any) => {
    const value = e.target.value;
    setType(value);
  };

  const changeTit = (e: any) => {
    const value = e.target.value;
    setTit(value);
  };
  const changeCtnt = (e: any) => {
    const value = e.target.value;
    setCtnt(value);
  };

  const changeFile = (e: any) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    } else {
      setFileName('');
    }
  };

  const uploadFiles = (e: any) => {
    let formData = new FormData();

    formData.append('dtlCd', '00011');
    formData.append('useGrpYn', 'N');
    if (!_.isNil(file1) && !_.isNil(file1[0])) {
      formData.append('file', file1[0]);

      uploadFilesApi(formData).then((res: any) => {
        let file1Seq = '';
        if (!_.isNil(file1) && !_.isNil(file1[0])) {
          file1Seq = res.data[0].atchflSeq;
        }
        saveCoop(file1Seq);
      });
    } else {
      saveCoop('');
    }
  };

  const changeCoop = (e: any) => {
    const value = e.target.value;
    setCoop(value);
  };

  const changePhone = (e: any) => {
    const value = e.target.value;
    setPhone(value);
  };

  const changeEmail = (e: any) => {
    const value = e.target.value;
    setEmail(value);
  };

  const changePath = (e: any) => {
    const value = e.target.value;
    setInPath(value);
  };

  const agreeCheck = (e: any) => {
    const value = e.target.checked;
    setAgreeYn(value);
  };

  const saveCoop = (file1Seq: any) => {
    if (!tit) {
      setAlert('제목을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!ctnt) {
      setAlert('내용을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!coop) {
      setAlert('회사명을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!phone) {
      setAlert('담당자 연락처을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!email) {
      setAlert('담당자 이메일을 입력해 주세요.');
      alertPopup.click();
      return;
    }
    if (!agreeYn) {
      setAlert('개인정보 수집 및 이용에 동의해주세요.');
      alertPopup.click();
      return;
    }

    const params = {
      inqryCcd: type,
      propseCmpnyNm: coop,
      chargerTelno: phone,
      chargerEmail: email,
      nttTit: tit,
      nttCtnt: ctnt,
      flpth: file1Seq,
      ldinPathCcd: inPath
    };

    saveCoopApi(params).then((res: any) => {
      setAlert('등록이 완료되었습니다.');
      alertPopup.click();
      navigate('/m/alliance');
    });
  };

  const tempImageRender = (files: any, ref: any, setter: any) => {
    const limitSize = 2 * 1024 * 1024;
    if (!_.isNil(files) && !_.isNil(files[0])) {
      if (files[0].size > limitSize) {
        alert('파일 사이즈가 10MB를 넘습니다.');
        setter(null);
        ref.current.src = '';
      }
    } else if (!_.isNil(ref.current)) {
      ref.current.src = '';
    }
  };

  return (
    <>
      <div className="contents">
        <div className="ps">
          <form className="form">
            <div className="form-label">구분</div>
            <div className="form-select">
              <select title="문의구분 선택" onChange={changeType}>
                {greenCodeListData.codeList.map((type) => {
                  const list = (
                    <option key={type.dtlCd} value={type.dtlCd}>
                      {type.dtlCdNm}
                    </option>
                  );
                  return list;
                })}
              </select>
              <span className="open-arrow"></span>
            </div>
            <hr className="divider" />
            <div className="form-label">제목</div>
            <input
              type="text"
              className="form-control tit"
              title="제목 입력"
              placeholder="제목을 입력해주세요"
              style={{
                width: '100%'
              }}
              onChange={changeTit}
            />
            <hr className="divider" />
            <div className="form-label">내용</div>
            <div className="form-textarea">
              <textarea
                cols={30}
                rows={10}
                className="ctnt"
                placeholder="내용을 입력해주세요"
                onChange={changeCtnt}
              ></textarea>
            </div>
            <hr className="divider" />
            <div className="form-label">첨부파일</div>
            <div className="row">
              {_.map(fileGroup, (file: any, key: number) => {
                return (
                  <>
                    <input
                      type="file"
                      className="ip-file"
                      style={{
                        display: 'none'
                      }}
                      onChange={(e: any) => {
                        if (key == 0) {
                          setFileName0(e.target.files[0].name);
                        }

                        file.setter(e.target.files);
                        tempImageRender(file.value, file.imgRef, file.setter);
                      }}
                      ref={file.fileRef}
                      accept="image/*"
                    />
                    {key == 0 ? (
                      <input
                        type="text"
                        className="form-control fileName"
                        title="첨부파일"
                        placeholder="파일명"
                        value={fileName0}
                        readOnly
                      />
                    ) : null}
                    <button
                      type="button"
                      className="btn sm"
                      onClick={() => {
                        file.fileRef.current.click();
                      }}
                    >
                      파일찾기
                    </button>
                  </>
                );
              })}

              {/* <input
                type="file"
                className="ip-file"
                onChange={changeFile}
                style={{
                  display: 'none'
                }}
                accept="image/*"
                readOnly
              />
              <input
                type="text"
                className="form-control fileName"
                title="첨부파일"
                placeholder="파일명"
                value={fileName}
                readOnly
              />
              <button
                className="btn sm"
                onClick={() => {
                  file.fileRef.current.click();
                }}
              >
                파일찾기
              </button> */}
            </div>
            <p className="form-guide">
              제휴와 관련된 문서 또는 이미지 파일을 첨부해주시면 검토에 도움을
              줄 수 있습니다. <br />
              (확장자 : pdf 외 jpg, jpeg, gif, png, bmp)
            </p>
            <p className="form-guide">
              파일 크기가 10MB 미만인 경우만 업로드 가능합니다.
            </p>
            <hr className="divider" />
            <div className="form-label">회사명</div>
            <input
              type="text"
              className="form-control coop"
              title="회사명 입력"
              placeholder="회사명을 입력해주세요"
              onChange={changeCoop}
            />
            <hr className="divider" />
            <div className="form-label">담당자 연락처</div>
            <input
              type="tel"
              className="form-control phone"
              title="담당자 연락처"
              placeholder="연락가능한 담당자 연락처를 입력해주세요."
              onChange={changePhone}
            />
            <hr className="divider" />
            <div className="form-label">담당자 이메일</div>
            <input
              type="text"
              className="form-control email"
              title="담당자 이메일"
              placeholder="담당자 이메일을 입력해주세요."
              onChange={changeEmail}
            />
            <hr className="divider" />
            <div className="form-label">인입 경로</div>
            <div className="form-select">
              <select title="인입경로 선택" onChange={changePath}>
                {pathCodeData.codeList.map((type) => {
                  const list = (
                    <option key={type.dtlCd} value={type.dtlCd}>
                      {type.dtlCdNm}
                    </option>
                  );
                  return list;
                })}
              </select>
              <span className="open-arrow"></span>
            </div>
          </form>
          <div className="info-box mt30">
            <strong>개인정보 수집 및 이용 동의 안내</strong>
            <ol className="list numeric mt10 newlist">
              <li>항목 : 회사명, 연락처, 이메일</li>
              <li>목적 : 제휴 제안에 따른 연락처 정보 확인</li>
              <li>
                보유기간 : 신청 후 1개월간 보관 후 파기 위 정보 수집에 대한
                동의를 거부할 권리 가 있으며, 동의 거부 시에는 제휴 제안 접수가
                제한될 수 있습니다.
              </li>
            </ol>
          </div>
          <div className="checkbox-area">
            <label htmlFor="tmpCheckbook4" className="checkbox round">
              <input type="checkbox" id="tmpCheckbook4" onChange={agreeCheck} />
              <span>개인정보 수집 및 이용에 동의합니다</span>
            </label>
          </div>
          <div className="btn-group mt10">
            <button className="btn md" onClick={uploadFiles}>
              등록 완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
