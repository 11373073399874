/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_MA_01.tsx
 * @desc 메인메뉴
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
import { downloadLink } from '../../utils/download';
import { useNavigate } from 'react-router-dom';

export default function GngCwhSCR_H_CW_PC_MA_01() {
  const navi = useNavigate();
  return (
    <>
      <div id="ui_parallax_page" className="parallax">
        <div className="parallax__01">
          <div className="intro-module">
            <div className="intro-full">
              <div className="intro-ani">
                <div className="title">
                  <h2
                    className="txt-rotate"
                    data-period="6000"
                    data-rotate='[ "언제나 새 차처럼 세차클링"]'
                  ></h2>
                </div>
              </div>
            </div>
          </div>
          <div className="mouse-icon">
            <div className="wheel"></div>
          </div>
        </div>

        <div className="parallax__02 parallax__02_txt">
          <p>
            <span>언제 어디서나 내 차 관리,</span>
            <span>간편하게 세차클링 하세요.</span>
          </p>
        </div>

        <div className="parallax__03">
          <div className="parallax__03_bg parallax__bg">
            <div className="l" aria-hidden="true"></div>
            <div className="r" aria-hidden="true"></div>
          </div>
          <div className="parallax__txt">
            <strong>#간편하게</strong>
            <span>원하는 시간, 장소 어디서든 세차하세요. </span>
          </div>
        </div>

        <div className="parallax__04">
          <div className="parallax__04_bg parallax__bg">
            <div className="l" aria-hidden="true"></div>
            <div className="r" aria-hidden="true"></div>
          </div>
          <div className="parallax__txt">
            <strong>#믿을수있는</strong>
            <span>
              월 7만 여건, G car의 차량관리 노하우와 품질이 검증된 맞춤형
              서비스를 받아보세요.
            </span>
          </div>
        </div>

        <div className="parallax__05">
          <div className="parallax__05_bg parallax__bg">
            <div className="l" aria-hidden="true"></div>
            <div className="r" aria-hidden="true"></div>
          </div>
          <div className="parallax__txt">
            <strong>#합리적가격</strong>
            <span>
              꼭 필요한 구성으로 이뤄진 상품으로 가성비 효과를 누려보세요.
            </span>
          </div>
        </div>

        <div className="parallax__06">
          <div className="parallax__06_bg parallax__bg">
            <div className="l" aria-hidden="true"></div>
            <div className="r" aria-hidden="true"></div>
          </div>
          <div className="parallax__txt">
            <strong>#전기차도</strong>
            <span>
              자동 세차장이 없는 전기차량 고객님도 걱정말고 세차하세요.
            </span>
          </div>
        </div>

        <div className="parallax__07">
          <span className="empty"></span>
          <p className="parallax__07_txt">
            <span>합리적인 구성의 ‘언제나 새 차처럼’ 상품</span>
            <span>언제, 어디서나, 스마트 세차 서비스를 누려보세요.</span>
          </p>

          <div className="service-box">
            <ul className="btn-list">
              <li>
                <a
                  onClick={() => {
                    navi('/service');
                  }}
                >
                  <p>1회 세차</p>
                  <div>
                    <strong>중요한 하루!</strong>
                    <p>
                      특별한 날 새차처럼
                      <br />
                      보이고 싶은
                      <br />
                      고객의 선택!
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navi('/service');
                  }}
                >
                  <p>2회 세차</p>
                  <div>
                    <strong>가성비</strong>
                    <p>
                      기계식 세차를 이용하지
                      <br />
                      못하는 전기차 사용자들을
                      <br />
                      위한 선택
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navi('/service');
                  }}
                >
                  <p>4회 세차</p>
                  <div>
                    <strong>
                      주말은 내가!
                      <br />
                      평일은 매일 매일!
                    </strong>
                    <p>
                      꾸준한 관리가 중점인
                      <br />
                      깔끔한 고객의 선택!
                    </p>
                  </div>
                </a>
              </li>
            </ul>
            <div className="service-bg"></div>
          </div>
        </div>

        <div className="parallax__08">
          <div className="tit">
            <span>환경과 서비스 퀄리티를 생각하는 세차클링</span>
            <span>올바르게, 친환경적으로</span>
          </div>
          <div className="etc-list">
            <ul>
              <li>
                <p>
                  최소 500ml의 물 사용으로
                  <br />
                  친환경 워터리스 방식 세차
                </p>
              </li>
              <li>
                <p>
                  차량손상이 적은
                  <br />
                  세차클링만의
                  <br />
                  윤활제와 계면활성제 혼합비율
                </p>
              </li>
              <li>
                <p>
                  주기적인 세차 타월교체와
                  <br />
                  장비 관리를 통한 품질유지
                </p>
              </li>
              <li>
                <p>
                  주변시설 소음과 오염을
                  <br />
                  최소화한 세차 기본
                  <br />
                  에티켓 준수
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="parallax__09">
          <div className="tit">
            <span>세차클링만의 다양한 혜택으로</span>
            <span>즐거움을 더해보세요.</span>
          </div>
          <div className="sub">세차클링 혜택</div>
          <div className="benefit-list">
            <ul>
              <li>
                <p>
                  회원을 위한 정기 세차
                  <br />
                  클링패스
                </p>
              </li>
              <li>
                <p>
                  다양한 프로모션과
                  <br />
                  이벤트 혜택
                </p>
              </li>
              <li>
                <p>
                  세차클링으로
                  <br />
                  새 차처럼
                  <br />
                  적립금 팡팡!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="app-download">
        <a href="#" onClick={downloadLink}>
          <span className="hide-txt">세차클링 앱 다운로드</span>
        </a>
      </div>
    </>
  );
}
