/**
 * <pre>
 * @title http.ts
 * @desc HTTP 클라이언트 및 REST API 서비스 모듈
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.22 21:01:33
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.22.  김완수  최초작성
 */
import { getGreencarApi } from '../../../config';
import { Http } from '@gngs/client-commons';
import {
  gmaResponseErrorHandler,
  gmaHttpErrorHandler,
  ResponseErrorHandler
} from './httpErrorHandler';
import { HttpErrorHandler } from '@gngs/client-commons';
import { AxiosResponse } from 'axios';
import { GmaResponseType } from '../vo/greenApiDefaultVo';

export function getHttp(baseUrl: string = getGreencarApi() || '') {
  const http = new Http(baseUrl);
  return http;
}

export function gmaResponseHandler<T>(res: AxiosResponse<GmaResponseType<T>>) {
  return gmaResponseErrorHandler(res).catch(gmaHttpErrorHandler);
}

/**
 * @todo HTTP 통신시 재요청, 예외 처리, 인증 헤더 등 반복 코드 제어
 */
export class RestApiService<T> {
  private _autoRetry: boolean;
  // private _setAuth?: boolean;
  private _httpErrorHandler?: HttpErrorHandler;
  private _responseErrorHandler?: ResponseErrorHandler<T>;

  constructor(autoRetry = true) {
    this._autoRetry = autoRetry;
  }

  setAutoRetry(retry: boolean) {
    this._autoRetry = retry;
  }

  setResponseErrorHandler(handler: ResponseErrorHandler<T>) {
    this._responseErrorHandler = handler;
  }

  setHttpErrorHandler(handler: HttpErrorHandler) {
    this._httpErrorHandler = handler;
  }

  call(request: Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> {
    return request
      .then(this._responseErrorHandler)
      .catch(this._httpErrorHandler);
  }
}

export const greencarApiService = new RestApiService<GmaResponseType<any>>();
greencarApiService.setHttpErrorHandler(gmaHttpErrorHandler);
greencarApiService.setResponseErrorHandler(gmaResponseErrorHandler);
