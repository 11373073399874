import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Link, Outlet } from 'react-router-dom';
import _ from 'lodash';

import { pages, Page } from '../../../config/pages/pages';
import { registUI } from '../../../utils/web_common_ui';

const MobileContentEmptyLayout = () => {
  const pageInfo = _.find(pages, (page: Page) => {
    const pathname = location.pathname.split('/');
    const pageurl = page.url.split('/');

    return location.pathname.indexOf(page.url) >= 0;
  });

  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.uiTop)) {
        registUI('.ui-top', window.uiTop);
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);

    let element: HTMLElement = document.getElementsByClassName(
      'btn-top'
    )[0] as HTMLElement;

    element.click();
  }, []);

  return (
    <div id="wrap">
      <Header headerType="sub" />
      <main className="container">
        <Outlet />
      </main>
      <Footer />

      <article className="modal c" id="modal_1">
        <div className="modal-dialog">
          <div className="">
            <h1 className="ptit"></h1>
          </div>
          <div className="modal-content">
            <div className="modal-body alert center">준비중입니다.</div>
          </div>
          <div className="modal-footer">
            <div className="btn-set">
              <button type="button" className="btn btn-pop-close dark">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>

      <article className="modal c" id="modal_2">
        <div className="modal-dialog">
          <div className="">
            <h1 className="ptit"></h1>
          </div>
          <div className="modal-content">
            <div className="modal-body alert center alertContent"></div>
          </div>
          <div className="modal-footer">
            <div className="btn-set">
              <button type="button" className="btn btn-pop-close dark">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default React.memo(MobileContentEmptyLayout);
