/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CS_06.tsx
 * @desc 공지사항 리스트
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  noticeListDefaultProps,
  selectNoticeListApi,
  selectNoticeAllListApi
} from './../../api/ci/csCenter';
import _, { size } from 'lodash';
import { formatDateToString } from './../../utils/math';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

export default function GngCwhSCR_H_CW_MO_CS_06() {
  const navigate = useNavigate();
  const location = useLocation();

  const [text, setText] = useState('');
  const [searchFlag, setSearchFlag] = useState('');

  const [pageIndex, setPageIndex] = useState('1');
  const [pageGroup, setPageGroup] = useState('1');
  const [totalCount, setTotalCount] = useState('');
  const [pageCount, setPageCount] = useState('1');

  const defaultListProps: noticeListDefaultProps = {
    noticeList: []
  };
  const [noticeListData, setNoticeListData] = useState(defaultListProps);

  useEffect(() => {
    selectNoticeListApi(pageIndex, '').then((res: any) => {
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));

      setPagination(pageIndex, '');
    });
  }, []);

  // 검색어 입력, 변경
  const onChange = (e: any) => {
    setSearchFlag('');
    if (e.target.value === '') {
      clearSearchValBtn();
    }
    setText(e.target.value);
  };

  // 검색어 초기화
  const clearSearchValBtn = () => {
    setText('');
    setSearchFlag('');
    selectNoticeListApi(pageIndex, '').then((res: any) => {
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
      setPagination(pageIndex, '');
    });
  };

  // 공지사항 검색
  const noticeSearch = () => {
    selectNoticeListApi(pageIndex, text).then((res: any) => {
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
      setSearchFlag('search');

      setPagination(pageIndex, text);
    });
  };

  let pageCountArray: any = [];
  const pageCountData = {
    pageList: [
      {
        num: '1'
      }
    ]
  };
  const [pageData, setPageData] = useState(pageCountData);
  const setPagination = (page: any, value: any) => {
    selectNoticeAllListApi(value).then((res: any) => {
      setTotalCount(res.data.noticeList.length);
      let pc = '';
      if (Math.floor(parseInt(res.data.noticeList.length) % 10) == 0) {
        pc = Math.floor(parseInt(res.data.noticeList.length) / 10) + '';
      } else {
        pc = Math.floor(parseInt(res.data.noticeList.length) / 10 + 1) + '';
      }
      setPageCount(pc);

      let pagenumber = page;
      let lastnumber = page;
      lastnumber = parseInt(pagenumber) + 5 + '';
      for (let i = parseInt(pagenumber); i < parseInt(lastnumber); i++) {
        if (parseInt(pc) >= i) {
          const param = {
            num: i + ''
          };
          pageCountArray.push(param);
        }
      }

      setPageData((prevState: any) => ({
        ...prevState,
        pageList: pageCountArray
      }));
    });
  };
  const getList = (type: any, pageNum: any) => {
    selectNoticeListApi(pageNum, text).then((res: any) => {
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...res.data.noticeList]
      }));
      if (pageNum != 1 && pageNum % 5 == 1) {
        if (type == 'next') {
          setPageGroup(parseInt(pageGroup) + 1 + '');
        }

        setPagination(pageNum, res.totalCount);
      }
      if (pageNum % 5 == 0) {
        if (pageNum != 5) {
          setPageGroup(parseInt(pageNum) / 5 + '');
          setPagination(pageNum - 4, res.totalCount);
        } else {
          setPageGroup('1');
          setPagination(1, res.totalCount);
        }
      }
    });
  };

  return (
    <>
      <div className="contents">
        <div className="ps">
          <div className="search-box mb20">
            <input
              type="text"
              className=""
              placeholder="검색어를 입력해주세요."
              onChange={onChange}
              value={text}
              // onFocus={() => {
              //   clearSearchValBtn();
              // }}
            />
            <button className="btn icon" onClick={noticeSearch}>
              <i className="ico search sm"></i>
            </button>
            {text != '' ? (
              <button
                type="button"
                className="clean-btn"
                onClick={clearSearchValBtn}
              >
                <i className="ico input-delete sm"></i>
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="board-list">
            {searchFlag != '' ? (
              <div className="board-list-head">
                <p>
                  <span className="emp">‘{text}’ </span>검색 결과
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="board-list-body">
              <ul>
                {noticeListData.noticeList.length === 0 ? (
                  <>
                    {text === null || text === '' ? (
                      <div className="board-list-body">
                        <div className="no-data">
                          <p>등록된 공지사항이 없습니다.</p>
                        </div>
                      </div>
                    ) : (
                      <div className="board-list-body">
                        <div className="no-data">
                          <p>
                            검색 결과가 없습니다.
                            <br />
                            다른 검색어를 이용해주세요.
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {noticeListData.noticeList.map((data) => {
                      let coloredCode = data.noticeTit.replaceAll(
                        text,
                        `<span style="color: #f00;">${text}</span>`
                      );
                      const list = (
                        <li
                          key={data.noticeSeq}
                          className={
                            data.imprtncNtfYn === 'Y'
                              ? 'item notice-list'
                              : 'item'
                          }
                          onClick={() => {
                            navigate('/m/notice/view/' + data.noticeSeq, {
                              state: {
                                pageIndex: pageIndex,
                                searchValue: text
                              }
                            });
                          }}
                        >
                          <a href="#">
                            {data.imprtncNtfYn === 'Y' ? (
                              <span className="badge">Notice</span>
                            ) : (
                              <></>
                            )}

                            {searchFlag != '' ? (
                              <p
                                className="title"
                                dangerouslySetInnerHTML={{
                                  __html: coloredCode
                                }}
                              ></p>
                            ) : (
                              <p className="title">{data.noticeTit}</p>
                            )}

                            <p className="date">{data.frstRdtt}</p>
                          </a>
                        </li>
                      );
                      return list;
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>

          {noticeListData.noticeList.length === 0 ? (
            <></>
          ) : (
            <ul className="pagination">
              {pageGroup === '1' ? (
                <></>
              ) : (
                <li className="previous">
                  <a
                    onClick={() => {
                      setPageIndex((parseInt(pageGroup) - 1) * 5 + '');
                      // console.log((parseInt(pageGroup) - 1) * 5 + '');
                      getList('prev', (parseInt(pageGroup) - 1) * 5);
                    }}
                  >
                    <i className="ico chevron-left xs"></i>
                  </a>
                </li>
              )}

              {pageData.pageList.map((data) => {
                const list = (
                  <li
                    key={data.num}
                    className={data.num === pageIndex ? 'active' : ''}
                  >
                    <a
                      onClick={() => {
                        setPageIndex(data.num);
                        getList('', data.num);
                      }}
                    >
                      {data.num}
                    </a>
                  </li>
                );
                return list;
              })}

              {Math.floor(parseInt(pageCount) / 5) + 1 ===
              Math.floor(parseInt(pageIndex) / 5) + 1 ? (
                <>
                  {Math.floor(parseInt(pageIndex) % 5) === 0 ? (
                    <li className="next">
                      <a
                        onClick={() => {
                          setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                          getList('next', parseInt(pageGroup) * 5 + 1);
                        }}
                      >
                        <i className="ico chevron-right xs"></i>
                      </a>
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <li className="next">
                  <a
                    onClick={() => {
                      setPageIndex(parseInt(pageGroup) * 5 + 1 + '');
                      getList('next', parseInt(pageGroup) * 5 + 1);
                    }}
                  >
                    <i className="ico chevron-right xs"></i>
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
