/**
 * <pre>
 * @title cstmrListApi.ts
 * @desc 회원정보 조회 API
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.22 22:27:51
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.22.  김완수  최초작성
 */
import { getHttp, gmaResponseHandler } from '../common/rest/http';
import { AxiosRequestConfig } from 'axios';
import { greenAuth } from '../common/auth/greenAuth';
import { GmaResponseType } from '../common/vo/greenApiDefaultVo';

// 자주묻는 질문 카테고리 (SCR_H_GA_PC_CS_02)
export type faqCtgyDefault = {
  dtlCdNm: string;
  dtlCd: string;
  sortOrdr: string;
  isActive?: boolean;
};
export interface faqCtgyDefaultProps {
  faqCtgyList: faqCtgyDefault[];
}
export function selectFaqCtgyApi(config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    code: 'WA0015'
  };
  return http
    .post<GmaResponseType<faqCtgyDefaultProps>>(
      `/api/cs/faq/na/selectFaqCommCode`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}

// 자주묻는 질문 목록 리스트 (SCR_H_GA_PC_CS_02)
export type faqListDefault = {
  faqSeq: string;
  faqCtgyCd: string;
  faqCtgyNm: string;
  faqTit: string;
  faqCtnt: string;
  rdCnt: string;
  ntceYn: string;
  useYn: string;
  isActive?: boolean;
};
export interface faqListDefaultProps {
  faqList: faqListDefault[];
}
export function selectFaqListApi(
  pageIndex: any,
  pageSize: any,
  ctgyCd: any,
  searchValue: any,
  config?: AxiosRequestConfig
) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    excelYn: 'N',
    pageSize: pageSize,
    pageIndex: pageIndex,
    code: ctgyCd,
    searchValue: searchValue
  };
  return http
    .post<GmaResponseType<faqListDefaultProps>>(
      `/api/cs/faq/na/selectHpFaqList`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}

// 공지사항 목록 리스트 (SCR_H_GA_PC_CS_06)
export type noticeListDefault = {
  num: string;
  noticeSeq: string;
  noticeTypCd: string;
  noticeTit: string;
  noticeCtnt: string;
  ntceStrtDttm: string;
  ntceEndDttm: string;
  rdcnt: string;
  ntceYn: string;
  atchflId: string;
  useYn: string;
  frstRdtt: string;
  newCheck: string;
  imprtncNtfYn: string;
  isActive?: boolean;
  prev?: boolean;
  next?: boolean;
};
export interface noticeListDefaultProps {
  noticeList: noticeListDefault[];
}
export function selectNoticeListApi(
  pageIndex: any,
  searchValue: any,
  config?: AxiosRequestConfig
) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    pageSize: '10',
    pageIndex: pageIndex,
    searchValue: searchValue
  };
  return http
    .post<GmaResponseType<noticeListDefaultProps>>(
      `/api/cs/notice/na/selectHpNoticeList`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}

// 공지사항 전체조회
export function selectNoticeAllListApi(
  searchValue: any,
  config?: AxiosRequestConfig
) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    searchValue: searchValue
  };
  return http
    .post<GmaResponseType<noticeListDefaultProps>>(
      `/api/cs/notice/na/selectHpNoticeAllList`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}

// 공지사항 상세 정보 (SCR_H_GA_PC_CS_07)
export type noticeInfoDefault = {
  num: string;
  noticeSeq: string;
  noticeTypCd: string;
  noticeTit: string;
  noticeCtnt: string;
  ntceStrtDttm: string;
  ntceEndDttm: string;
  rdcnt: string;
  ntceYn: string;
  atchflId: string;
  useYn: string;
  frstRdtt: string;
  newCheck: string;
};
export interface noticeInfoDefaultProps {
  noticeInfo: noticeInfoDefault;
}
export function searchNoticeInfoApi(
  noticeSeq: any,
  config?: AxiosRequestConfig
) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    noticeSeq: noticeSeq
  };
  return http
    .post<GmaResponseType<noticeInfoDefaultProps>>(
      `/api/cs/notice/na/selectHpNoticeInfo`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}

// 약관
export type StplatListDefault = {
  stplatScrinCd: string;
  adiDc: string;
  upperStplatGrpNo: string;
  sortOrdrGrp: string;
  stplats: StplatsDefault[];
  stplatGrpNo: string;
  stplatGrpNm: string;
  svcCcd: string;
};
export type StplatsDefault = {
  adiDc: string;
  essntlYn: string;
  stplatSqnc: string;
  stplatNm: string;
  stplatNo: string;
  stplatScrinCpstNo: string;
  sortOrdrStplat: string;
  stplatCtnt: string;
  stplatCcd: string;
  items: ItemDefault[];
  isActive?: boolean;
};
export type ItemDefault = {
  parmNm: string;
  stplatItemNo: string;
  stplatSqnc: string;
  stplatNo: string;
  bassVu: string;
  sortOrdrItem: string;
  itemNm: string;
  stplatItemTyp: string;
};
export type StplatHistDefault = {
  stplatSeq: string;
  stplatNo: string;
  stplatGrpNo: string;
  stplatSqnc: string;
  stplatCcd: string;
  stplatNm: string;
  stplatCtnt: string;
  adiDc: string;
  enfcDttm: string;
  tmntDttm: string;
  essntlYn: string;
  sortOrdr: string;
  useYn: string;
  frstRdtt: string;
  frstRgstrId: string;
  frstRgstrIp: string;
  frstRgstrNm: string;
  lastMdtt: string;
  lastUpdusrId: string;
  lastUpdusrIp: string;
  lastUpdusrNm: string;
};

export interface stplatListDefaultProps {
  stplatList: StplatListDefault[];
}
export interface stplatDefaultProps {
  stplatItem: StplatsDefault[];
}
export interface stplatHistDefaultProps {
  stplatHist: StplatHistDefault[];
}
export function selectStplatListApi(config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    svcCcd: '00002',
    stplatScrinCd: '00101'
  };
  return http
    .post<GmaResponseType<StplatListDefault[]>>(
      `/api/ctStplat/na/retrieveStplatScrin`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}
export function selectStplatHistApi(code: any, config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  req.data = {
    stplatNo: code
  };
  return http
    .post<GmaResponseType<StplatHistDefault[]>>(
      `/api/ctStplat/na/retrieveStplatHist`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}
