export function downloadLink() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  //크롬일 경우 isChrome에는 Chrome이라는 문잘의 위치 값이 반환되고 크롬이 아닐경우는
  //-1이 반환된다. 나머지도 동일
  const isChrome = userAgent.indexOf('chrome');
  const isEdge = userAgent.indexOf('edg');

  if (isChrome > -1) {
    if (isEdge > -1) {
      //Edge는 Chrome과 Edge 모두의 값을 가지고 있기 때문에
      window.open(
        'https://play.google.com/store/apps/details?id=com.app.android.Cargle&referrer=utm_source%3DPC%20Web'
      );
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=com.app.android.Cargle&referrer=utm_source%3DPC%20Web'
      );
    }
  } else {
    window.open(
      'https://apps.apple.com/kr/app/daehanmingug-no-1-kasyeeoling/id1540689331'
    );
  }
  // const agent = window.navigator.userAgent.toLowerCase();

  // if (agent.indexOf('safari') != -1) {
  //   window.open(
  //     'https://play.google.com/store/apps/details?id=com.greencar&referrer=utm_source%3DPC%20Web'
  //   );
  // } else {
  //   window.open(
  //     'https://apps.apple.com/kr/app/daehanmingug-no-1-kasyeeoling/id457792991'
  //   );
  // }
}

export function agentCheck() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  const isMobile = userAgent.indexOf('mobile');
  const isWindow = userAgent.indexOf('windows');
  const isLinux = userAgent.indexOf('linux');

  if (isMobile > -1) {
    return 'mobile';
  } else {
    return 'window';
  }
}
