import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { menuList } from '../../../../utils/getPage';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  webHamburgerMenuInitUi,
  registUI
} from '../../../../utils/web_common_ui';

export default function MainHeader(props: { headerType?: string }) {
  const { headerType = '' } = props;
  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.uiMenu) && _.isFunction(window.headerPosi)) {
        registUI('#header', window.uiMenu);
        if (headerType == 'sub') {
          registUI('#header.sub', window.headerPosi);
        }

        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
  }, []);

  return (
    <header id="header" className={headerType}>
      <div className="head-cont">
        <div className="head-module">
          <div className="logo">
            <Link
              to="index"
              onClick={() => {
                document.body.classList.remove('menu-open');
              }}
            >
              <span className="hide-txt">세차클링</span>
            </Link>
          </div>
          <div className="menu">
            <div className="dimm-bg">
              <div className="bg"></div>
            </div>
            <a
              href="https://cwc-api.clingwash.co.kr/main/index.do"
              className="btn-link"
              target="_blank"
              title="새창 열기"
              rel="noopener noreferrer"
            >
              클링비즈<i className="ico arrow-rt md"></i>
            </a>
            <button className="btn-menu" title="menu" aria-label="Sidebar menu">
              <div className="module">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </button>

            <nav className="gnb">
              <div className="module-gnb">
                <div className="info-module">
                  <div className="menu-logo">
                    <Link to="index">
                      <img
                        src="/static/web/images/common/logo_clingwash.png"
                        alt="세차클링"
                      />
                    </Link>
                  </div>
                  <p className="desc">
                    <span className="ani">
                      <i className="hide-txt">언제 어디서나 새 차처럼,</i>
                    </span>
                    <span className="emp ani">
                      <i className="hide-txt">세차클링 </i>
                    </span>
                  </p>
                  {/* <p className="desc">
                    <span className="ani">
                      <img
                        src="/static/web/images/common/logo_desc_txt.png"
                        alt="언제 어디서나 새 차처럼,"
                      />
                    </span>
                    <span className="emp ani">
                      <img
                        src="/static/web/images/common/logo_clingwash_lg.png"
                        alt="세차클링"
                      />{' '}
                    </span>
                  </p> */}

                  <div className="link-module">
                    <div className="item">
                      <a
                        href="https://www.greencar.co.kr/service/company"
                        className="in"
                        target="_blank"
                        title="새창 열기"
                        rel="noopener noreferrer"
                      >
                        회사소개
                      </a>
                    </div>
                    <div className="item">
                      <a
                        href="#"
                        className="pop_open"
                        data-id="modal_1"
                        onClick={() => {
                          new window.UiPopup('.modal').bindEvent();
                        }}
                      >
                        {' '}
                        인재채용{' '}
                      </a>
                    </div>
                  </div>

                  <div className="sns-module">
                    <p className="txt">
                      <span className="ani"></span>
                    </p>
                    <div className="sns-ani"></div>
                  </div>
                </div>
                <div className="depth1-list">
                  {menuList
                    .filter((item) => item.device == 'WEB' && item.order !== 5)
                    .map((menuGroup: any, index: number) => {
                      return (
                        <div className="depth1" key={index}>
                          <Link
                            to={'#'}
                            className="btn"
                            onClick={(e) => e.preventDefault()}
                          >
                            <span className="hidden">
                              <span className="ani">{menuGroup.title}</span>
                            </span>
                          </Link>
                          <div className="depth2-list">
                            {_.map(
                              menuGroup.children,
                              (menu: any, sIndex: number) => {
                                return (
                                  <div className="depth2" key={sIndex}>
                                    {menu.link === 'Y' ? (
                                      <a
                                        href={menu.url}
                                        target="_blank"
                                        title="새창 열기"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="hidden">
                                          <span className="ani blank">
                                            {menu.subTitle}
                                          </span>
                                        </span>
                                      </a>
                                    ) : (
                                      <Link
                                        to={menu.url}
                                        onClick={() => {
                                          document.body.classList.remove(
                                            'menu-open'
                                          );
                                        }}
                                      >
                                        <span className="hidden">
                                          <span className="ani">
                                            {menu.subTitle}
                                          </span>
                                        </span>
                                      </Link>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="depth-line"></div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </nav>
            <div className="green-bg"></div>
            <div className="menu-bg"></div>
            <div className="menu-bg right"></div>
          </div>
        </div>
      </div>
    </header>
  );
}
