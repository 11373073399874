/**
 * <pre>
 * @title cstmrListApi.ts
 * @desc 회원정보 조회 API
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.22 22:27:51
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.22.  김완수  최초작성
 */
import { getHttp, gmaResponseHandler } from '../common/rest/http';
import { AxiosRequestConfig } from 'axios';
import { greenAuth } from '../common/auth/greenAuth';
import { GmaResponseType } from '../common/vo/greenApiDefaultVo';

export type coopCodeDefault = {
  dtlCdNm: string;
  dtlCd: string;
  sortOrdr: string;
  isActive?: boolean;
};
export interface coopCodeDefaultProps {
  codeList: coopCodeDefault[];
}

export type pathCodeDefault = {
  dtlCdNm: string;
  dtlCd: string;
  sortOrdr: string;
  isActive?: boolean;
};
export interface pathDefaultProps {
  codeList: pathCodeDefault[];
}
export function uploadFilesApi(params: any, config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'multipart/form-data'
    }
  };
  req.data = params;
  return http
    .post<GmaResponseType>(`/api/cm/na/uploadInFiles?`, req)
    .then(gmaResponseHandler)
    .then((res) => res.data);
}
// 제휴문의 구분 조회
export function selectCoopCodeApi(config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  return http
    .post<GmaResponseType<coopCodeDefaultProps>>(
      `/api/cs/inqry/na/retrieveCtgyCdList`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}
// 인입경로 구분 조회
export function selectPathCodeApi(config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'application/json'
    }
  };
  return http
    .post<GmaResponseType<pathDefaultProps>>(
      `/api/cs/inqry/na/retrieveLdinPathCcdList`,
      req
    )
    .then(gmaResponseHandler)
    .then((res) => res.data);
}
// 제휴문의 등록
export function saveCoopApi(params: any, config?: AxiosRequestConfig) {
  const http = getHttp();
  const req = {
    ...config,
    headers: {
      //...greenAuth.getAuthHeaders(),
      ...config?.headers,
      'Content-Type': 'multipart/form-data'
    }
  };
  req.data = params;
  return http
    .post<GmaResponseType>(`/api/cs/inqry/na/insertCnaqFaqEvlHist`, req)
    .then(gmaResponseHandler)
    .then((res) => res.data);
}
