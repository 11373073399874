/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_CS_01.tsx
 * @desc 고객센터
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  faqCtgyDefaultProps,
  selectFaqCtgyApi,
  noticeListDefaultProps,
  selectNoticeListApi
} from './../../api/ci/csCenter';
import _, { size } from 'lodash';
import { Link } from 'react-router-dom';
import { downloadLink } from '../../utils/download';

export default function GngCwhSCR_H_CW_MO_CS_01() {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [code, setSearchCode] = useState('');

  const defaultProps: faqCtgyDefaultProps = {
    faqCtgyList: []
  };
  const [faqCtgyData, setFaqCtgyData] = useState(defaultProps);

  const defaultListProps: noticeListDefaultProps = {
    noticeList: []
  };
  const [noticeListData, setNoticeListData] = useState(defaultListProps);

  useEffect(() => {
    selectFaqCtgyApi().then((res: any) => {
      setFaqCtgyData((prevState: any) => ({
        ...prevState,
        faqCtgyList: [...res.data.commCodeList]
      }));
    });

    selectNoticeListApi('1', '').then((res: any) => {
      let array: any[] = [];
      for (let i = 0; i < res.data.noticeList.length; i++) {
        const date = new Date(res.data.noticeList[i].frstRdtt);
        res.data.noticeList[i].frstRdtt =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0');

        if (res.data.noticeList[i].imprtncNtfYn == 'Y') {
          array.push(res.data.noticeList[i]);
        }
      }
      setNoticeListData((prevState: any) => ({
        ...prevState,
        noticeList: [...array]
      }));
    });
  }, []);

  // 검색어 입력, 변경
  const onChange = (e: any) => {
    if (e.target.value === '') {
      clearSearchValBtn();
    }
    setText(e.target.value);
  };

  // 검색어 초기화
  const clearSearchValBtn = () => {
    setText('');
  };

  return (
    <>
      <div className="contents">
        <div className="cs">
          <div className="intro-text">
            <p>
              안녕하세요,
              <br />
              세차클링 고객센터입니다.
              <br />
              무엇을 도와드릴까요?
            </p>
          </div>
          <div className="search-box mt30">
            <input
              type="text"
              placeholder="궁굼한 내용을 검색하세요"
              onChange={onChange}
              value={text}
              // onFocus={() => {
              //   clearSearchValBtn();
              // }}
            />
            <button
              className="btn icon"
              onClick={() => {
                if (text) {
                  navigate('/m/faq/list', {
                    state: {
                      searchCode: code,
                      searchValue: text
                    }
                  });
                }
              }}
            >
              <i className="ico search sm"></i>
            </button>
            {text != '' ? (
              <button
                type="button"
                className="clean-btn"
                onClick={clearSearchValBtn}
              >
                <i className="ico input-delete sm"></i>
              </button>
            ) : (
              <></>
            )}
            {/* <button
              type="button"
              className="btns"
              onClick={clearSearchValBtn}
              style={{
                marginLeft: '5px'
              }}
            >
              초기화
            </button> */}
          </div>
          <div className="scroll-x">
            <ul>
              {faqCtgyData.faqCtgyList.map((info) => {
                const list = (
                  <li
                    key={info.dtlCd}
                    className="item"
                    onClick={() => {
                      //etSearchCode(info.dtlCd);

                      navigate('/m/faq/list', {
                        state: {
                          searchCode: info.dtlCd,
                          searchValue: text
                        }
                      });
                    }}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <a href="#">{info.dtlCdNm}</a>
                  </li>
                );
                return list;
              })}
            </ul>
          </div>
          <div className="quick-menu">
            <div className="item">
              <div className="img"></div>
              <div className="text">
                <strong>02-528-7741</strong>
                <p>월-금 09:00 – 17:00</p>
              </div>
            </div>
            <div
              className="item"
              onClick={() => {
                navigate('/m/faq/list');
              }}
            >
              <div className="img"></div>
              <div className="text">
                <strong>자주묻는질문</strong>
              </div>
            </div>

            <div className="item" onClick={downloadLink}>
              <div className="img"></div>
              <div className="text">
                <strong>1:1문의</strong>
                <p>앱에서 작성 가능</p>
              </div>
            </div>
          </div>
          <section className="notice mt40">
            <h3 className="title">공지사항</h3>
            <div className="board-list">
              <div className="board-list-body">
                <ul>
                  {noticeListData.noticeList.map((data) => {
                    const list = (
                      <li className="item notice-list" key={data.noticeSeq}>
                        <a
                          href="#"
                          onClick={() => {
                            navigate('/m/notice/view/' + data.noticeSeq, {
                              state: {
                                pageIndex: '1',
                                searchValue: ''
                              }
                            });
                          }}
                        >
                          <span className="badge">Notice</span>
                          <p className="title">{data.noticeTit}</p>

                          <p className="date">{data.frstRdtt}</p>
                        </a>
                      </li>
                    );
                    return list;
                  })}
                </ul>
              </div>
            </div>
          </section>

          <div className="btn-group mt32">
            <button
              className="btn line md"
              onClick={() => {
                navigate('/m/notice/list');
              }}
            >
              공지사항 전체보기
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
