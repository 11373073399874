import React, { useEffect } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { Outlet } from 'react-router-dom';
import _ from 'lodash';
import { webContentTitleInitUi } from '../../../utils/web_common_ui';

const MainLayout = () => {
  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.ui.init)) {
        window.ui.init();
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
    webContentTitleInitUi();

    (() => {
      window.addEventListener('beforeunload', (e: any) => {
        document.getElementsByClassName('contents')[0].innerHTML = '';
      });
    })();
  }, []);
  return (
    <div id="wrap" className="main">
      <Header headerType="main" />
      <main id="container">
        <div className="contents">
          <Outlet />
        </div>
      </main>
      <Footer />

      <article className="modal a sm" id="modal_1">
        <div className="modal-dialog not-close">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="ptit"></h1>
            </div>
            <div className="modal-body center">
              <p>준비중입니다</p>
            </div>
            <div className="modal-footer">
              <div className="btn-set b2">
                {/* <button type="button" className="btn close-pop" data-id="modal_1">취소</button> */}
                <button
                  type="button"
                  className="btn close-pop dark b rd lg"
                  data-id="modal_1"
                >
                  확인
                </button>
              </div>
            </div>
            <button type="button" className="btn-pop-close">
              <i className="blind">닫기</i>
            </button>
          </div>
        </div>
      </article>

      <article className="modal a sm" id="modal_2">
        <div className="modal-dialog not-close">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="ptit"></h1>
            </div>
            <div className="modal-body center">
              <p className="alertContent"></p>
            </div>
            <div className="modal-footer">
              <div className="btn-set b2">
                {/* <button type="button" className="btn close-pop" data-id="modal_1">취소</button> */}
                <button
                  type="button"
                  className="btn close-pop dark b rd lg"
                  data-id="modal_2"
                >
                  확인
                </button>
              </div>
            </div>
            <button type="button" className="btn-pop-close">
              <i className="blind">닫기</i>
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};
export default React.memo(MainLayout);
