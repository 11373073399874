/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_TC_01.tsx
 * @desc 회원이용약관
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 *  2023.07.10.  이대규  페이지 라우팅
 */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import {
  selectStplatListApi,
  selectStplatHistApi
} from './../../api/ci/csCenter';

type SelectedHist = {
  stplatSeq: string;
  createMarkup: () => { __html: string };
};
// 경로 상수 정의
const PATHS = {
  CLAUSE: '/clause',
  CLAUSE_TW: '/clausetw',
  CLAUSE_TH: '/clauseth'
};

// 경로에 따른 이름을 반환하는 함수
const getNameByPath = (path: string) => {
  switch (path) {
    case PATHS.CLAUSE:
      return '세차클링 회원 약관';
    case PATHS.CLAUSE_TW:
      return '개인정보처리방침';
    case PATHS.CLAUSE_TH:
      return '위치기반 서비스 이용약관';
    default:
      return '세차클링 회원 약관';
  }
};
export default function GngCwhSCR_H_CW_PC_TC_01() {
  window.scrollTo(0, 0);
  const location = useLocation();

  const [uiTabClass, setUiTabClass] = useState('');
  const [stplatHist, setStplatHist] = useState<SelectedHist | undefined>();
  const [tabStplatNo, setTabStplatNo] = useState<number>(0);

  const { data: stplatList } = useQuery({
    queryKey: ['selectStplatListApi', location.pathname],
    queryFn: () =>
      selectStplatListApi().then((res) => {
        const list = res.data?.[0].stplats
          .filter(
            (v, i, a) => a.findIndex((t) => t.stplatNm === v.stplatNm) === i
          )
          .map((item) => {
            item.isActive = item.stplatNm === getNameByPath(location.pathname);

            return item;
          });
        return list;
      })
  });

  useEffect(() => {
    const stplat = stplatList?.find(
      (item) => item.stplatNm === getNameByPath(location.pathname)
    );
    setTabStplatNo(Number(stplat?.stplatNo));
  }, [location, stplatList]);

  const { data: stplatHistList } = useQuery({
    queryKey: ['selectStplatHistApi', tabStplatNo],
    queryFn: () =>
      selectStplatHistApi(tabStplatNo).then((res) => {
        const list = res?.data?.map((item) => {
          return {
            ...item,
            enfcDttm: item.enfcDttm.substring(0, 10)
          };
        });
        if (list !== undefined) {
          setStplatHist({
            stplatSeq: list[0].stplatSeq,
            createMarkup: () => {
              return { __html: list[0].stplatCtnt };
            }
          });
        }
        return list;
      }),
    enabled: tabStplatNo > 0 // Only run the query if stplatNo is truthy
  });

  useEffect(() => {
    setUiTabClass('ui-tab scroll');
  }, [stplatList, setUiTabClass]);

  return (
    <>
      <section className="content-layout">
        <div className="terms-top">
          {stplatHist !== undefined && stplatHistList !== undefined && (
            <div className="terms-select">
              <label htmlFor="termsSelect">개정이력</label>
              <span className="ui-select">
                <select
                  id="termsSelect"
                  title="개인정보 처리방침 개정이력 선택 및 이동"
                  value={stplatHist?.stplatSeq}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    for (let i = 0; i < stplatHistList.length; i++) {
                      if (stplatHistList[i].stplatSeq == e.target.value) {
                        setStplatHist({
                          stplatSeq: stplatHistList[i].stplatSeq,
                          createMarkup: () => {
                            return { __html: stplatHistList[i].stplatCtnt };
                          }
                        });
                      }
                    }
                  }}
                >
                  {stplatHistList?.map((item) => {
                    const list = (
                      <option key={item.stplatSeq} value={item.stplatSeq}>
                        {item.enfcDttm} 개정
                      </option>
                    );
                    return list;
                  })}
                </select>
              </span>
            </div>
          )}
        </div>
        <div
          id="termsText"
          className="terms-text"
          dangerouslySetInnerHTML={stplatHist?.createMarkup()}
        />
      </section>
    </>
  );
}
