/**
 * <pre>
 * @title routeConfig.tsx
 * @desc 라우트 설정
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.09.28 17:36:43
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.28.  김완수  최초작성
 */
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useRoutes } from 'react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setData } from '../../store/modules/contentLayout/contentLayout';

import { selectStplatListApi } from './../../api/ci/csCenter';
import { unClickMenu, getCurrentMenuGroup } from '../../utils/web_common_ui';
import { Menu, getPage, getLayout } from '../../utils/getPage';
import { config } from '../config';

import { getNameByPath, getPathByName } from '../../utils/pathUtils';
export default function RoutesElement() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { data: stplatList } = useQuery({
    queryKey: ['selectStplatListApi', location.pathname],
    queryFn: () =>
      selectStplatListApi().then((res) => {
        const list = res.data?.[0].stplats.filter(
          (v, i, a) => a.findIndex((t) => t.stplatNm === v.stplatNm) === i
        );
        return list;
      }),
    enabled: getNameByPath(location.pathname) !== ''
  });

  useEffect(() => {
    unClickMenu();
    const menuGroup = getCurrentMenuGroup();

    if (menuGroup && getNameByPath(location.pathname) !== '' && stplatList) {
      console.log('이러면 나가린데?');
      const menus: Menu[] = stplatList.map((item) => ({
        title: item.stplatNm,
        subTitle: item.stplatNm,
        url: getPathByName(item.stplatNm, location.pathname),
        link: 'N'
      }));

      // Update menuGroup and dispatch
      const updatedMenuGroup = {
        ...menuGroup,
        children: menus,
        currentUrl: menuGroup.children[0]?.url || '',
        contentClass: '',
        title: menuGroup.title || '' // Add default string if title is undefined
      };

      dispatch(setData(updatedMenuGroup));
    } else {
      dispatch(setData(menuGroup));
    }
  }, [location, stplatList]);

  const pageGroups = _.groupBy(config.pages, 'type');
  const useRoutesArray = _.map(pageGroups, (pages, k) => {
    const Layout = getLayout(k);
    return {
      element: <Layout />,
      children: pages.map((v) => {
        const Component = getPage(v.id);
        return { path: v.url, element: <Component /> };
      })
    };
  });

  return useRoutes(useRoutesArray);
}
