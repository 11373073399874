/*
 * <pre>
 * @title GngCwhSCR_H_CW_MO_SV_02.tsx
 * @desc 클링패스
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React from 'react';
export default function GngCwhSCR_H_CW_MO_SV_02() {
  return (
    <>
      <div className="contents service">
        <div className="js-inview mb40">
          <p className="desc-tit inview-el nth-child-7">
            구독으로 시작하는
            <br />
            새로운 세차, 클링패스
          </p>
        </div>

        <section className="sec-mg-minus">
          <div className="gray-full-area js-inview ">
            <div className="gray-full-area-txt inview-el">
              <h2 className="desc-tit pt0 col-white">
                {/* desc-col-green */}
                <span className="">클링패스</span>
                <br />
                서비스란?
              </h2>
              <p className="desc-sub desc-col-white">
                클링패스 방문 세차는 월 구독형 세차
                <br /> 패키지로 정기적으로 테크니션이
                <br />
                원하는 곳에 방문하여 세차를
                <br />
                진행합니다.
              </p>
            </div>
          </div>
        </section>

        <section className="section-wrap bg-parallax mt40">
          <div className="img-item-wrap">
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/img_pass01.png"
                className=""
              />
              <div className="desc">
                <div className="text ml0">
                  <strong>차량 케어에 대한 바른 변화</strong>
                  <p>
                    세차클링은 차량케어에 관한 생각을 바꾸고
                    <br /> 삶을 새롭게 혁신하기 위해 존재합니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="item js-ani">
              <img
                src="/static/mob/images/contents/img_pass02.png"
                className=""
              />
              <div className="desc">
                <div className="text ml0">
                  <strong>차량 케어를 위한 바른 행동</strong>
                  <p>
                    세차클링은 차량의 컨디션에 맞추어
                    <br />
                    전문 테크니션을 운영합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="clean_service">
          <h1 className="desc-tit pt60 js-ani">
            클링패스 <span className="col-sblue">상품 구성</span>
          </h1>
          <div className="clean-se-bg mt32 js-ani">
            <p className="sec-tit">이용권</p>
            <ul className="coupon-area carwashing">
              <li className="img-wrap">
                <img
                  src="/static/mob/images/contents/img_carwashing_coupon.svg"
                  alt="carwashing 쿠폰"
                />
                <span className="use-num">x2</span>
              </li>
            </ul>
          </div>

          <div className="clean-se-bg mt12 js-ani">
            <p className="sec-tit">할인권</p>
            <ul className="coupon-area">
              <li className="img-wrap ">
                <img
                  src="/static/mob/images/contents/img_coupon_50.svg"
                  alt="50%쿠폰"
                />
              </li>
              <li className="img-wrap mt24">
                <img
                  src="/static/mob/images/contents/img_coupon_30.svg"
                  alt="30%쿠폰"
                />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
