import React, { useEffect } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { Outlet } from 'react-router-dom';
import _ from 'lodash';
import { registUI, webContentTitleInitUi } from '../../../utils/web_common_ui';

const MobileMainLayout = () => {
  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.ui.init)) {
        window.ui.init();
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
    webContentTitleInitUi();

    (() => {
      window.addEventListener('beforeunload', (e: any) => {
        document.getElementsByClassName('contents')[0].innerHTML = '';
      });
    })();
  }, []);

  return (
    <div id="wrap" className="main">
      <Header headerType="main" />
      <main id="container">
        <div className="contents">
          <Outlet />
        </div>
      </main>
      <Footer />

      <article className="modal c" id="modal_1">
        <div className="modal-dialog">
          <div className="">
            <h1 className="ptit"></h1>
          </div>
          <div className="modal-content">
            <div className="modal-body alert center">준비중입니다.</div>
          </div>
          <div className="modal-footer">
            <div className="btn-set">
              <button type="button" className="btn btn-pop-close dark">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>

      <article className="modal c" id="modal_2">
        <div className="modal-dialog">
          <div className="">
            <h1 className="ptit"></h1>
          </div>
          <div className="modal-content">
            <div className="modal-body alert center alertContent"></div>
          </div>
          <div className="modal-footer">
            <div className="btn-set">
              <button type="button" className="btn btn-pop-close dark">
                확인
              </button>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};
export default React.memo(MobileMainLayout);
