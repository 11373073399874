/**
 * <pre>
 * @title buildType.ts
 * @desc 빌드 타입 config
 * @package @gngs/greencar-web-front
 * </pre>
 *
 * @author 그린카 차세대 개발팀 김완수
 * @since 2022.08.19
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.08.19.  김완수  최초작성
 */
import _ from 'lodash';

export enum BuildEnv {
  LOCAL = 'local',
  DEV = 'dev',
  STG = 'stg',
  PRD = 'prd'
}

export type BuildEnvStr = keyof typeof BuildEnv;

export function toBuildEnv(type?: string) {
  const _type = type?.toUpperCase();
  if (_type && _.has(BuildEnv, _type)) {
    return BuildEnv[_type as BuildEnvStr];
  }
  return BuildEnv.DEV;
}

export function isDev(env: BuildEnv) {
  return !(env === BuildEnv.PRD || env === BuildEnv.STG);
}
