import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { pages, Page } from '../../../config/pages/pages';
import { registUI } from '../../../utils/web_common_ui';

const ContentLayout = () => {
  const navigate = useNavigate();

  const pageInfo = _.find(pages, (page: Page) => {
    const pathname = location.pathname.startsWith('/')
      ? location.pathname
      : `/${location.pathname}`;
    const pageurl = page.url.startsWith('/') ? page.url : `/${page.url}`;

    return pathname.indexOf(pageurl) >= 0;
  });

  //console.log('ContentLayout :::::: ', location.pathname, pages[0].url);
  const contentLayoutState = useSelector(
    (state: RootState) => state.contentLayout
  );
  useEffect(() => {
    //webHamburgerMenuInitUi();
  }, [contentLayoutState]);

  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.uiTab)) {
        registUI('.ui-tab', window.uiTab);
        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
  }, [pageInfo?.id]);

  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.uiTop)) {
        registUI('.ui-top', window.uiTop);

        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 100);
  }, []);
  return (
    <div id="wrap">
      <div
        className="pop_open alertPopup"
        data-id="modal_2"
        onClick={() => {
          new window.UiPopup('.modal').bindEvent();
        }}
        style={{ display: 'none' }}
      ></div>

      <Header headerType="sub" />
      <main className="container">
        <div className={`contents ${pageInfo?.contentClass}`}>
          <section className="title-area cs js-inview">
            <h1 className="title inview-el nth-child-3">
              {contentLayoutState.title}
            </h1>
          </section>

          <div className="ui-tab">
            <div className="module">
              {_.map(
                contentLayoutState.children,
                (menu: any, index: number) => {
                  return (
                    <div
                      className={`item ${
                        `${menu.url}` === contentLayoutState.currentUrl
                          ? 'active'
                          : ''
                      }`}
                      key={index}
                    >
                      {menu.link === 'Y' ? (
                        <a
                          href={menu.url}
                          target="_blank"
                          title="새창 열기"
                          rel="noopener noreferrer"
                          className="blank"
                        >
                          {menu.subTitle}
                        </a>
                      ) : (
                        <Link
                          to={menu.url}
                          className="btn"
                          onClick={() => {
                            navigate(`${menu.url}`);
                          }}
                        >
                          {menu.subTitle}
                        </Link>
                      )}
                    </div>
                  );
                }
              )}
              <div className="line"></div>
            </div>
          </div>
          <Outlet />
          {/* <section className="content-layout">
            <Outlet />
          </section> */}
        </div>
      </main>
      <Footer />

      <article className="modal a sm" id="modal_1">
        <div className="modal-dialog not-close">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="ptit"></h1>
            </div>
            <div className="modal-body center">
              <p>준비중입니다</p>
            </div>
            <div className="modal-footer">
              <div className="btn-set b2">
                {/* <button type="button" className="btn close-pop" data-id="modal_1">취소</button> */}
                <button
                  type="button"
                  className="btn close-pop dark b rd lg"
                  data-id="modal_1"
                >
                  확인
                </button>
              </div>
            </div>
            <button type="button" className="btn-pop-close">
              <i className="blind">닫기</i>
            </button>
          </div>
        </div>
      </article>

      <article className="modal a sm" id="modal_2">
        <div className="modal-dialog not-close">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="ptit"></h1>
            </div>
            <div className="modal-body center">
              <p className="alertContent"></p>
            </div>
            <div className="modal-footer">
              <div className="btn-set b2">
                {/* <button type="button" className="btn close-pop" data-id="modal_1">취소</button> */}
                <button
                  type="button"
                  className="btn close-pop dark b rd lg"
                  data-id="modal_2"
                >
                  확인
                </button>
              </div>
            </div>
            <button type="button" className="btn-pop-close">
              <i className="blind">닫기</i>
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default React.memo(ContentLayout);
