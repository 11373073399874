/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_TC_01.tsx
 * @desc 회원이용약관
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  stplatListDefaultProps,
  selectStplatListApi,
  stplatDefaultProps,
  selectStplatHistApi,
  stplatHistDefaultProps
} from '../../api/ci/csCenter';
import _, { isArguments } from 'lodash';
import { Children } from 'react';
import { registUI } from '../../utils/web_common_ui';
import { IgnorePlugin } from 'webpack';

export default function GngCwhSCR_H_CW_PC_TC_03() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const location = useLocation();
  const [uiTabClass, setUiTabClass] = useState('');

  const defaultListProps: stplatListDefaultProps = {
    stplatList: []
  };
  const [stplatListData, setStplatListData] = useState(defaultListProps);

  const defaultStplatProps: stplatDefaultProps = {
    stplatItem: []
  };
  const [stplatItemData, setStplatItemData] = useState(defaultStplatProps);

  const defaultHistProps: stplatHistDefaultProps = {
    stplatHist: []
  };
  const [stplatHistData, setStplatHistData] = useState(defaultHistProps);

  useEffect(() => {
    selectStplatListApi().then((res: any) => {
      setStplatListData((prevState: any) => ({
        ...prevState,
        stplatList: [...res.data]
      }));

      let cnt = '';
      for (let i = 0; i < res.data[0].stplats.length; i++) {
        res.data[0].stplats[i];
        res.data[0].stplats[i].isActive = false;
        if (res.data[0].stplats[i].stplatNo == '211069') {
          cnt = i + '';
        }
      }
      res.data[0].stplats[cnt].isActive = true;
      document.getElementsByClassName('terms-text')[0].innerHTML =
        res.data[0].stplats[cnt].stplatCtnt;

      selectStplatHistApi('211069').then((res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].enfcDttm = res.data[i].enfcDttm.substring(0, 10);
        }
        setStplatHistData((prevState: any) => ({
          ...prevState,
          stplatHist: [...res.data]
        }));
      });

      let data: any = [];
      for (let i = 0; i < res.data[0].stplats.length; i++) {
        if (res.data[0].stplats[i].stplatNo == '211066') {
          data.push(res.data[0].stplats[i]);
        }
        if (res.data[0].stplats[i].stplatNo == '211068') {
          data.push(res.data[0].stplats[i]);
        }
        if (res.data[0].stplats[i].stplatNo == '211069') {
          data.push(res.data[0].stplats[i]);
        }
      }
      setStplatItemData((prevState: any) => ({
        ...prevState,
        stplatItem: [...data]
      }));
      setUiTabClass('ui-tab scroll');
    });
  }, []);

  useEffect(() => {
    document.querySelector('.ui-tab.scroll > .left')?.remove();
    document.querySelector('.ui-tab.scroll > .right')?.remove();
    // window.scrollTab();
    registUI('.ui-tab', window.uiTab);
  }, [uiTabClass]);

  const itemToggle = (code: any) => {
    const itemIndex = _.findIndex(stplatItemData.stplatItem, (item) => {
      return item.stplatNo == code;
    });

    for (let i = 0; i < stplatItemData.stplatItem.length; i++) {
      stplatItemData.stplatItem[i].isActive = false;
    }
    stplatItemData.stplatItem[itemIndex].isActive = true;
    document.getElementsByClassName('terms-text')[0].innerHTML =
      stplatItemData.stplatItem[itemIndex].stplatCtnt;

    selectStplatHistApi(stplatItemData.stplatItem[itemIndex].stplatNo).then(
      (res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].enfcDttm = res.data[i].enfcDttm.substring(0, 10);
        }
        setStplatHistData((prevState: any) => ({
          ...prevState,
          stplatHist: [...res.data]
        }));
      }
    );

    setStplatItemData((prevState: any) => ({
      ...prevState,
      stplatItem: [...stplatItemData.stplatItem]
    }));
  };

  return (
    <>
      <section className="title-area cs js-inview is-inviewed">
        <h1 className="title inview-el nth-child-3">이용약관</h1>
      </section>

      <div className={uiTabClass}>
        <div className="module">
          {stplatItemData.stplatItem.map((item) => {
            const list = (
              <div
                key={item.stplatNo}
                className={item.isActive == true ? 'item active' : 'item'}
                onClick={() => {
                  itemToggle(item.stplatNo);
                }}
              >
                <a href="#" className="btn">
                  {item.stplatNm}
                </a>
              </div>
            );
            return list;
          })}
          <div className="line"></div>
        </div>
      </div>

      <section className="content-layout">
        <div className="terms-top">
          <div className="terms-select">
            <label htmlFor="termsSelect">개정이력</label>
            <span className="ui-select">
              <select
                id="termsSelect"
                title="개인정보 처리방침 개정이력 선택 및 이동"
                onChange={(e: any) => {
                  console.log(e.target.value);

                  for (let i = 0; i < stplatHistData.stplatHist.length; i++) {
                    if (
                      stplatHistData.stplatHist[i].stplatSeq == e.target.value
                    ) {
                      document.getElementsByClassName(
                        'terms-text'
                      )[0].innerHTML = stplatHistData.stplatHist[i].stplatCtnt;
                    }
                  }
                }}
              >
                {stplatHistData.stplatHist.map((item) => {
                  const list = (
                    <option key={item.stplatSeq} value={item.stplatSeq}>
                      {item.enfcDttm} 개정
                    </option>
                  );
                  return list;
                })}
              </select>
            </span>
          </div>
        </div>
        <div id="termsText" className="terms-text"></div>
      </section>
    </>
  );
}
