import _ from 'lodash';
import { Link } from 'react-router-dom';
import { config } from '../../config';
// import { useConfig } from '../../hooks/useConfig';

const pages = _.sortBy(
  _.filter(config.pages, (v) => v.id !== 'pageList'),
  (v) => v.id
);

export default function PageList() {
  // const { config } = useConfig();
  // const pages = _.sortBy(
  //   _.filter(config.pages, (v) => v.id !== 'pageList'),
  //   (v) => v.id
  // );

  return (
    <div style={{ margin: '15px' }}>
      {pages.map((v) => {
        return (
          <div key={v.id} style={{ margin: '4px' }}>
            <Link to={v.url!} style={{ textDecoration: 'underline' }}>
              {`${v.id} (${v.title})`}
            </Link>
          </div>
        );
      })}
    </div>
  );
}
