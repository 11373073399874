/*
 * <pre>
 * @title GngCwhSCR_H_CW_PC_CW_01.tsx
 * @desc 세차클링
 * @package @gngs/cwh-ui
 * </pre>
 *
 * @author 그린카 차세대 개발팀 이준용
 * @since 2022.09.28 12:34:40
 * @version 0.1.0
 * @see =================== 변경 내역 ==================
 *   날짜       변경자     내용
 *  2022.09.29.  이준용  최초작성
 */

import { downloadLink } from '../../utils/download';
import { useEffect } from 'react';
import { registUI } from '../../utils/web_common_ui';
import _ from 'lodash';

export default function GngCwhSCR_H_CW_PC_CW_01() {
  useEffect(() => {
    let initHeaderInterval: any;
    const initHeader = () => {
      if (_.isFunction(window.bgParallax) && _.isFunction(window.bgParallax)) {
        registUI('.bg-parallax', window.bgParallax);

        clearInterval(initHeaderInterval);
      }
    };
    initHeaderInterval = setInterval(initHeader, 200);
  }, []);

  return (
    <>
      <div className="greencar__top_bn js-inview">
        <div className="mx-guide">
          <div className="greencar__top_bn_txt inview-el nth-child-7">
            <h2>
              스마트한 세차 라이프,
              <br />
              세차클링
            </h2>
          </div>
        </div>
      </div>
      <div className="content-layout aff-introduction">
        <div className="grid-moudle">
          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="static/web/images/contents/clean_img_01.png" alt="" />
            </div>
            <div className="flex cont inview-el">
              <p className="tit">간편한 세차 예약</p>
              <p className="desc">
                원하는 시간과 장소에서 어디서든
                <br />
                간편하고 편리하게 예약하세요.
              </p>
            </div>
          </div>

          <div className="grid js-inview bg2-trigger">
            <div className="flex cont inview-el">
              <p className="tit">검증된 세차 서비스</p>
              <p className="desc">
                카셰어링 플랫폼 G car가 만든 비대면 세차 <br />
                서비스, 월 7만 여 건의 G car 세차 노하우로
                <br />
                안전하고 편리한 서비스를 경험하세요
              </p>
            </div>
            <div className="flex inview-el">
              <img src="static/web/images/contents/clean_img_02.png" alt="" />
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex inview-el">
              <img src="static/web/images/contents/clean_img_03.png" alt="" />
            </div>
            <div className="flex cont inview-el">
              <p className="tit">합리적 가격, 뛰어난 수준</p>
              <p className="desc">
                정기세차 서비스를 이용하면
                <br />
                다양한 혜택이 제공됩니다.
                <br />
                합리적인 가격으로 이용하세요.
              </p>
            </div>
          </div>

          <div className="grid js-inview">
            <div className="flex cont inview-el">
              <p className="tit">전기차 세차서비스</p>
              <p className="desc">
                전기차도 간편하게, 더 퍼펙트하게 <br />
                관리하세요.
              </p>
            </div>
            <div className="flex inview-el">
              <img src="static/web/images/contents/clean_img_04.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="greencar__footer_info js-inview">
        <div className="mx-guide inview-el">
          <div className="tit">
            <h2>
              세차클링만의 <em>세차 시스템</em>
            </h2>
          </div>

          <div className="together-box">
            <div className="box-item">
              <div>
                <div className="tit">친환경 워터리스</div>
                <p>
                  친환경 워터리스 세차, 물을 단 500ml 밖에 사용하지 않습니다.
                </p>
              </div>
            </div>
            <div className="box-item">
              <div>
                <div className="tit">검증된 제품 사용</div>
                <p>
                  윤활제와 계면활성제 비율이 검증된 제품을 사용하고 있습니다.
                </p>
              </div>
            </div>
            <div className="box-item">
              <div>
                <div className="tit">주기적인 관리</div>
                <p>
                  세차클링은 주기적으로 타월과 세차 장비를 관리하고 있습니다.
                </p>
              </div>
            </div>
            <div className="box-item">
              <div>
                <div className="tit">깔끔하고 안전한 서비스</div>
                <p>
                  주변시설 오염, 소음발생 없이 다양한 환경에서도 깔끔하고 안전한
                  서비스를 제공합니다.
                </p>
              </div>
            </div>
          </div>
          <div className="btn-primary">
            <button type="button" className="btns lg" onClick={downloadLink}>
              세차클링 앱 다운로드
            </button>
          </div>
        </div>
      </div>
      <span className="bg-v-1"></span>
      <span className="bg-v-2"></span>
    </>
  );
}
